import { SVGProps } from "react";

function PlanSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39111 17.2904H4.00993C4.18972 18.2636 5.03157 19 6.04295 19C7.18535 19 8.11145 18.0604 8.11145 16.9014V13.0163H13.8887V16.9014C13.8887 18.0604 14.8148 19 15.9572 19C16.8611 19 17.6295 18.4118 17.911 17.5922H18.6089C19.9295 17.5922 21 16.5061 21 15.1663V7.83392C21 6.49412 19.9295 5.40799 18.6089 5.40799H17.9111C17.6296 4.58824 16.8611 4 15.9572 4C14.8148 4 13.8887 4.93958 13.8887 6.09862V9.43044H8.11145V6.09862C8.11145 4.93958 7.18535 4 6.04295 4C5.03156 4 4.18971 4.73644 4.00992 5.70961H3.39111C2.07054 5.70961 1 6.79573 1 8.13554V14.8645C1 16.2043 2.07054 17.2904 3.39111 17.2904ZM3.39111 7.03284H3.97445V15.9672H3.39111C2.79085 15.9672 2.30424 15.4735 2.30424 14.8645V8.13554C2.30424 7.52654 2.79085 7.03284 3.39111 7.03284ZM18.0257 16.2689V6.73122H18.6089C19.2092 6.73122 19.6958 7.22492 19.6958 7.83392V15.1663C19.6958 15.7753 19.2092 16.2689 18.6089 16.2689H18.0257ZM16.7214 6.08598C16.7147 5.66358 16.3752 5.32324 15.9572 5.32324C15.5351 5.32324 15.193 5.67039 15.193 6.09862V10.0533C15.1937 10.0662 15.1941 10.0791 15.1941 10.0921C15.1941 10.1051 15.1937 10.118 15.193 10.1308V12.3206C15.1935 12.3319 15.1938 12.3432 15.1938 12.3546C15.1938 12.3661 15.1935 12.3774 15.193 12.3887V16.9014C15.193 17.3296 15.5351 17.6768 15.9572 17.6768C16.3752 17.6768 16.7147 17.3364 16.7214 16.914V6.08598ZM13.8887 10.7537V11.693H8.11145V10.7537H13.8887ZM6.04295 17.6768C6.46504 17.6768 6.8072 17.3296 6.8072 16.9014V6.09862C6.8072 5.67039 6.46503 5.32324 6.04295 5.32324C5.62086 5.32324 5.27869 5.67039 5.27869 6.09862V16.9014C5.27869 17.3296 5.62086 17.6768 6.04295 17.6768Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39111 17.2904H4.00993C4.18972 18.2636 5.03157 19 6.04295 19C7.18535 19 8.11145 18.0604 8.11145 16.9014V13.0163H13.8887V16.9014C13.8887 18.0604 14.8148 19 15.9572 19C16.8611 19 17.6295 18.4118 17.911 17.5922H18.6089C19.9295 17.5922 21 16.5061 21 15.1663V7.83392C21 6.49412 19.9295 5.40799 18.6089 5.40799H17.9111C17.6296 4.58824 16.8611 4 15.9572 4C14.8148 4 13.8887 4.93958 13.8887 6.09862V9.43044H8.11145V6.09862C8.11145 4.93958 7.18535 4 6.04295 4C5.03156 4 4.18971 4.73644 4.00992 5.70961H3.39111C2.07054 5.70961 1 6.79573 1 8.13554V14.8645C1 16.2043 2.07054 17.2904 3.39111 17.2904ZM3.39111 7.03284H3.97445V15.9672H3.39111C2.79085 15.9672 2.30424 15.4735 2.30424 14.8645V8.13554C2.30424 7.52654 2.79085 7.03284 3.39111 7.03284ZM18.0257 16.2689V6.73122H18.6089C19.2092 6.73122 19.6958 7.22492 19.6958 7.83392V15.1663C19.6958 15.7753 19.2092 16.2689 18.6089 16.2689H18.0257ZM16.7214 6.08598C16.7147 5.66358 16.3752 5.32324 15.9572 5.32324C15.5351 5.32324 15.193 5.67039 15.193 6.09862V10.0533C15.1937 10.0662 15.1941 10.0791 15.1941 10.0921C15.1941 10.1051 15.1937 10.118 15.193 10.1308V12.3206C15.1935 12.3319 15.1938 12.3432 15.1938 12.3546C15.1938 12.3661 15.1935 12.3774 15.193 12.3887V16.9014C15.193 17.3296 15.5351 17.6768 15.9572 17.6768C16.3752 17.6768 16.7147 17.3364 16.7214 16.914V6.08598ZM13.8887 10.7537V11.693H8.11145V10.7537H13.8887ZM6.04295 17.6768C6.46504 17.6768 6.8072 17.3296 6.8072 16.9014V6.09862C6.8072 5.67039 6.46503 5.32324 6.04295 5.32324C5.62086 5.32324 5.27869 5.67039 5.27869 6.09862V16.9014C5.27869 17.3296 5.62086 17.6768 6.04295 17.6768Z"
        fill="white"
        fillOpacity="0.1"
      />
    </svg>
  );
}
export default PlanSVG;
