import { useFieldArray, useFormContext } from "react-hook-form";
import React, {  useState } from "react";
import { questionType } from "types";

const PreviewQuestions = () => {
  
  const {
    register,
    control,
    formState,
    reset,
    setValue,
    clearErrors,
    watch,
    resetField,
    getFieldState,
    getValues,
    trigger,
  } = useFormContext();
  const { isValid, errors } = formState;

  const { fields: questions } = useFieldArray({
    control,
    name: `questions`,
  });

  return (
    <div className="flex flex-col h-full w-full gap-y-5 max-sm:pb-3 ">
      <div className="flex flex-col h-1/5 w-full gap-y-5 max-sm:pb-3 ">
        <div className="text-sm">اطلاعات</div>
        <div className="w-full mr-2">
          <input
            {...register("formName")}
            className="border-0 bg-transparent "
            disabled={true}
            readOnly={true}
          />
        </div>
        <div className="w-full mr-2">
          <input
            {...register("formDescription")}
            className="border-0 bg-transparent "
            disabled={true}
            readOnly={true}
          />
        </div>
      </div>
      <div className="text-sm">محتوا</div>
      <div className="flex flex-col h-4/5 gap-y-4 max-sm:pb-3 overflow-y-auto">
        {questions
          .filter((question: questionType) => question.isCustom === true)
          .map((question: questionType, idx) => (
            <div key={idx} className="w-[90%]  mr-2">
              <input
                {...register(`questions.${idx}.text`)}
                className="border-0 bg-transparent "
                disabled={true}
                readOnly={true}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default PreviewQuestions;
