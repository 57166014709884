import { WithContext as ReactTags, Tag } from "react-tag-input";
import "components/tag/tagCtrl.css";
import { tagType } from "types";
import React from "react";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.enter];

interface Props {
  mabnaTags?: string[];
  handleDelete: (i: number) => void;
  handleAddition: (tag: Tag) => void;
  handleDrag: (tag: Tag, currPos: number, newPos: number) => void;
  handleTagClick: (index: number) => void;
  tags: tagType[];
  placeholder: string;
  Position: "inline" | "top" | "bottom" | undefined;
  autocomplete: boolean;
}

const TagCtrl = ({
  mabnaTags,
  handleDelete,
  handleAddition,
  handleDrag,
  handleTagClick,
  tags,
  placeholder,
  Position,
  autocomplete,
}: Props) => {
  const suggestions: tagType[] = mabnaTags!.map((tag: string) => {
    return {
      id: tag,
      text: tag,
    };
  });

  return (
    <ReactTags
      placeholder={placeholder}
      inputFieldPosition={Position}
      tags={tags}
      suggestions={suggestions}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      handleTagClick={handleTagClick}
      autocomplete
    />
  );
};

export default TagCtrl;
