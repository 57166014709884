import { onlyLettersAndNumbers, toShamsi } from "components/helper/publicLib";
import ConfirmModal from "components/modals/confirmModal";
import PlansModal from "components/modals/plansModal";
import AvatarSVG from "components/svg/avatarSvg";
import CloseSVG from "components/svg/closeSvg";
import DeleteSVG from "components/svg/deleteSvg";
import EditSVG from "components/svg/editSvg";
import React, { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { GiSaveArrow } from "react-icons/gi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { FixedSizeList } from "react-window";
import Player from "components/player.json";
import {
  addNote,
  addPlanToAthlete,
  deleteAthletePlan,
  deleteNote,
  getAthlete,
  getAthleteActivities,
  getAthleteNotes,
  getAthletePlanById,
  getAthletePlans,
  updateNote,
} from "services/athleteService";
import { getPlan } from "services/planService";
import { toast } from "sonner";
import { activityType, athletePlanType, athleteType, noteType } from "types";
const moviesUrl = process.env.REACT_APP_MOVIES_URL;

const initialValues: athleteType = {
  athleteId: "",
  athletePhotoUrl: "",
  athleteFullName: "",
  subscriptionDateTime: "",
  state: true,
  progress: 0,
  lastFormDate: "",
  needAssessment: true,
  age: 0,
  height: 0,
  weight: 0,
  goal: "",
  sportBackground: "",
  injuries: "",
  specialMedicine: "",
  activities: null,
  plans: null,
  dayRemainPackage: 0,
};

const Athlete = () => {
  const { athleteId } = useParams();
  const [showPlansModal, setShowPlansModal] = useState<boolean>(false);
  const [noteText, setNoteText] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [noteAdded, setNoteAdded] = useState<number>(0);
  const [selectedNote, setSelectedNote] = useState<number>(-1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>("0");
  const [athleteNotes, setAthleteNotes] = useState<noteType[]>([]);
  const [athleteActivities, setAthleteActivities] = useState<activityType[]>(
    []
  );
  const [athletePlans, setAthletePlans] = useState<athletePlanType[]>([]);
  const divRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    control,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      isLoading,
    },
  } = useForm<athleteType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const delete_Note = async (note: noteType) => {
    let notes: noteType[] = [...athleteNotes];
    const deletedNote = await deleteNote(note.noteId!);
    if ((deletedNote as any).status === 200) {
      notes = notes.filter(
        (noteItem: noteType) => noteItem.noteId !== note.noteId
      );
      setAthleteNotes(notes);
    }
  };

  const handleScrollToBottom = () => {
    //
    (divRef.current as any).scrollTo({ top: 1000, behavior: "smooth" });
  };
  useEffect(() => {
    if (divRef.current) {
      // Only triggers if comments are added
      handleScrollToBottom();
    }
  }, [noteAdded]);
  const setNote = async () => {
    if (noteText.length > 0) {
      let notes: noteType[] = [...athleteNotes];
      if (selectedNote > -1) {
        const updatedNote = await updateNote({
          athleteId: athleteId,
          noteId: notes[selectedNote].noteId,
          noteText: noteText,
        });
        if ((updatedNote as any).status === 200) {
          notes[selectedNote] = {
            noteId: notes[selectedNote].noteId,
            noteText: noteText,
          };
          setSelectedNote(-1);
          setAthleteNotes(notes);
        }
      } else {
        const addedNote = await addNote({
          athleteId: athleteId,
          noteText: noteText,
        });
        if ((addedNote as any).status === 200) {
          notes.push({
            noteId: (addedNote as any).data.result.noteId,
            noteText: noteText,
          });
          setAthleteNotes(notes);
          setNoteAdded(noteAdded + 1);
        }
      }

      setNoteText("");
    }
  };

  const acceptBtnClick = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      const deletedPlan = deleteAthletePlan(selectedPlanId!, athleteId!);
      if ((deletedPlan as any).status === 200) {
        let athletePlansLocal: athletePlanType[];
        athletePlansLocal = athletePlans.filter(
          (planItem: athletePlanType) => planItem.planId !== selectedPlanId
        );
        setAthletePlans(athletePlansLocal);
        setShowModal(false);
        toast.success("عملیات با موفقیت انجام پذیرفت");
      }
    } catch (error) {
      toast.error("خطا در عملیات");
    }
  };
  const rejectBtnClick = () => {
    setShowModal(false);
  };

  const selectPlan = async (planId: string) => {
    try {
      const { data } = await getPlan(planId);
      if (data && data.result) {
        const { result: planData } = data;
        const addPlanResult = await addPlanToAthlete({
          planId: planData.id,
          athleteId: athleteId,
        });
        if ((addPlanResult as any).status === 200) {
          const athletePlansLocal = await getAthletePlans(athleteId!);
          if (
            (athletePlansLocal as any).status === 200 &&
            (athletePlansLocal as any).data.result
          ) {
            setAthletePlans(athletePlansLocal.data.result);
          }
          // const addedPlan = await getAthletePlanById(planData.id);
          // if ((addedPlan as any).status === 200) {

          // let athletePlans: athletePlanType[] = watch("plans") || [];
          // athletePlans.push({
          //   planId: planData.id,
          //   planName: addedPlan.data.result.id,
          //   planPosterUrl: addedPlan.data.result.id,
          //   planDuration: addedPlan.data.result.id,
          //   planProgress: addedPlan.data.result.id,
          //   assignDatetime: addedPlan.data.result.id,
          //   state: addedPlan.data.result.id,
          // });
          // setValue("plans", athletePlans);
        }
      }
    } catch (error) {}
  };

  const deletePlan = (index: number) => {
    let athletePlans: athletePlanType[] = watch("plans") || [];
    athletePlans.splice(index, 1);
    setValue("plans", athletePlans);
  };
  useEffect(() => {
    async function fetchAPI() {
      try {
        if (athleteId) {
          // let filData: movementType;
          const retValAthleteData = await getAthlete(athleteId);
          if (
            (retValAthleteData as any).status === 200 &&
            (retValAthleteData as any).data.result
          ) {
            reset((retValAthleteData as any).data.result);
          }

          const athleteNotesLocal = await getAthleteNotes(athleteId);
          if (
            (athleteNotesLocal as any).status === 200 &&
            (athleteNotesLocal as any).data.result
          ) {
            setAthleteNotes(athleteNotesLocal.data.result);
          }

          const athleteActivitiesLocal = await getAthleteActivities(athleteId);
          if (
            (athleteActivitiesLocal as any).status === 200 &&
            (athleteActivitiesLocal as any).data.result
          ) {
            setAthleteActivities(athleteActivitiesLocal.data.result);
          }

          const athletePlansLocal = await getAthletePlans(athleteId);
          if (
            (athletePlansLocal as any).status === 200 &&
            (athletePlansLocal as any).data.result
          ) {
            setAthletePlans(athletePlansLocal.data.result);
          }
        }
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  const rowStyles: string =
    "w-full max-md:border-t   max-md:h-8 max-md:flex max-md:items-center max-md:justify-start max-md:gap-x-2";

  const gridItemSryle =
    "border w-full h-12 lg:h-20 flex flex-col items-center justify-center gap-y-1 lg:gap-y-3 px-4 py-1 rounded-2xl tooltip 	tooltip-bottom  tooltip-custom lg:tooltip-right";
  const colStyles: string =
    "md:hidden h-full bg-slate-300 flex items-center w-32 justify-center";
  return (
    <form className="w-full h-full flex   flex-col justify-start items-center gap-y-7 lg:gap-y-11 pt-3 mx-2 overflow-y-auto">
      <div className="w-full 2xl:w-3/4 lg:h-[12rem] h-[21rem] flex flex-col lg:flex-row gap-y-5 lg:gap-y-3 items-center  lg:justify-start lg:items-center gap-x-5">
        <div className=" w-full lg:w-1/3 flex justify-center lg:justify-end items-center">
          <div className="w-32 flex justify-center items-center">
            <div className="avatar ">
              <div
                className={`absolute top-0 -right-6 text-xs  h-5 rounded-full text-center ${
                  watch("state")
                    ? "text-green-600 bg-green-200 w-9"
                    : "text-slate-400 bg-slate-700 w-12"
                }`}
              >
                {`${watch("state") ? "فعال" : "غیر فعال"}`}{" "}
              </div>
              <div className="w-24 rounded-full">
                {watch("athletePhotoUrl") ? (
                  <img src={watch("athletePhotoUrl")!} alt={`athlet Avatar`} />
                ) : (
                  <AvatarSVG />
                )}
              </div>
              <div className="absolute -bottom-3 -right-2 text-xs py-1 h-6 w-28 rounded-full text-center text-white bg-color1 ">
                <div className="h-full ">{watch("athleteFullName")}</div>
              </div>
              <div className="absolute -bottom-9 -right-1 text-xs py-1 h-6 w-28 rounded-full text-center text-black ">
                <div className="h-full ">
                  {watch("dayRemainPackage")} روز تا پایان اشتراک
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full grid grid-cols-2 lg:grid-cols-4 gap-y-1 mt-4 gap-x-2 grid-rows-2 justify-center w-full  content-center justify-items-center items-center text-2xs lg:text-xs ">
          <div className={gridItemSryle}>
            <div className="text-center">شروع</div>
            <div>{toShamsi(watch("subscriptionDateTime"))}</div>
          </div>
          <div className={gridItemSryle}>
            {" "}
            <div className="text-center">سن</div>
            <div>{watch("age")}</div>
          </div>
          <div className={gridItemSryle}>
            {" "}
            <div className="text-center">قد</div>
            <div>{watch("height")} سانتی متر</div>
          </div>
          <div className={gridItemSryle}>
            {" "}
            <div className="text-center">وزن فعلی</div>
            <div>{watch("weight")} کیلوگرم</div>
          </div>
          <div className={gridItemSryle} data-tip={watch("goal")}>
            {" "}
            <div className="text-center">هدف</div>
            <div className="truncate  w-28 2xl:w-44">{watch("goal")}</div>
          </div>
          <div className={gridItemSryle} data-tip={watch("sportBackground")}>
            {" "}
            <div className="text-center">سابقه ورزشی</div>
            <div className="truncate  w-28 2xl:w-44">
              {watch("sportBackground")}
            </div>
          </div>
          <div className={gridItemSryle} data-tip={watch("injuries")}>
            {" "}
            <div className="text-center">مصدومیت/بیماری</div>
            <div className="truncate  w-28 2xl:w-44">{watch("injuries")}</div>
          </div>
          <div className={gridItemSryle} data-tip={watch("specialMedicine")}>
            {" "}
            <div className="text-center">داروی خاص</div>
            <div className="truncate w-28 2xl:w-44">
              {watch("specialMedicine")}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[calc(100%-24rem)] lg:h-[calc(100%-18rem)] flex flex-col items-center">
        <div className="w-full lg:w-[80%] ">
          {" "}
          <div role="tablist" className="tabs tabs-bordered tabs-xs md:tabs-sm">
            <a
              role="tab"
              onClick={() => setSelectedTab(0)}
              className={`tab ${selectedTab === 0 && "tab-active"}`}
            >
              پروفایل
            </a>
            <a
              role="tab"
              onClick={() => setSelectedTab(1)}
              className={`tab ${selectedTab === 1 && "tab-active"}`}
            >
              فرم ها
            </a>
            <a
              role="tab"
              onClick={() => setSelectedTab(2)}
              className={`tab ${selectedTab === 2 && "tab-active"}`}
            >
              برنامه ورزشی
            </a>
            <a
              role="tab"
              onClick={() => setSelectedTab(3)}
              className={`tab ${selectedTab === 3 && "tab-active"}`}
            >
              آنالیز
            </a>
          </div>
        </div>
        <div className="h-full w-full xl:w-[95%]">
          {selectedTab === 0 && (
            <div className="w-full h-[98%] min-h-20 flex flex-col lg:flex-row justify-between items-start gap-x-3 gap-y-7 lg:gap-y-0 text-xs mt-5">
              <div className="w-full lg:w-1/3 h-full text-center flex flex-col items-center justify-start gap-y-2">
                <div>فعالیت ها</div>
                <div className="w-full h-[95%] min-h-[95%] shadow-none lg:shadow-xl flex flex-col items-center justify-start border gap-y-3 p-2 overflow-y-auto">
                  {athleteActivities && athleteActivities!.length > 0 ? (
                    athleteActivities!.map(
                      (activity: activityType, index: number) => (
                        <div
                          key={index}
                          className=" flex justify-between text-2xs 2xl:text-xs w-full gap-x-2 min-h-10 shadow-md border rounded-md p-2"
                        >
                          <div className="w-3/5 text-wrap text-right">
                            {activity.activityText}
                          </div>
                          <div className="w-2/5 text-left">
                            {toShamsi(activity.activityDatetime)}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div className="my-auto">فعالیتی برای نمایش وجود ندارد</div>
                  )}
                </div>
              </div>
              <div className="w-full hidden lg:flex lg:w-1/3 h-10 lg:h-full text-center  flex-col items-center justify-start gap-y-2">
                پیام ها
              </div>
              <div className="w-full lg:w-1/3 h-72 lg:h-full text-center flex flex-col items-center justify-start gap-y-2">
                <div>یادداشت ها</div>
                <div className="w-full h-[95%] min-h-[95%] shadow-none lg:shadow-xl flex flex-col items-center text-2xs 2xl:text-xs justify-between border gap-y-1 p-2 ">
                  <>
                    {" "}
                    <div
                      ref={divRef}
                      className="w-full h-[89%]  flex flex-col items-center justify-start  gap-y-1 border overflow-y-auto"
                    >
                      {athleteNotes && athleteNotes!.length > 0 ? (
                        athleteNotes!.map((note: noteType, index: number) => (
                          <div
                            key={index}
                            className=" flex flex-col justify-between items-start  w-full min-h-20 shadow-md border rounded-md p-2"
                          >
                            <div className="w-full text-wrap text-justify h-[90%] overflow-y-auto">
                              {note.noteText}
                            </div>
                            <div className="w-full flex justify-end min-h-[10%]">
                              <EditSVG
                                stroke="black"
                                className="w-3 h-3 ml-3 cursor-pointer"
                                onClick={() => {
                                  setSelectedNote(index);
                                  setNoteText(note.noteText!);
                                }}
                              />
                              <DeleteSVG
                                className="w-3 h-3 cursor-pointer"
                                onClick={() => delete_Note(note)}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="my-auto">
                          یادداشتی برای نمایش وجود ندارد
                        </div>
                      )}
                    </div>
                    <div className="bg-gray-200 w-full h-10 rounded-lg flex justify-between items-center pl-3">
                      <input
                        tabIndex={0}
                        type="text"
                        placeholder="افزودن یادداشت ..."
                        maxLength={100}
                        value={noteText}
                        className="bg-transparent pr-2 focus:outline-none w-[90%] text-wrap"
                        onChange={(e) => {
                          if (onlyLettersAndNumbers(e.target.value))
                            setNoteText(e.target.value);
                        }}
                      />
                      <div className="w-[15%] flex justify-between items-center gap-x-3">
                        <CloseSVG
                          onClick={() => {
                            setNoteText("");
                            setSelectedNote(-1);
                          }}
                          fill="black"
                          className={`w-3 h-3 cursor-pointer ${
                            noteText.length > 0 ? "visible" : "invisible"
                          }`}
                        />
                        <div onClick={setNote} className="cursor-pointer ">
                          ذخیره
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className="w-full h-[98%] min-h-32 lg:min-h-10 flex flex-col  justify-start items-center gap-y-4">
              <div className="w-full md:w-32 h-10 mt-4">
                <div
                  className="btn-1 !h-9 text-white  bg-color1 mt-2"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    setShowPlansModal(true);
                  }}
                >
                  اختصاص برنامه
                </div>
              </div>
              <div className="w-[97%] h-[75%] 2xl:h-[85%] mx-2 text-xs 2xl:text-sm">
                <div className="w-full h-10 max-md:hidden">
                  <div className="w-full h-10 text-center  flex ">
                    <div className="w-[15%]"></div>
                    <div className="w-[5%] "></div>
                    <div className="w-[21%] ">نام برنامه</div>
                    <div className="w-[12%]"> تاریخ اختصاص</div>
                    <div className="w-[10%]">مدت برنامه</div>
                    <div className="w-[10%]">پیشرفت</div>
                    <div className="w-[15%]">وضعیت</div>
                  </div>
                </div>
                <div className="h-full overflow-y-auto ">
                  {athletePlans &&
                    athletePlans!.length > 0 &&
                    athletePlans!.map(
                      (plan: athletePlanType, index: number) => (
                        <div
                          key={index}
                          className=" md:bg-white max-md:h-80  border-b-2 text-center text-xs  h-28  flex flex-col   md:flex-row  items-center "
                        >
                          <div
                            className={`md:w-[15%] md:flex md:justify-center ${rowStyles} !h-28`}
                          > 
                            <div className={`${colStyles}`}></div>
                            <div className="w-16  h-full  rounded-lg overflow-hidden flex justify-center items-center ">
                              <img
                                src={
                                  plan.planPosterUrl
                                    ? `${moviesUrl}/plan/${plan.planId}/poster.png`
                                    : Player.imageUrl
                                }
                                alt={plan.planName}
                                style={{ width: "90%", height: "90%" }}
                              />
                            </div>
                          </div>
                          <div className={`md:w-[5%] ${rowStyles}`}>
                            <div className={`${colStyles}`}></div>
                            <DeleteSVG
                              className="w-4 h-4 cursor-pointer"
                              onClick={() => {
                                setSelectedPlanId(plan.planId);
                                setShowModal(true);
                              }}
                            />
                          </div>
                          <div className={`md:w-[21%] ${rowStyles}`}>
                            <div className={`${colStyles}`}>نام برنامه</div>
                            {plan.planName}
                          </div>
                          <div className={`md:w-[12%] ${rowStyles}`}>
                            <div className={`${colStyles}`}>تاریخ اختصاص </div>
                            {toShamsi(plan.assignDatetime)}
                          </div>{" "}
                          <div className={`md:w-[10%] ${rowStyles}`}>
                            <div className={`${colStyles}`}>مدت برنامه</div>
                            <div>
                              {plan.planDuration} <span>هفته</span>
                            </div>
                          </div>{" "}
                          <div className={`md:w-[10%] ${rowStyles}`}>
                            <div className={`${colStyles}`}>پیشرفت</div>
                            <div>
                              <span className="text-lg">%</span>{" "}
                              {plan.planProgress?.toString().replace("%", "")}{" "}
                            </div>
                          </div>
                          <div className={`md:w-[15%] ${rowStyles}`}>
                            <div className={`${colStyles}`}>پیشرفت</div>
                            <div className=" flex justify-center items-center gap-x-2 ">
                              <span>
                                {plan.status ? "فعـــال" : "غیر فعال"}
                              </span>
                              <input
                                type="checkbox"
                                className="toggle toggle-sm toggle-primary"
                                checked={plan.status}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PlansModal
        showModal={showPlansModal}
        exitBtnClick={() => setShowPlansModal(false)}
        selectPlanBtnClick={(planId: string) => {
          selectPlan(planId);
          setShowPlansModal(false);
        }}
      >
        <GiSaveArrow className="w-3" />
      </PlansModal>
      <ConfirmModal
        acceptMessage="حذف شود"
        rejectMessage="منصرف شدم"
        title="برنامه انتخاب شده حذف شود؟"
        desc=""
        showModal={showModal}
        acceptBtnClick={acceptBtnClick}
        rejectBtnClick={rejectBtnClick}
      >
        <RiDeleteBinLine className="w-3" />
      </ConfirmModal>
    </form>
  );
};

export default Athlete;