import { SVGProps } from "react";

function PublishedSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 48 60"
      enableBackground="new 0 0 48 48"
    >
      <g>
        <path
          fill="#046604"
          d="M33,13.5H15v-10h5.1c0.4-1.7,2-3,3.9-3c1.9,0,3.4,1.3,3.9,3H33V13.5z M17,11.5h14v-6h-5v-1c0-1.1-0.9-2-2-2   s-2,0.9-2,2v1h-5V11.5z"
        />
        <path
          fill="#046604"
          d="M41,47.5H7v-40h10v4h14v-4h10V47.5z M9,45.5h30v-36h-6v4H15v-4H9V45.5z"
        />
        <polygon
          fill="#046604"
          points="20.1,34.9 15.2,30 16.6,28.6 20.1,32.1 31.4,20.8 32.8,22.2  "
        />
      </g>
      <text
        x="0"
        y="63"
        fill="#046604"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        Created by Creative Stall
      </text>
      <text
        x="0"
        y="68"
        fill="#046604"
        fontSize="5px"
        fontWeight="bold"
        fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"
      >
        from the Noun Project
      </text>
    </svg>
  );
}
export default PublishedSVG;
