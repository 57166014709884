import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import EyeoffSVG from "components/svg/EyeoffSVG";
import LogoSVG from "components/svg/logoSvg";
import { useState } from "react";
// import { Navigate, useNavigate } from "react-router-dom";
import EyeSVG from "components/svg/EyeSVG";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { loginType } from "types";
import SpinnerSVG from "components/svg/spinnerSvg";
import { Change_REACT_APP_URL, maskUserId } from "components/helper/publicLib";
import { loginMethod } from "services/loginService";
import { saveTokens } from "services/authService";
// const FRONT_URL = process.env.REACT_APP_FRONT_URL;

const initialValues: DefaultValues<loginType> = {
  email_or_phone: "",
  password: "",
};

const Login = () => {
  // let navigate = useNavigate();
  const form = useForm<loginType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    resetField,
    // formState,
    trigger,
    getFieldState,
    setFocus,
    formState: {
      errors,
      // isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      // isisSubmitting,
      dirtyFields,
    },
  } = form;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  // const [authenticated, setauthenticated] = useState<boolean>(
  //   localStorage.getItem("authenticated") ? true : false
  // );

  const showPasswordEvent = (event: any) => {
    setShowPassword((prev) => !prev);
  };

  const registerOptions = {
    email_or_phone: {
      required: "تلفن همراه یا ایمیل خود را وارد نمایید",
      validate: (value: any) => {
        if (!isNaN(value)) {
          if (!value.startsWith("09") || value.length !== 11)
            //mobile
            return "شماره همراه وارد شده معتبر نیست";
        } else {
          var re = /\S+@\S+\.\S+/;
          if (!re.test(value)) return "ایمیل وارد شده معتبر نیست";
        }
      },
    },

    password: {
      required: "رمز عبور را وارد نمایید",
      validate: (value: any) => {
        const validPass =
          /(\d.*[A-Z])|([A-Z].*\d)/.test(value) &&
          /\d/.test(value) &&
          /[a-z]/.test(value);
        if (!validPass) return "حداقل یک حرف بزرگ، کوچک و عدد وارد نمایید";
      },
      minLength: {
        value: 6,
        message: "طول رمز عبور باید حداقل 6 کاراکتر باشد",
      },
    },
  };

  const onSubmit: SubmitHandler<loginType> = async (data: loginType) => {
    if (isSubmitting || !isValid) return;
    try {
      const { data: retVal } = await loginMethod({
        userId: maskUserId(data.email_or_phone!),
        password: data.password!,
      });

      if (retVal.success && retVal.data.accessToken) {
        sessionStorage.setItem("authenticated", "true");
        await saveTokens(retVal.data);
        window.location.href = `${Change_REACT_APP_URL(
          window.location.href
        )}/main`;
      } else {
        toast.error("خطای ورود به سامانه");
      }
    } catch (error) {
      if ((error as any).response?.data)
        toast.error("نام کاربری یا رمز عبور اشتباه است");
      else toast.error("خطای دسترسی");
    }
  };

  return (
    <main className="flex h-screen w-screen flex-col items-center  justify-start gap-y-3 2xl:gap-y-5">
      <LogoSVG className="w-40 h-40 2xl:w-48 2xl:h-48 mt-2 2xl:mt-7 " />

      <div className="text-color1 text-base 2xl:text-xl">ورود به داشبورد</div>
      <FormProvider {...form}>
        <form
          className="w-full sm:w-96 flex flex-col  2xl:text-sm  px-2 gap-y-5 mt-7"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full sm:w-96 flex  text-sm  px-2">
            <label
              data-tip={errors.email_or_phone && errors.email_or_phone.message}
              className={`form-control w-full  ${
                errors.email_or_phone && "tooltip tooltip-bottom"
              }`}
            >
              <div className="label justify-start">
                <span className=""> تلفن همراه / ایمیل</span>
              </div>

              <input
                type="text"
                id="email_or_phone"
                {...register("email_or_phone", registerOptions.email_or_phone)}
                placeholder="تلفن همراه یا ایمیل خود را وارد نمایید"
                className={`input h-9 input-bordered w-full placeholder:text-xs focus:outline-none ltr ${
                  errors?.email_or_phone && "border-1 border-red-600"
                }`}
              />
            </label>
          </div>
          <div className="w-full sm:w-96 flex text-sm px-2 relative">
            <label
              data-tip={errors.password && errors.password.message}
              className={`form-control w-full  ${
                errors.password && "tooltip tooltip-bottom"
              }`}
            >
              <div className="label justify-start">
                <span className=""> رمز عبور</span>
              </div>

              <input
                id="password"
                maxLength={20}
                type={!showPassword ? "password" : "text"}
                {...register("password", registerOptions.password)}
                placeholder="رمز عبور خود را وارد نمایید"
                className={`input  h-9 input-bordered  w-full bg-white  placeholder:text-right placeholder:text-xs ltr focus:outline-none ${
                  errors.password && "border-1 border-red-600"
                }`}
              />
            </label>
            <div
              onMouseDown={showPasswordEvent}
              // onMouseUp={showPasswordEvent}
              className={`${
                !getValues("password") && "hidden"
              } absolute cursor-pointer  w-6 right-4 bottom-2`}
            >
              {!showPassword ? (
                <EyeSVG className="h-[20px] w-[20px]" />
              ) : (
                <EyeoffSVG className="h-[20px] w-[20px]" />
              )}
            </div>
          </div>
          <div className="w-full flex items-center justify-center ">
            <Link
              to={"/forgetPassword"}
              className="text-sm text-center transition-all underline text-color1"
            >
              فراموشی رمز عبور
            </Link>
          </div>
          <div className="w-full sm:w-96 flex flex-col justify-center text-xs px-2 mt-2 gap-y-2">
            <button className="btn-1 text-white flex justify-center  bg-color1 w-full">
              {isSubmitting && (
                <SpinnerSVG className="animate-spin h-5 w-5 text-white" />
              )}
              <span>ورود</span>
            </button>
          </div>
          <div className="w-full flex items-center justify-center ">
            <Link
              to={"/signUp"}
              className="text-sm text-center transition-all  text-color1"
            >
              قبلا ثبت‌ نام نکرده‌اید؟ اکنون ثبت‌ نام کنید
            </Link>
          </div>
        </form>
      </FormProvider>
    </main>
  );
};

export default Login;
