import { getAllMovements, removeMovement } from "services/movementService";
import React, { MouseEvent, useContext, useEffect, useState } from "react";
import ConfirmModal from "components/modals/confirmModal";
import { SubmitHandler, useForm } from "react-hook-form";
import LoadingMovements from "pages/loadingMovements";
import { RiDeleteBinLine } from "react-icons/ri";
import DeleteSVG from "components/svg/deleteSvg";
import SearchSVG from "components/svg/searchSvg";
import FilterSVG from "components/svg/filterSvg";
import { useNavigate } from "react-router-dom";
import CloseSVG from "components/svg/closeSvg";
import VideoHover from "components/videoHover";
import { MyContext } from "components/Context";
import { getTags } from "services/tagsService";
import Filter from "components/modals/filter";
import { tagType, movementType } from "types";
import EditSVG from "components/svg/editSvg";
import ViewSVG from "components/svg/viewSvg";
import { toast } from "sonner";
import Player from "components/player.json";
// import movementTags from "../components/movementTags.json";
// import movementsData from "../components/movements.json";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;
interface Search {
  searchTxt: string;
}

interface MovementsProps {
  isModal?: boolean;
  selectMovement: (movementId: string, movementType: number) => void;
}

const Movements = ({ isModal, selectMovement }: MovementsProps) => {
  // const [isPlaying, setIsPlaying] = useState<boolean>();
  const [originalTags, setOriginalTags] = useState<tagType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [selectedMovementId, setSelectedMovementId] = useState<
    string | undefined
  >("0");

  const [firteredData, setFirteredData] = useState<movementType[]>([]);
  const { typeOfMovement, setTypeOfMovement } = useContext(MyContext);
  const [localTypeOfMovement, setLocalTypeOfMovement] =
    useState<boolean>(typeOfMovement);

  //const { setMovementsData, movementsData } = useContext(MyContext);
  const [movementsData, setMovementsData] = useState<movementType[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchAPI() {
      try {
        // if (!movementsData || movementsData.length === 0) {
        const movementRetVal = await getAllMovements();

        if (movementRetVal.status === 200 && movementRetVal.data) {
          const movements: movementType[] = movementRetVal.data.result.map(
            (movement: any, idx: number) => ({
              id: movement.id,
              type: movement.type,
              name: movement.name,
              poster: movement.poster || "",
              description: movement.description,
              movie: movement.movie,
              tags: movement.tags || [],
            })
          );
          setMovementsData(movements);
          setFirteredData(movements);
          setIsLoadingData(false);
          if (originalTags.length === 0) {
            const retVal = await getTags();

            if (retVal.status === 200) {
              setOriginalTags(
                retVal.data.result.map((tag: string) => ({
                  id: tag,
                  text: tag,
                  checked: false,
                }))
              );
            } else {
              toast.error("خطا در فراخوانی اطلاعات");
              return;
            }
          }
        }
        // } else setFirteredData(movementsData);
        setIsLoadingData(false);
        // resolve("ok");
        //   }, 1);
        // });
      } catch (error) {
        setIsLoadingData(false);
        toast.error("خطا در فراخوانی اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  const acceptBtnClick = async (e: MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      await removeMovement(selectedMovementId!);

      const movementsDataLocal = movementsData.filter(
        (item: movementType) => item.id !== selectedMovementId
      );

      setMovementsData(movementsDataLocal);
      setFirteredData(movementsDataLocal);
      setShowModal(false);
      toast.success("عملیات با موفقیت انجام پذیرفت");
    } catch (error) {
      toast.error("خطا در عملیات");
    }
    setIsLoadingData(false);
  };
  const rejectBtnClick = () => {
    setShowModal(false);
  };

  // const handleMouseEnter = (e: any) => {
  //   const vid = e.target;
  //   //vid.muted = true;
  //   let p = vid.play();
  //   if (p !== undefined) p.catch(function () {});
  //   setIsPlaying(true);
  // };
  // const handleMouseLeave = (e: any) => {
  //   if (isPlaying) {
  //     const vid = e.target;
  //     // vid.muted = false;
  //     vid.currentTime = 0;
  //     vid.pause();
  //   }
  // };
  const onSubmit: SubmitHandler<Search> = async (inputSearch: Search) => {
    const localPA = movementsData.filter((item: movementType) =>
      item.name.includes(inputSearch.searchTxt)
    );
    //setData(localPA);
    setFirteredData(localPA);
  };

  const { register, handleSubmit } = useForm<Search>({
    mode: "onSubmit",
    defaultValues: { searchTxt: "" },
  });

  const setfilterChecked = (id: number) => {
    let tmpData: any = [...originalTags];
    const idx = tmpData.findIndex((item: tagType) => item.id === id.toString());
    tmpData[idx].checked = !tmpData[idx].checked;
    setOriginalTags(tmpData);
  };

  const filterClear = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const clearedTags = originalTags.map((item) => ({
      ...item,
      checked: false,
    }));
    setOriginalTags(clearedTags);
    setShowFilter(!showFilter);
    // setData(movementsData);
    setFirteredData(movementsData);
  };
  const filterData = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let tempData: movementType[] = [];
    const checkedTags = originalTags.filter((item) => item.checked);

    if (checkedTags.length > 0) {
      movementsData.map((item: movementType) => {
        item.tags &&
          item.tags.map((Litem: any) => {
            const llItem = Litem.text;
            checkedTags.map(
              (tagItem) =>
                tagItem.checked &&
                tagItem.text === llItem &&
                tagItem.checked &&
                tagItem.text === llItem &&
                !tempData.find(({ id }) => id === item.id) &&
                tempData.push(item)
            );
          });
        return true;
      });
      // setData(tempData);
      setFirteredData(tempData);
    } else {
      // setData(movementsData);
      setFirteredData(movementsData);
    }
    setShowFilter(!showFilter);
  };
  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center ${
        isLoadingData && "overflow-hidden"
      } `}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col-reverse md:flex-row  justify-between h-18 md:h-10 w-full mt-5 md:mt-1 px-2 gap-y-2 "
      >
        {!isModal && (
          <div
            className="btn-1 !h-9 text-white  bg-color1 mt-2"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              navigate("/movement");
            }}
          >
            <div className="mx-2 text-xl dark:text-white">+ </div>
            اضافه کردن حرکت
          </div>
        )}

        <div
          className={`h-8 w-full md:w-72 flex relative justify-start items-center gap-x-2 mt-2 ml-2 ${
            isModal && "mr-auto"
          }`}
        >
          <div className="w-4 relative cursor-pointer">
            {!showFilter && (
              <FilterSVG
                fill={
                  originalTags.filter((item) => item.checked).length !== 0
                    ? "blue"
                    : "black"
                }
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                className="w-full "
              />
            )}
            {showFilter && (
              <CloseSVG
                fill={`black`}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              />
            )}

            {showFilter && (
              <div className="absolute mt-4">
                <Filter
                  data={originalTags}
                  setChecked={setfilterChecked}
                  title="حرکت انتخاب شده حذف شود؟"
                  desc="در صورت پاک کردن این حرکت تمام محتوای آن حذف خواهد شد."
                  showModal={showFilter}
                  filterDone={filterData}
                  filterClear={filterClear}
                />
              </div>
            )}
          </div>

          <div className=" h-full w-full flex relative justify-center items-center">
            <input
              autoFocus
              {...register("searchTxt")}
              type="text"
              className="w-full border h-full shadow py-2 px-6 rounded-lg "
              placeholder="جستجو"
            />
            <button type="submit" className="absolute left-5">
              <SearchSVG className="text-black  w-5  top-3.5 right-3 fill-current" />
            </button>
          </div>
        </div>
      </form>
      <div className="w-full flex flex-col gap-y-2 h-[calc(100vh-4.8rem)] md:h-[calc(100vh-2.8rem)]">
        <div className="form-control w-full mt-3 md:w-[40%] lg:w-[30%]  self-center h-11">
          <label className="cursor-pointer label rounded-lg !gap-5">
            <span
              className={`label-text w-1/2 p-2 text-center ${
                !localTypeOfMovement ? "bg-color2 rounded-md" : "border-b-2"
              }`}
            >
              حرکات من
            </span>
            <input
              type="checkbox"
              onChange={() => setLocalTypeOfMovement(!localTypeOfMovement)}
              className="toggle toggle-primary hidden"
            />
            <span
              className={`label-text w-1/2 p-2  text-center ${
                localTypeOfMovement ? "bg-color2 rounded-md" : "border-b-2"
              }`}
            >
              بانک حرکات
            </span>
          </label>
        </div>
        <div
          className={`flex md:self-start md:ml-auto content-start items-start  justify-center md:justify-start gap-x-4  mt-2 mr-2 flex-wrap h-[92%]  ${
            isLoadingData ? "overflow-hidden " : "overflow-y-auto "
          } `}
        >
          {isLoadingData ? (
            <LoadingMovements />
          ) : (
            !localTypeOfMovement &&
            movementsData &&
            (movementsData.filter((item: movementType) => item.type === 1)
              .length === 0 ? (
              <div className="h-10 w-full mx-5 ">
                <div>لیست شما خالی است.</div>
                {/* <a className="btn-1 text-white  bg-color1" href="/#movement">
                  <span className="mx-2 text-xl">+ </span>
                  اضافه کردن حرکت
                </a> */}
              </div>
            ) : (
              firteredData
                .filter((item: movementType) => item.type === 1)
                .map((movement: movementType) => (
                  <div
                    key={movement.id}
                    className="card card-compact bg-transparent justify-between w-44 h-60 md:w-36  md:h-52 bg-base-100 mb-4 hover:shadow-md border "
                  >
                    <figure
                      className={`relative h-full w-full rounded-lg  text-white hover:text-black ${
                        isModal && "cursor-pointer"
                      }`}
                      onClick={() =>
                        isModal && selectMovement(movement.id!, movement.type!)
                      }
                    >
                      <h2 className="absolute top-3 right-3 text-sm z-10  ">
                        {movement.name}
                      </h2>
                      <VideoHover
                        // src={movement.movie?.toString()!}
                        src={`${moviesUrl}/media/${movement.id}/media.mp4`}
                        // poster={Player.imageUrl}
                        poster={`${moviesUrl}/media/${movement.id}/thumbnail.png`}
                        classes="w-full h-full"
                      />
                    </figure>
                    {!isModal && (
                      <div className="w-full flex justify-between px-2 h-7 mt-1 ">
                        <div
                          data-tip="ویرایش"
                          className="cursor-pointer tooltip tooltip-left"
                        >
                          <button
                            onClick={() => {
                              setTypeOfMovement(localTypeOfMovement);
                              navigate(`/movement/${movement.id}`);
                            }}
                          >
                            <EditSVG className="w-4 text-black h-4" />
                          </button>
                        </div>
                        <div
                          data-tip="حذف"
                          className="cursor-pointer tooltip tooltip-right"
                          onClick={() => {
                            setSelectedMovementId(movement.id);
                            setShowModal(true);
                          }}
                        >
                          <DeleteSVG className="w-4 h-4" />
                        </div>
                      </div>
                    )}
                  </div>
                ))
            ))
          )}
          {!isLoadingData &&
            localTypeOfMovement &&
            firteredData &&
            firteredData.filter((item: movementType) => item.type === 0)
              .length > 0 &&
            firteredData
              .filter((item: movementType) => item.type === 0)
              .map((movement: movementType) => (
                <div
                  key={movement.id}
                  className="card card-compact bg-transparent justify-between w-44 h-60 md:w-36  md:h-52 bg-base-100 mb-4 hover:shadow-md border"
                >
                  <figure
                    className={`relative h-full w-full rounded-lg  text-white hover:text-black ${
                      isModal && "cursor-pointer"
                    }`}
                    onClick={() =>
                      isModal && selectMovement(movement.id!, movement.type!)
                    }
                  >
                    <h2 className="absolute top-3 right-3 text-sm z-10">
                      {movement.name}
                    </h2>
                    <VideoHover
                      src={movement.movie?.toString()!}
                      poster={Player.imageUrl}
                      classes="w-full h-full"
                    />
                  </figure>
                  {!isModal && (
                    <div className="flex h-11 px-3  items-center">
                      <div
                        data-tip="مشاهده اطلاعات"
                        className="cursor-pointer tooltip tooltip-left"
                      >
                        <button
                          onClick={() => {
                            setTypeOfMovement(localTypeOfMovement);
                            navigate(`/movement/${movement.id}`);
                          }}
                        >
                          <ViewSVG className="w-4 text-black" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          <ConfirmModal
            acceptMessage="حذف شود"
            rejectMessage="منصرف شدم"
            title="حرکت انتخاب شده حذف شود؟"
            desc="در صورت پاک کردن این حرکت تمام محتوای آن حذف خواهد شد."
            showModal={showModal}
            acceptBtnClick={acceptBtnClick}
            rejectBtnClick={rejectBtnClick}
          >
            <RiDeleteBinLine className="w-3" />
          </ConfirmModal>
        </div>
      </div>
    </div>
  );
};

export default Movements;
