import { useEffect } from "react";
import { getUserInfo } from "services/loginService";
import { toast } from "sonner";
import { UseStore } from "store/useStore";

const Main = () => {
  const updateUserLogedInData = UseStore(
    (state) => state.updateUserLogedInData
  );

  //  const userLogedInData = UseStore((state) => state.userLogedInData);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const { data } = await getUserInfo();
        if (data.success && data.data.claimsIdentity.length > 4) {
          updateUserLogedInData({
            userName: data.data.claimsIdentity[0].value,
            userId: data.data.claimsIdentity[1].value,
            firstName: data.data.claimsIdentity[2].value,
            lastName: data.data.claimsIdentity[3].value,
            role: data.data.claimsIdentity[4].value,
            
          });
          //        console.log("userLogedInData", userLogedInData);
        }
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);
  return <div className="h-full w-full"></div>;
};

export default Main;
