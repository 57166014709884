import React, { Dispatch, SetStateAction, useState } from "react";
import DashboardSVG from "components/svg/dashboardSvg";
import AnalyticsSVG from "components/svg/analyticsSvg";
import BrandingSVG from "components/svg/brandingSvg";
import SettingSVG from "components/svg/settingSvg";
import TrainerSVG from "components/svg/trainerSvg";
import PackgeSVG from "components/svg/packageSvg";
import { signOut } from "services/authService";
import ArrowSVG from "components/svg/arrowSvg";
import FormSVG from "components/svg/formSvg";
import PlanSVG from "components/svg/planSvg";
import LogoSVG from "components/svg/logoSvg";
import ExitSVG from "components/svg/exitSvg";
import { NavLink, useNavigate } from "react-router-dom";

interface SideBarProps {
  show: boolean;
  setter: Dispatch<SetStateAction<boolean>>;
}

interface MenuItemProps {
  icon: any;
  name: string;
  route: string;
}
interface ColapsMenuItemProps {
  icon: any;
  name: string;
  routes: string[];
  subNames?: string[];
  // ClickedMenuWithSubmenu: () => void;
}

const SideBar = ({ show, setter }: SideBarProps) => {
  const [selectedMenu, setSelectedMenu] = useState<string>("");
  // Define our base class
  const className =
    "bg-white w-[250px] h-screen  transition-[margin-right] ease-in-out duration-500 fixed md:static top-0 bottom-0 right-0 z-40 overflow-y-auto";
  // Append class based on state of sidebar visiblity
  const appendClass = show ? " mr-0" : " mr-[-250px] md:mr-0";

  const clickedMenu = () => {
    setter((oldVal: boolean) => !oldVal);
  };

  const ColapsMenuItem = ({
    icon,
    name,
    subNames,
    routes,
  }: ColapsMenuItemProps) => {
    // Highlight menu item based on currently displayed route
    const [hover, setHover] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState<boolean>(
      routes.find((item) => item === selectedMenu) ? true : false
    );

    return (
      <div
        className={`w-full relative text-black ${
          !isOpen && !hover && " hover:bg-color2 rounded-lg"
        }`}
      >
        <input
          checked={isOpen}
          onChange={() => {
            setIsOpen(!isOpen);
            setTimeout(async () => {
              setHover(!isOpen);
            }, 200);
          }}
          type="checkbox"
          className="peer absolute top-0 inset-x-0 z-10 h-12 min-w-full  opacity-0 cursor-pointer"
        />
        <div className="  h-12 w-full  flex items-center rounded-lg gap-x-1    gap-1 [&>*]:my-auto   pl-6 py-3  border-b-[1px] border-b-white/10 pr-2">
          <span className="text-xl flex [&>*]:mx-auto w-[30px] ">{icon}</span>
          <div className="">{name}</div>
        </div>

        <div className="absolute top-3 left-1 text-black transition-transform duration-500 rotate-0 peer-checked:rotate-180">
          <ArrowSVG className="h-5 w-5 mt-1" />
        </div>

        <div className="parent overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40 ">
          {subNames?.map((subName, index) => (
            <div
              key={index}
              // className="child p-4"
              className={`child mt-1 h-8 flex justify-start pr-12  items-center text-xs ${
                selectedMenu === routes[index]
                  ? "bg-color1 text-white rounded-lg "
                  : "text-black  hover:bg-color2 rounded-lg"
              }`}
            >
              <NavLink
                onClick={(e: any) => {
                  clickedMenu();
                  setSelectedMenu(routes[index]);
                }}
                to={`${routes[index]}`}
              >
                <div>{subName}</div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ColapsMenuItem1 = ({
    icon,
    name,
    subNames,
    routes,
  }: ColapsMenuItemProps) => {
    // Highlight menu item based on currently displayed route

    const [isOpen, setIsOpen] = useState<boolean>(
      routes.find((item) => item === selectedMenu) ? true : false
    );

    return (
      <div
        className="collapse collapse-arrow !-mt-1 !-mb-2 cursor-pointer"
        onClick={(e: any) => {
          setIsOpen(!isOpen);
        }}
      >
        <input type="radio" name={`my-accordion-2 `} checked={isOpen} />
        <div
          className={`collapse-title flex  text-black gap-x-2  ${
            isOpen && "bg-color2 rounded-xl "
          }`}
        >
          <span className="mt-1">{icon}</span>
          <div className="">{name}</div>
        </div>
        <div className="collapse-content flex-col text-xs   ">
          {subNames?.map((subName, index) => (
            <div
              key={index}
              className={`mt-1 h-8 flex justify-start pr-10  items-center  ${
                selectedMenu === routes[index]
                  ? "bg-color1 text-white rounded-lg "
                  : "text-black  hover:bg-color2 rounded-lg"
              }`}
            >
              <NavLink
                onClick={(e: any) => {
                  clickedMenu();
                  setSelectedMenu(routes[index]);
                }}
                to={`${routes[index]}`}
              >
                <div>{subName}</div>
              </NavLink>
              {/* <a
                href={`${routes[index]}`}
                onClick={(e: any) => {
                  clickedMenu();
                  setSelectedMenu(routes[index]);
                }}
              >
                <div>{subName}</div>
              </a> */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Clickable menu items
  const MenuItem = ({ icon, name, route }: MenuItemProps) => {
    let navigate = useNavigate();
    // Highlight menu item based on currently displayed route
    const colorClass =
      selectedMenu === route
        ? "bg-color1 text-white rounded-lg "
        : "text-black hover:bg-color2  rounded-lg ";

    return (
      <a
        // href={`${route === "EXIT" ? "#" : route}`}
        onClick={() => {
          if (route === "EXIT") {
            signOut();
          } else {
            clickedMenu();
            setSelectedMenu(route);
            navigate(`/${route}`);
          }
        }}
        className={`flex  gap-1 [&>*]:my-auto  cursor-pointer  pl-6 py-3  border-b-[1px] border-b-white/10 pr-2 ${colorClass}`}
      >
        <div className="text-xl flex [&>*]:mx-auto w-[30px]">{icon}</div>
        <div>{name}</div>
      </a>
    );
  };

  // Overlay to prevent clicks in background, also serves as our close button
  const ModalOverlay = () => (
    <div
      className={`flex md:hidden  fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
      onClick={() => {
        clickedMenu();
      }}
    />
  );

  return (
    <>
      <div className={`${className}${appendClass} text-sm`}>
        <div className=" flex w-full justify-center">
          <a href="/">
            <LogoSVG className="w-36 h-28 2xl:w-44 2xl:h-44 mt-1" />
            {/*eslint-disable-next-line*/}
            {/* <img
              src="./images/Logo.jpg"
              alt="Company Logo"
              width={150}
              height={150}
            /> */}
          </a>
        </div>

        <div className="flex flex-col w-[90%] mx-2 ">
          <div className="flex gap-x-2 mr-3 my-3">
            <DashboardSVG className="w-4" />
            <div className="text-black">مدیریت داشبورد</div>
          </div>
          <MenuItem
            name="آنالیز"
            route="analyse"
            icon={<AnalyticsSVG className="w-4" />}
          />

          <ColapsMenuItem
            name="فرم ها"
            subNames={["فرم ابتدایی", "فرم کنترل"]}
            routes={["elementary", "control"]}
            icon={<FormSVG className="w-4" />}
          />
          <ColapsMenuItem
            name="برنامه ورزشی"
            subNames={["لیست حرکات", "لیست برنامه ها"]}
            routes={["movements", "plans"]}
            icon={<PlanSVG className="w-4" />}
          />
          <MenuItem
            name="ورزشکاران"
            route="athletes"
            icon={<TrainerSVG className="w-4" />}
          />
          <MenuItem
            name="پکیج"
            route="packages"
            icon={<PackgeSVG className="w-4  " />}
          />
          <MenuItem
            name="برندینگ"
            route="branding"
            icon={<BrandingSVG className="w-4" />}
          />
          <MenuItem
            name="تنظیمات"
            route="settings"
            icon={<SettingSVG className="w-4" />}
          />
          <MenuItem
            name="خروج"
            route="EXIT"
            icon={<ExitSVG className="w-[13px] !mr-3" />}
          />
        </div>
      </div>
      {show ? <ModalOverlay /> : <></>}
    </>
  );
};

export default SideBar;
