import React, { useImperativeHandle, useRef, useState } from "react";
import { onlyLettersAndNumbers } from "components/helper/publicLib";
import { useFieldArray, useFormContext } from "react-hook-form";
import ConfirmModal from "components/modals/confirmModal";
import { RiDeleteBinLine } from "react-icons/ri";
import { questionType } from "types";
import { toast } from "sonner";

import DeleteSVG from "../svg/deleteSvg";

const DefinedQuestions = () => {
  const [newQuestion, setNewQuestion] = useState<boolean>(false);
  const [questionText, setQuestionText] = useState<string>("");
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    register,
    control,
    formState,
    reset,
    setValue,
    clearErrors,
    watch,
    resetField,
    getFieldState,
    getValues,
    trigger,
  } = useFormContext();
  const { isValid, errors } = formState;

  const {
    fields: questions,
    append: appendQuestion,
    remove: removeQuestion,
    // update: updateExercise,
  } = useFieldArray({
    control,
    name: `questions`,
  });
  const preDefinedQuestionCount: number = questions.filter(
    (question: questionType) => question.isCustom === false
  ).length;
  const questionRef = useRef<HTMLInputElement>(null);

  const DeleteQuestion = (questionIndex: number) => {
    setSelectedQuestion(preDefinedQuestionCount + questionIndex);
    setShowModal(true);
  };

  const acceptBtnClick = async () => {
    try {
      removeQuestion(selectedQuestion);

      setShowModal(false);
    } catch (error) {
      toast.error("خطا در حذف سوال");
    }
  };
  const rejectBtnClick = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col w-full gap-y-5 max-sm:pb-3">
      {questions
        .filter((question: questionType) => question.isCustom === true)
        .map((question: questionType, idx) => (
          <div
            key={idx}
            className="flex flex-row  justify-start w-full gap-x-5"
          >
            <DeleteSVG
              className="w-4 h-6 cursor-pointer"
              onClick={() => DeleteQuestion(idx)}
            />
            <input
              {...register(`questions.${preDefinedQuestionCount + idx}.text`)}
              className="border-0 bg-transparent "
              disabled={true}
              readOnly={true}
            />
          </div>
        ))}

      <div className={`${!newQuestion && "hidden"} w-full md:w-1/2 flex-col`}>
        <div className=" w-full  flex  ">
          <label
            data-tip={errors.formName && errors.formName.message}
            className={`form-control w-full  ${
              errors.formName && "tooltip tooltip-bottom"
            }`}
          >
            <input
              tabIndex={0}
              type="text"
              value={questionText}
              ref={questionRef}
              onChange={(e) =>
                onlyLettersAndNumbers(e.target.value) &&
                setQuestionText(e.target.value)
              }
              className={`input max-2xl:text-xs 2xl:text-sm h-8 input-bordered w-full  ${
                errors.formName && "border border-red-600"
              }`}
              placeholder="سوال خود را تایپ نمایید ..."
            />
          </label>
        </div>
        <div
          onClick={() => {
            if (questionText)
              appendQuestion({
                id: (getValues("questions").length + 1).toString(),
                text: questionText,
                isChecked: true,
                isNeeded: false,
                isCustom: true,
              });

            setNewQuestion(false);
          }}
          className="btn-1 !h-9 w-full sm:w-24 text-white  bg-color1 mt-2 mr-auto"
        >
          ذخیره
        </div>
      </div>

      <div
        onClick={() => {
          questionRef.current?.focus();
          setQuestionText("");
          setNewQuestion(true);
        }}
        className="btn-1 !h-9 w-full sm:w-36 text-white  bg-color1 mt-2 "
      >
        <div className="mx-2 text-xl dark:text-white">+ </div>
        اضافه کردن سوال
      </div>
      <ConfirmModal
        acceptMessage="حذف شود"
        rejectMessage="منصرف شدم"
        title="گزینه انتخاب شده حذف شود؟"
        desc=""
        showModal={showModal}
        acceptBtnClick={acceptBtnClick}
        rejectBtnClick={rejectBtnClick}
      >
        <RiDeleteBinLine className="w-3" />
      </ConfirmModal>
    </div>
  );
};

export default DefinedQuestions;
