import {
  useFormContext,
  SubmitHandler,
  useFieldArray,
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  exerciseType,
  movementType,
  circuitType,
  dayType,
  setType,
} from "types";
import {
  onlyDigits,
  onlyLettersAndNumbers,
  uuidv4,
} from "components/helper/publicLib";
import MovementsModal from "components/modals/movementsModal";
import { getMovement } from "services/movementService";
import React, { useEffect, useRef, useState } from "react";
import Numbring from "components/numbering.json";
import DeleteSVG from "components/svg/deleteSvg";
import VideoHover from "components/videoHover";
import { GiSaveArrow } from "react-icons/gi";
import SwapSVG from "components/svg/swapSvg";
import ExitSVG from "components/svg/exitSvg";
import { toast } from "sonner";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;
interface Props {
  currentWeek: number;
  currentDay: number;
  dayData: dayType;
  circuitData: circuitType | null;
  currentCircuit: number;
  setShowExercise: (state: number) => void;
}

interface excerciseMovie {
  movementId?: string;
}

const Circuit = ({
  currentWeek,
  currentDay,
  dayData,
  circuitData,
  currentCircuit,
  setShowExercise,
}: // insUpExercise,
Props) => {
  const [showMovementsModal, setShowMovementsModal] = useState<boolean>(false);
  // const [movieUrl, setMovieUrl] = useState<string[]>([]);
  const [movieData, setMovieData] = useState<excerciseMovie[]>([]);
  const [currentCircuitExercise, setCurrentCircuitExercise] =
    useState<number>(0);
  const [isSubmittingExercise, setIsSubmittingExercise] =
    useState<boolean>(false);

  const {
    register,
    control,
    formState,
    getValues,
    reset,
    setValue,
    clearErrors,
    watch,
    trigger,
    resetField,
    getFieldState,
    setError,
    setFocus,
  } = useFormContext();
  const { errors, dirtyFields, isValid } = formState;

  const objectChain = `weekDays.${
    currentWeek * 7 + currentDay
  }.circuits.${currentCircuit}`;
  const ExercisesOfCurrentCircuitData = watch(
    `${objectChain}.circuitExercises`
  );

  const currentCircuitData = watch(`${objectChain}`);

  const {
    fields: circuitExercises,
    append: appendCircuitExercise,
    remove: removeCircuitExercise,
    update: updateCircuitExercise,
  } = useFieldArray({
    control,
    name: `${objectChain}.circuitExercises`,
  });

  const currentCircuitExerciseRef = useRef<number>(0);
  const {
    fields: exerciseSets,
    append: appendSet,
    remove: removeSet,
    update: updateSet,
  } = useFieldArray({
    control,
    name: `${objectChain}.circuitExercises.${currentCircuitExercise}.exerciseSets`,
  });

  useEffect(() => {
    try {
      const circuitExercisesLocal = getValues(
        `${objectChain}.circuitExercises`
      );
      if (!circuitExercisesLocal) setShowExercise(0);
      // if (circuitExercisesLocal) {
      //   let MovieUrlLocal: excerciseMovie[] = [];
      //   circuitExercisesLocal.map(async (ex: exerciseType) => {
      //     if (ex.movementId) {
      //       const {
      //         data: { result: movement },
      //       } = await getMovement(ex.movementId!);

      //       if (movement) {
      //         MovieUrlLocal.push({
      //           url: `${movement.movie}`?.toString()!,
      //           posterUrl: `${movement.poster || Player.imageUrl}`?.toString()!,
      //         });
      //         setMovieData(MovieUrlLocal);
      //       }
      //     }
      //   });
      // } else setShowExercise(0);
    } catch (error) {
      toast.error("خطا در فراخوانی اطلاعات");
    }
  }, []);

  // const handleMouseEnter = (e: any) => {
  //   const vid = e.target;
  //   vid.muted = true;
  //   vid.play();
  // };
  // const handleMouseLeave = (e: any) => {
  //   const vid = e.target;
  //   vid.muted = false;
  //   vid.currentTime = 0;
  //   vid.pause();
  // };

  const returnCircuitError = () => {
    const localError = getFieldState(`${objectChain}`).error;
    return localError;
  };

  const returnExerciseOfCircuitError = (index: number) => {
    const localError = getFieldState(
      `${objectChain}.circuitExercises.${index}`
    ).error;
    return localError;
  };
  const selectExercise = async (movementId: string, movementType: number) => {
    try {
      const {
        data: { result: tempMovementData },
      } = await getMovement(movementId);

      // const movement: movementType = privateMovements.find(
      //   (item) => item.id === movementId && item.type === movementType
      // )!;

      const movement: movementType = {
        id: tempMovementData.id,
        type: tempMovementData.type,
        name: tempMovementData.name,
        poster: tempMovementData.poster,
        description: tempMovementData.description,
        movie: tempMovementData.movie,
        // tags: movement.tags || [],
      };
      setValue(
        `${objectChain}.circuitExercises.${currentCircuitExercise}.movementId`,
        movement.id
      );
      setValue(
        `${objectChain}.circuitExercises.${currentCircuitExercise}.movementType`,
        movement.type
      );
      setValue(
        `${objectChain}.circuitExercises.${currentCircuitExercise}.exerciseName`,
        movement.name
      );
      clearErrors([
        `${objectChain}.circuitExercises.${currentCircuitExercise}.exerciseName`,
      ]);
      let movieUrlLocal = [...movieData];
      if (currentCircuitExercise < movieData.length)
        movieUrlLocal[currentCircuitExercise] = {
          movementId: movement.id,
        };
      else
        movieUrlLocal.push({
          movementId: movement.id,
        });

      setMovieData(movieUrlLocal);
    } catch (error) {
      toast.error("خطا در فراخوانی اطلاعات حرکت ها");
    }
  };

  const registerOptions = {
    circuitName: {
      required: "نام سیرکت را وارد نمایید",
    },
    exerciseDescription: {
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "توضیحات معتبر نیست",
    },

    circuitRestTime: {
      valueAsNumber: true,
      required: "زمان استراحت را وارد نمایید",
      // pattern: {
      //   value: /d+/,
      //   message: "مقدار باید عدد باشد",
      // },
      maxLength: {
        value: 3,
        message: "تعداد ارقام بیش از حد مجاز است",
      },
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "زمان استراحت معتبر نیست",
    },
    exerciseSets: {
      valueAsNumber: true,
      required: "مقدار ست را وارد نمایید",
      // pattern: {
      //   value: /d+/,
      //   message: "مقدار باید عدد باشد",
      // },
      maxLength: {
        value: 20,
        message: "تعداد کاراکتر ها بیش از حد مجاز است",
      },
    },
  };
  return (
    <div className="w-full h-full flex flex-col gap-y-1 justify-start items-start overflow-y-auto text-xs">
      <div className="w-full flex flex-col items-start justify-start border rounded-md gap-y-2 p-2">
        <span>
          هفته {Numbring[currentWeek]} / روز {Numbring[currentDay]} / تمرین{" "}
          {circuitData
            ? Numbring[currentCircuit]
            : Numbring[
                (dayData?.circuits?.length || 0) > 0
                  ? dayData!.circuits!.length - 1
                  : 0
              ]}
        </span>

        <div className="flex w-full justify-end items-center ">
          <div className="flex items-center gap-x-2">
            <button
              // onClick={(e) => insUpExercise(e, index, day)}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();

                resetField(
                  `weekDays.${
                    currentWeek * 7 + currentDay
                  }.circuits.${currentCircuit}`,
                  { keepError: false, keepDirty: false, keepTouched: false }
                );
                reset(watch(), {
                  keepValues: false,
                  keepDirty: false,
                  keepDefaultValues: false,
                });
                setShowExercise(0);
              }}
              className="btn-1  border-[#7367F0] hover:bg-slate-100 text-color1 hover:text-color1 "
            >
              <ExitSVG className="w-3" fill="#7367F0" />
              بازگشت
            </button>
            <button
              //type="submit"
              onClick={async (e: React.MouseEvent) => {
                e.preventDefault();
                ExercisesOfCurrentCircuitData.map(
                  (item: exerciseType, index: number) => {
                    if (!item.exerciseName) {
                      setError(
                        `${objectChain}.circuitExercises.${index}.exerciseName`,
                        {
                          type: "manual",
                          message: "انتخاب حرکت اجباری است",
                        }
                      );
                      return false;
                    }
                  }
                );

                if (!isValid) {
                  trigger();
                  return false;
                } else {
                  setIsSubmittingExercise(true);
                  clearErrors(`${objectChain}.exerciseSets`);
                  reset(watch(), {
                    keepValues: false,
                    keepDirty: false,
                    keepDefaultValues: false,
                  });

                  return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      setIsSubmittingExercise(false);
                      setShowExercise(0);
                      resolve();
                    }, 2000);
                  });
                }
                //   reset({}, { keepValues: true });
              }}
              className="btn-1 bg-color1 text-white  min-w-24  "
            >
              {isSubmittingExercise ? (
                <span
                  className={`
         loading loading-spinner loading-xs`}
                ></span>
              ) : (
                <GiSaveArrow />
              )}
              ذخیره سیرکت
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-1 overflow-y-auto">
        <div className="w-full flex flex-col gap-y-1">
          <label
            data-tip={
              (returnCircuitError() as any)?.circuitName &&
              (returnCircuitError() as any)?.circuitName.message
            }
            className={`w-full  ${
              (returnCircuitError() as any)?.circuitName &&
              "tooltip tooltip-bottom"
            }`}
          >
            <div className="label justify-start">
              <span className="">نام سیرکت </span>
            </div>
            <input
              tabIndex={0}
              type="text"
              {...register(
                // `day.${index}.dayName` as const,
                `${objectChain}.circuitName`,
                registerOptions.circuitName
              )}
              maxLength={30}
              placeholder="نام سیرکت را وارد نمایید"
              className={`input input-xs 2xl:input-sm text-xs  input-bordered w-full
                                ${
                                  (returnCircuitError() as any)?.circuitName &&
                                  "border-1 border-red-600"
                                }
                              `}
            />
            {/* {errors.day && (errors.day as any)[0].dayName.message} */}
          </label>

          <div className="w-full  flex h-2/3">
            <label className={`form-control w-full h-full `}>
              <div className="label justify-start">
                <span className="">توضیحات</span>
              </div>
              <textarea
                tabIndex={1}
                {...register(`${objectChain}.circuitDescription`)}
                rows={3}
                cols={10}
                maxLength={300}
                placeholder="توضیحات خود را وارد نمایید"
                className={`input input-xs text-xs input-bordered w-full h-10 2xl:h-12 `}
              />
            </label>
          </div>
        </div>
        <div className="w-full  flex items-end gap-x-3  ">
          <label
            data-tip={
              (returnCircuitError() as any)?.circuitRestTime &&
              (returnCircuitError() as any)?.circuitRestTime.message
            }
            className={`form-control w-28  ${
              (returnCircuitError() as any)?.circuitRestTime &&
              "tooltip tooltip-left"
            }`}
          >
            <div className="label justify-start ">
              <span>استراحت بین ست</span>
            </div>
            <input
              type="text"
              maxLength={2}
              onKeyDown={(e: any) => {
                return onlyDigits(e);
              }}
              tabIndex={2}
              {...register(
                `${objectChain}.circuitRestTime`,
                registerOptions.circuitRestTime
              )}
              placeholder=""
              className={`input input-xs text-xs input-bordered w-full text-center  ltr  ${
                (returnCircuitError() as any)?.circuitRestTime &&
                "border border-red-600"
              }`}
            />
          </label>
          <select
            {...register(`${objectChain}.circuitRestType`, {
              valueAsNumber: true,
            })}
            defaultValue="0"
            className="rounded-md bg-transparent "
          >
            <option value={0}>ثانیه</option>
            <option value={1}>دقیقه</option>
          </select>
        </div>
        <div className=" flex flex-col h-[64%] 2xl:h-[62%] w-full  ">
          {circuitExercises &&
            circuitExercises.length > 0 &&
            circuitExercises.map((circuitExercise: exerciseType, index) => (
              <div
                key={index}
                className="flex flex-col w-full gap-y-1 border-t-2"
              >
                <div className="text-color1 pt-1">تمرین {Numbring[index]}</div>
                <div className="w-full flex justify-start items-start ">
                  <div
                    className={`w-[10rem] h-[12rem] flex flex-col items-center justify-center border relative rounded-md ${
                      (returnExerciseOfCircuitError(index) as any)
                        ?.exerciseName && "border border-red-600"
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentCircuitExercise(index);
                      setShowMovementsModal(true);
                    }}
                  >
                    {/* {movieData.length > index && movieData[index].url && ( */}
                    {(circuitExercise.movementId ||
                      (movieData.length > index &&movieData[index].movementId)) && (
                      <>
                        <h2 className="absolute top-3 right-3 text-sm z-10">
                          {watch(
                            `${objectChain}.circuitExercises.${index}.exerciseName`
                          )}
                        </h2>
                        <VideoHover
                          src={`${moviesUrl}/media/${circuitExercise.movementId?circuitExercise.movementId:movieData[index].movementId}/media.mp4`}
                          // poster={Player.imageUrl}
                          poster={`${moviesUrl}/media/${circuitExercise.movementId?circuitExercise.movementId:movieData[index].movementId}/thumbnail.png`}
                          // src={movieData[index].url!}
                          // poster={movieData[index].posterUrl || Player.imageUrl}
                          classes="w-full h-full"
                        />
                      </>
                    )}

                    {!circuitExercise.movementId&&!movieData[index]?.movementId && (
                      <>
                        {" "}
                        <i className="cursor-pointer flex flex-col justify-center items-center text-4xl font-light">
                          +
                        </i>
                        <div className="text-2xs text-center">
                          حرکت مورد نظر را از لیست حرکات اضافه نمایید
                        </div>
                      </>
                    )}
                    {circuitExercise.movementId && (
                      <div
                        data-tip="انتخاب حرکت"
                        className="bottom-0 right-0 absolute  pr-1 bg-white opacity-60 w-full h-6 flex items-center tooltip tooltip-left"
                      >
                        <SwapSVG className="w-4 cursor-pointer" />
                      </div>
                    )}
                  </div>
                  {index > 1 && (
                    <div
                      data-tip={`حذف تمرین ${Numbring[index]}`}
                      className="mr-auto ml-3 tooltip tooltip-right"
                      onClick={() => removeCircuitExercise(index)}
                    >
                      <DeleteSVG className="w-5 h-8 cursor-pointer" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col bg-white w-full items-start  justify-center rounded-md ">
                  <div className="w-full flex justify-start items-start">
                    <div className="form-control  ">
                      <label className="cursor-pointer label !gap-x-3 ">
                        <span>تعداد</span>
                        <input
                          type="checkbox"
                          className="toggle toggle-primary "
                          {...register(
                            `${objectChain}.circuitExercises.${index}.exerciseSetType`
                          )}
                        />
                        <span>زمان</span>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col w-full justify-start items-start p-2 gap-y-1">
                    {/* {getValues(
                      `weekDays.${
                        currentWeek * 7 + currentDay
                      }.circuits.${currentCircuit}`
                    ) */}
                    {ExercisesOfCurrentCircuitData &&
                      ExercisesOfCurrentCircuitData[index] &&
                      ExercisesOfCurrentCircuitData[index].exerciseSets &&
                      ExercisesOfCurrentCircuitData[index].exerciseSets.map(
                        (exercise: setType, idx: any) => (
                          <fieldset
                            key={exercise.id}
                            className="flex w-full justify-start gap-x-2 "
                          >
                            <div className="min-w-14">ست {Numbring[idx]}</div>
                            <div
                              data-tip={
                                (returnExerciseOfCircuitError(index) as any)
                                  ?.exerciseSets &&
                                (returnExerciseOfCircuitError(index) as any)
                                  ?.exerciseSets[idx] &&
                                (returnExerciseOfCircuitError(index) as any)
                                  ?.exerciseSets[idx].value.message
                              }
                              className={`${
                                (returnExerciseOfCircuitError(index) as any)
                                  ?.exerciseSets &&
                                (returnExerciseOfCircuitError(index) as any)
                                  ?.exerciseSets[idx] &&
                                "tooltip tooltip-left"
                              }`}
                            >
                              <input
                                type="text"
                                tabIndex={2}
                                maxLength={20}
                                {...register(
                                  `${objectChain}.circuitExercises.${index}.exerciseSets.${idx}.value`,
                                  registerOptions.exerciseSets
                                )}
                                placeholder=""
                                className={`input max-md:input-sm input-bordered  text-center h-7  ltr  ${
                                  (returnExerciseOfCircuitError(index) as any)
                                    ?.exerciseSets &&
                                  (returnExerciseOfCircuitError(index) as any)
                                    ?.exerciseSets[idx] &&
                                  "border border-red-600"
                                }`}
                              />
                            </div>
                            <div>
                              {!ExercisesOfCurrentCircuitData[index]
                                .exerciseSetType && (
                                <select
                                  {...register(
                                    `${objectChain}.circuitExercises.${index}.exerciseSets.${idx}.type`,
                                    {
                                      valueAsNumber: true,
                                    }
                                  )}
                                  defaultValue="0"
                                  className="rounded-md bg-transparent h-7"
                                >
                                  <option value={0}>ثانیه</option>
                                  <option value={1}>دقیقه</option>
                                </select>
                              )}
                            </div>
                            <div
                              onClick={() => {
                                if (idx > 0) {
                                  let localcircuitExercises: exerciseType = {
                                    ...ExercisesOfCurrentCircuitData[index],
                                  };

                                  if (
                                    idx <
                                    localcircuitExercises.exerciseSets!.length -
                                      1
                                  ) {
                                    toast.error("امکان حذف آخرین ست وجود دارد");
                                  } else {
                                    localcircuitExercises.exerciseSets!.splice(
                                      idx,
                                      1
                                    );

                                    updateCircuitExercise(
                                      index,
                                      localcircuitExercises
                                    );
                                  }
                                }
                              }}
                            >
                              <DeleteSVG className="w-4 h-7 cursor-pointer" />
                            </div>
                          </fieldset>
                        )
                      )}
                  </div>

                  <div className="flex justify-center items-center gap-x-3 mr-3 my-3">
                    <span
                      className="text-lg bg-sky-100 w-6  text-center rounded-md cursor-pointer"
                      onClick={() => {
                        // const values = getValues();
                        if (
                          ExercisesOfCurrentCircuitData[index]?.exerciseSets
                            ?.length === 0 ||
                          (
                            ExercisesOfCurrentCircuitData[index]
                              ?.exerciseSets as any
                          )[
                            ExercisesOfCurrentCircuitData[index]?.exerciseSets
                              ?.length! - 1
                          ].value !== ""
                        ) {
                          circuitExercises.map(
                            (exerciseItem, ExerciseIndex) => {
                              const newSet: setType = {
                                id:
                                  ExercisesOfCurrentCircuitData[ExerciseIndex]
                                    ?.exerciseSets?.length + 1,
                                type: 0,
                              };
                              let localcircuitExercises: exerciseType = {
                                ...ExercisesOfCurrentCircuitData[ExerciseIndex],
                              };
                              localcircuitExercises.exerciseSets?.push(newSet);
                              updateCircuitExercise(
                                ExerciseIndex,
                                localcircuitExercises
                              );
                              // }
                            }
                          );
                        }
                      }}
                    >
                      +
                    </span>
                    <span className="text-xs">اضافه کردن ست</span>
                  </div>
                </div>
              </div>
            ))}
          <div className="flex justify-start items-center gap-x-3 mr-3 my-3">
            <span
              className="text-lg bg-color1 w-6  text-center rounded-md cursor-pointer text-white"
              onClick={() => {
                if (
                  ExercisesOfCurrentCircuitData[
                    ExercisesOfCurrentCircuitData.length - 1
                  ].movementId === null ||
                  (errors.weekDays &&
                    (errors.weekDays as any)[currentWeek * 7 + currentDay]
                      .circuits)
                ) {
                  // trigger();
                  return false;
                }
                const newExerciseForThisCircuit = {
                  id:
                    ExercisesOfCurrentCircuitData &&
                    ExercisesOfCurrentCircuitData.length > 0
                      ? `${uuidv4().toString().substring(0, 34)}${(
                          Math.max.apply(
                            null,
                            ExercisesOfCurrentCircuitData.filter(
                              (item: exerciseType) => item
                            ).map((o: exerciseType) => o.id)
                          ) + 1
                        )
                          .toString()
                          .padStart(2, "0")}`
                      : `${uuidv4().toString().substring(0, 34)}01`,
                  movementId: null,
                  movementType: null,
                  exerciseSets: [
                    {
                      id: 1,
                      type: 0,
                    },
                    {
                      id: 2,
                      type: 0,
                    },
                    {
                      id: 3,
                      type: 0,
                    },
                  ],
                };

                appendCircuitExercise(newExerciseForThisCircuit, {
                  shouldFocus: false,
                });
              }}
            >
              +
            </span>
            <span>اضافه کردن تمرین</span>
          </div>
        </div>
      </div>
      <MovementsModal
        showModal={showMovementsModal}
        exitBtnClick={() => setShowMovementsModal(false)}
        selectExerciseBtnClick={(movementId: string, movementType: number) => {
          selectExercise(movementId, movementType);
          setShowMovementsModal(false);
        }}
      >
        <GiSaveArrow className="w-3" />
      </MovementsModal>
    </div>
  );
};

export default Circuit;