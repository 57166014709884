import http from "services/authService";

const appUrl = process.env.REACT_APP_URL;

export const addPackage = (data: any) => {
  return http.post(`${appUrl}/Package/addpackage`, {
    package: { ...data },
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const deletePackage = (packageId: string) => {
  return http.delete(`${appUrl}/Package/deletepackage`, {
    data: {
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      packageId: packageId,
    },
  });
};

export const updatePackage = (data: any) => {
  return http.put(`${appUrl}/Package/updatepackage`, {
    package: { ...data },
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const getPackages = () => {
  return http.get(
    `${appUrl}/Package/getpackages?coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6`
  );
};

export const getPackage = (packageId: string) => {
  return http.get(
    `${appUrl}/Package/getpackage?coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6&PackageId=${packageId}`
  );
};
