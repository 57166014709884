import http from "services/authService";

const appUrl = process.env.REACT_APP_URL;

export const loginMethod = (data: { userId: string; password: string }) => {
  return http.post(`${appUrl}/User/signin`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getUserInfo = async () => {
  return http.get(`${appUrl}/User/current`, {
    headers: { "Content-Type": "application/json" },
  });
};
