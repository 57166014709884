import { SVGProps } from "react";

function DraggableSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#000000"  {...props} version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg"  
	 width="800px" height="800px" viewBox="0 0 32 32" >
{/* <style type="text/css">
	.st0{fill:none;}
</style> */}
<title>draggable</title>
<rect x="10" y="6" width="4" height="4"/>
<rect x="18" y="6" width="4" height="4"/>
<rect x="10" y="14" width="4" height="4"/>
<rect x="18" y="14" width="4" height="4"/>
<rect x="10" y="22" width="4" height="4"/>
<rect x="18" y="22" width="4" height="4"/>
<rect id="_Transparent_Rectangle_" className="fill-none" width="32" height="32"/>
</svg>
    
    
   
  );
}
export default DraggableSVG;
