import { forType, planType, professionalInfoType } from "types";
import professionalInfo from "components/professionalInfo.json";
import React, { useContext, useEffect, useState } from "react";
import IndividualsSVG from "components/svg/individualsSvg";
import ConfirmModal from "components/modals/confirmModal";
import { SubmitHandler, useForm } from "react-hook-form";
import PlanCoverSVG from "components/svg/planCoverSvg";
import PublishedSVG from "components/svg/publishedSvg";
import PlanFilter from "components/modals/planFilter";
import {
  deletePlan,
  duplicatePlan,
  getAllPlans,
  getPlan,
  savePlan,
} from "services/planService";
import FilterSVG from "components/svg/filterSvg";
import SearchSVG from "components/svg/searchSvg";
import DeleteSVG from "components/svg/deleteSvg";
import AssignSVG from "components/svg/assignSvg";
import ReviewSVG from "components/svg/reviewSvg";
import privatePlans from "components/plans.json";
import { useNavigate } from "react-router-dom";
import CloseSVG from "components/svg/closeSvg";
import { MyContext } from "components/Context";
import LoadingPlans from "pages/loadingPlans";
import EditSVG from "components/svg/editSvg";
import CopySVG from "components/svg/copySvg";
import { toast } from "sonner";
const moviesUrl = process.env.REACT_APP_MOVIES_URL;

interface Search {
  searchTxt: string;
}

interface PlansProps {
  isModal?: boolean;
  selectPlan: (planId: string) => void;
}

const Plans = ({ isModal, selectPlan }: PlansProps) => {
  const [data, setData] = useState<planType[]>([]);
  const [firteredData, setFirteredData] = useState<planType[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [localProfessionalInfo, setLocalProfessionalInfo] =
    useState<professionalInfoType>(professionalInfo);
  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>("0");
  const { register, handleSubmit } = useForm<Search>({
    mode: "onSubmit",
    defaultValues: { searchTxt: "" },
  });
  const navigate = useNavigate();
  const { isDarkMode } = useContext(MyContext);

  const getPlansData = async () => {
    try {
      const { data: PlansData } = await getAllPlans();
      if (PlansData.result && PlansData.result.length > 0) {
        setData(PlansData.result);
        setFirteredData(PlansData.result);
        setLocalProfessionalInfo({
          gender: {
            data: localProfessionalInfo.gender.data.map((plan) => ({
              ...plan,
              checked: false,
            })),
            checked: false,
          },
          weight: {
            data: localProfessionalInfo.weight.data.map((plan) => ({
              ...plan,
              checked: false,
            })),
            checked: false,
          },
          place: {
            data: localProfessionalInfo.place.data.map((plan) => ({
              ...plan,
              checked: false,
            })),
            checked: false,
          },
          level: {
            data: localProfessionalInfo.level.data.map((plan) => ({
              ...plan,
              checked: false,
            })),
            checked: false,
          },
        });
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      throw error;
    }
  };
  useEffect(() => {
    async function fetchAPI() {
      try {
        await getPlansData();
      } catch (error) {
        toast.error("خطا در فراخوانی اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  const onSubmit: SubmitHandler<Search> = async (data) => {
    const localPA = privatePlans.filter((item) =>
      item.planName.includes(data.searchTxt)
    );
    // setData(localPA);
    setFirteredData(localPA);
  };

  const setFilterChecked = (name: string) => {
    let tmpData: any = { ...localProfessionalInfo };
    tmpData[name].checked = !tmpData[name].checked;
    setLocalProfessionalInfo(tmpData);
  };

  const setRadioFilterChecked = (name: string, id: number) => {
    let tmpData: any = { ...localProfessionalInfo };
    tmpData[name].data = tmpData[name].data.map((item: forType) =>
      item.id === id ? { ...item, checked: true } : { ...item, checked: false }
    );
    setLocalProfessionalInfo(tmpData);
  };
  const filterData = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let tempData: planType[] = [];
    const checkedTags =
      localProfessionalInfo.gender.checked ||
      localProfessionalInfo.weight.checked ||
      localProfessionalInfo.place.checked ||
      localProfessionalInfo.level.checked;
    if (checkedTags) {
      privatePlans.map((item) => {
        if (localProfessionalInfo.gender.checked) {
          const selectedItem: forType[] =
            localProfessionalInfo.gender.data.filter(
              (dItem: forType) => dItem.checked === true
            );
          if (selectedItem.length > 0 && selectedItem[0].id === item.gender)
            tempData.push(item);
        }
        if (localProfessionalInfo.weight.checked) {
          const selectedItem: forType[] =
            localProfessionalInfo.weight.data.filter(
              (dItem: forType) => dItem.checked === true
            );
          if (selectedItem.length > 0 && selectedItem[0].id === item.weight)
            tempData.push(item);
        }
        if (localProfessionalInfo.place.checked) {
          const selectedItem: forType[] =
            localProfessionalInfo.place.data.filter(
              (dItem: forType) => dItem.checked === true
            );
          if (selectedItem.length > 0 && selectedItem[0].id === item.place)
            tempData.push(item);
        }
        if (localProfessionalInfo.level.checked) {
          const selectedItem: forType[] =
            localProfessionalInfo.level.data.filter(
              (dItem: forType) => dItem.checked === true
            );
          if (selectedItem.length > 0 && selectedItem[0].id === item.level)
            tempData.push(item);
        }
        return true;
      });
      // setData(tempData);
      setFirteredData(tempData);
    } else setFirteredData(privatePlans);
    // setData(privatePlans);
    setShowFilter(!showFilter);
  };

  const filterClear = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let tmpData: any = { ...localProfessionalInfo };
    tmpData.gender.checked = false;
    tmpData.gender.data = tmpData.gender.data.map((item: forType) => ({
      ...item,
      checked: false,
    }));

    tmpData.weight.checked = false;
    tmpData.weight.data = tmpData.weight.data.map((item: forType) => ({
      ...item,
      checked: false,
    }));
    tmpData.place.checked = false;
    tmpData.place.data = tmpData.place.data.map((item: forType) => ({
      ...item,
      checked: false,
    }));
    tmpData.level.checked = false;
    tmpData.level.data = tmpData.level.data.map((item: forType) => ({
      ...item,
      checked: false,
    }));
    setLocalProfessionalInfo(tmpData);
    setShowFilter(!showFilter);
    // setData(privatePlans);
    setFirteredData(privatePlans);
  };

  const acceptBtnClick = async () => {
    if (selectedPlanId) {
      await deletePlan(selectedPlanId!);
      const localPA = data.filter((item) => item.id !== selectedPlanId);
      setData(localPA);
      setFirteredData(localPA);
      setShowModal(false);
      toast.success("عملیات با موفقیت انجام پذیرفت");
    }
  };

  const acceptUpdateBtnClick = () => {
    setShowModal(false);
    navigate(`/plan/${selectedPlanId}`);
  };

  const rejectBtnClick = () => {
    setShowModal(false);
  };

  const rejectUpdateBtnClick = () => {
    setShowUpdateModal(false);
  };

  const copyPlan = async (planId: string) => {
    try {
      const duplicatedPlan = await duplicatePlan(planId);
      if (duplicatedPlan.status === 200) {
        const { result } = duplicatedPlan.data;
        const localData = [
          ...data,
          {
            id: result.id,
            state: result.state,
            planName: result.planName,
            gender: result.gender,
            planTime: result.planTime,
            place: result.place,
            planDescription: result.planDescription,
            level: result.level,
            weight: result.weight,
            logo: result.logo || null,
          },
        ];
        setData(localData);
        setFirteredData(localData);

        toast.success("عملیات با موفقیت انجام پذیرفت");
        // getPlansData();
      } else toast.error("خطا در عملیات");
    } catch (error) {
      toast.error("خطا در عملیات");
    }
  };
  return (
    <>
      {/* {!isLoadingData && data.length === 0 && (
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex flex-col items-center gap-y-3">
            <div>لیست شما خالی است.</div>
           
          </div>
        </div>
      )} */}
      {
        <div
          className={`w-full h-full flex flex-col justify-start items-center ${
            isLoadingData && "overflow-hidden"
          } `}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col-reverse md:flex-row  justify-between h-18 md:h-10 w-full mt-5 md:mt-1 px-2 gap-y-2 "
          >
            {!isModal ? (
              <div
                className="btn-1 h-9 text-white  bg-color1 mt-2"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  navigate("/plan");
                }}
              >
                <>
                  <div className="mx-2 text-xl dark:text-white">+ </div>
                  اضافه کردن برنامه
                </>
              </div>
            ) : (
              <div></div>
            )}

            <div
              className={`h-8 w-full md:w-72 flex relative justify-start items-center gap-x-2 mt-2 ml-2`}
            >
              <div className="w-4 relative cursor-pointer">
                {!showFilter && (
                  <FilterSVG
                    fill={
                      localProfessionalInfo.gender.checked ||
                      localProfessionalInfo.level.checked ||
                      localProfessionalInfo.place.checked ||
                      localProfessionalInfo.weight.checked
                        ? "blue"
                        : "black"
                    }
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className="w-full"
                  />
                )}
                {showFilter && (
                  <CloseSVG
                    fill={`black`}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                )}

                {showFilter && (
                  <div className="absolute mt-4">
                    <PlanFilter
                      data={localProfessionalInfo}
                      setChecked={setFilterChecked}
                      setRadioChecked={setRadioFilterChecked}
                      title="برنامه انتخاب شده حذف شود؟"
                      desc="در صورت پاک کردن این برنامه تمام محتوای آن حذف خواهد شد."
                      showModal={showFilter}
                      filterDone={filterData}
                      filterClear={filterClear}
                    />
                  </div>
                )}
              </div>

              <div className=" h-full w-full flex relative justify-center items-center">
                <input
                  autoFocus
                  {...register("searchTxt")}
                  type="text"
                  className="w-full border h-full shadow py-2 px-6 rounded-lg"
                  placeholder="جستجو"
                />
                <button tabIndex={0} type="submit" className="absolute left-5">
                  <SearchSVG className="text-black h-5 w-5  top-3.5 right-3 fill-current" />
                </button>
              </div>
            </div>
          </form>
          {/* <div className="flex h-full sm:self-start sm:ml-auto items-start justify-center sm:justify-start gap-y-4 sm:gap-y-1 gap-x-4 mt-2 mr-2 flex-wrap overflow-y-auto	"> */}
          {!isLoadingData && data.length === 0 ? (
            <div className="h-full w-full flex justify-center items-center mx-auto">
              <div className="flex flex-col items-center gap-y-3">
                <div>لیست شما خالی است.</div>
              </div>
            </div>
          ) : (
            <div
              className={`flex md:self-start sm:ml-auto content-start  items-start justify-center md:justify-start gap-y-3 gap-x-4 mt-4 mr-2 flex-wrap h-full  ${
                isLoadingData ? "overflow-hidden " : "overflow-y-auto "
              }`}
            >
              {isLoadingData ? (
                <LoadingPlans />
              ) : (
                firteredData &&
                firteredData.map((plan) => (
                  <div key={plan.id} className="flex flex-col w-44 md:w-36  ">
                    <div className="card card-compact bg-transparent justify-between  w-full h-64 md:h-56 bg-base-100 shadow-xl ">
                      <figure
                        className={`h-64 relative ${
                          isModal && "cursor-pointer"
                        }`}
                        onClick={() => isModal && selectPlan(plan.id!)}
                      >
                        <h2 className="absolute top-3 right-3 text-xs text-black">
                          {plan.planName}
                        </h2>

                        {plan.logo ? (
                          <div className="w-full h-full">
                            <img
                              loading="lazy"
                              className="object-fill h-full w-full "
                              // src={`./images/${plan.logo}`}
                              src={`${moviesUrl}/plan/${plan.id}/poster.png`}
                              alt="logo"
                            />
                          </div>
                        ) : (
                          <PlanCoverSVG className="object-fill h-full w-full rounded-xl" />
                        )}
                      </figure>

                      <div className="dropdown dropdown-hover flex justify-center my-auto z-10">
                        <div
                          tabIndex={0}
                          role="button"
                          className="btn-1 m-1 bg-transparent border-0"
                        >
                          ...
                        </div>
                        <ul
                          tabIndex={0}
                          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-40 md:w-36 top-7 max-md:gap-y-3"
                        >
                          <div
                            onClick={() => {
                              setSelectedPlanId(plan.id);
                              if (!isModal) setShowUpdateModal(true);
                              else acceptUpdateBtnClick();
                            }}
                            className="flex justify-between cursor-pointer hover:bg-black-200 rounded-md mx-1 px-2 mt-2 sm:mt-4 border-1 "
                          >
                            <div>ویرایش</div>
                            <EditSVG className=" w-4 dark:text-white" />
                          </div>
                          {!isModal && (
                            <>
                              <div className="flex justify-between cursor-pointer hover:bg-black-200 rounded-md mx-1 px-2 mt-2 sm:mt-4">
                                <div>مشاهده افراد</div>
                                <IndividualsSVG
                                  className=" w-4 "
                                  fill={`${isDarkMode ? "white" : "black"}`}
                                />
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedPlanId(plan.id);
                                  setShowModal(true);
                                }}
                                className="flex justify-between cursor-pointer hover:bg-black-200 rounded-md mx-1 px-2 mt-2 sm:mt-4"
                              >
                                <div>حذف</div>
                                <DeleteSVG
                                  className=" w-4 "
                                  fill={`${isDarkMode && "white"}`}
                                />
                              </div>
                              <div
                                onClick={() => {
                                  copyPlan(plan.id!);
                                }}
                                className="flex justify-between cursor-pointer hover:bg-black-200 rounded-md mx-1 px-2 mt-2 sm:mt-4"
                              >
                                <div>کپی</div>
                                <CopySVG
                                  className=" w-4 "
                                  fill={`${isDarkMode && "white"}`}
                                />
                              </div>
                              <div className="flex justify-between cursor-pointer hover:bg-black-200 rounded-md mx-1 px-2 mt-2 sm:mt-4">
                                <div>اختصاص</div>
                                <AssignSVG
                                  className=" w-4 "
                                  fill={`${isDarkMode && "white"}`}
                                />
                              </div>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    {!isModal && (
                      <div
                        className={`mt-2 flex justify-center items-center gap-x-2 text-2xs  ${
                          plan.state === 0 ? "text-red-400" : "text-lime-600"
                        }`}
                      >
                        {plan.state === 0 ? (
                          <>
                            {" "}
                            <div>در حال بازبینی</div>
                            <ReviewSVG className="w-5 text-red-400" />
                          </>
                        ) : (
                          <>
                            {" "}
                            <div>منتشر شده</div>
                            <PublishedSVG className="w-5 text-lime-600" />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))
              )}
              <ConfirmModal
                acceptMessage="حذف شود"
                rejectMessage="منصرف شدم"
                title="برنامه انتخاب شده حذف شود؟"
                desc="در صورت پاک کردن این برنامه تمام محتوای آن  حذف خواهد شد."
                showModal={showModal}
                acceptBtnClick={acceptBtnClick}
                rejectBtnClick={rejectBtnClick}
              >
                <DeleteSVG className="w-3 " fill={"white"} />
              </ConfirmModal>
              <ConfirmModal
                acceptMessage="ویرایش برای همه"
                rejectMessage="منصرف شدم"
                title="هرگونه تغییر در این برنامه، برای تمام ورزشکاران دریافت کننده این برنامه نیز لحاظ خواهد شد."
                desc="در صورتی که نمی خواهید تغییرات برای همه لحاظ شود. ابتدا برنامه را کپی نموده، سپس تغییرات را روی نسخه کپی انجام دهید."
                showModal={showUpdateModal}
                acceptBtnClick={acceptUpdateBtnClick}
                rejectBtnClick={rejectUpdateBtnClick}
              >
                <EditSVG className="w-3 text-white" />
              </ConfirmModal>
            </div>
          )}
        </div>
      }
    </>
  );
};
export default Plans;
