import { SVGProps } from "react";

function PackgeSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="3.5"
        y="7.66675"
        width="15"
        height="3.33333"
        rx="1"
        stroke="black"
      />
      <rect
        x="3.5"
        y="7.66675"
        width="15"
        height="3.33333"
        rx="1"
        stroke="white"
        // stroke-opacity="0.1"
      />
      <path d="M11.0002 7.66675V18.5001" stroke="black" />
      <path
        d="M11.0002 7.66675V18.5001"
        stroke="white"
        // stroke-opacity="0.1"
      />
      <path
        d="M16.8332 11V16.8333C16.8332 17.7538 16.087 18.5 15.1665 18.5H6.83317C5.9127 18.5 5.1665 17.7538 5.1665 16.8333V11"
        stroke="black"
      />
      <path
        d="M16.8332 11V16.8333C16.8332 17.7538 16.087 18.5 15.1665 18.5H6.83317C5.9127 18.5 5.1665 17.7538 5.1665 16.8333V11"
        stroke="black"
        fill="white"
        // stroke-opacity="0.1"
      />
      <path
        d="M7.24984 7.66655C6.09924 7.66655 5.1665 6.73381 5.1665 5.58321C5.1665 4.43262 6.09924 3.49988 7.24984 3.49988C8.89503 3.47121 10.3829 5.12445 10.9998 7.66655C11.6167 5.12445 13.1046 3.47121 14.7498 3.49988C15.9004 3.49988 16.8332 4.43262 16.8332 5.58321C16.8332 6.73381 15.9004 7.66655 14.7498 7.66655"
        stroke="black"
      />
      <path
        d="M7.24984 7.66655C6.09924 7.66655 5.1665 6.73381 5.1665 5.58321C5.1665 4.43262 6.09924 3.49988 7.24984 3.49988C8.89503 3.47121 10.3829 5.12445 10.9998 7.66655C11.6167 5.12445 13.1046 3.47121 14.7498 3.49988C15.9004 3.49988 16.8332 4.43262 16.8332 5.58321C16.8332 6.73381 15.9004 7.66655 14.7498 7.66655"
        stroke="white"
        // stroke-opacity="0.1"
      />
    </svg>
  );
}
export default PackgeSVG;
