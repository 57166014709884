import SearchSVG from "components/svg/searchSvg";
import React, { useState } from "react";
import { forType } from "types";

interface Props {
  data: any;
  showModal: boolean;
  title: string;
  desc: string;
  setChecked: (name: string) => void;
  setRadioChecked: (name: string, id: number) => void;
  filterDone: (e: React.MouseEvent<HTMLElement>) => void;
  filterClear: (e: React.MouseEvent<HTMLElement>) => void;
}

const PlanFilter = ({
  data,
  setChecked,
  filterDone,
  filterClear,
  setRadioChecked,
}: Props) => {
  return (
    <div className="card card-compact flex flex-col  bg-white z-20 justify-start  w-64 h-80  shadow-2xl ">
      <div className="w-full p-2  rounded-xl text-sm text-center">فیلتر</div>
      <div className="flex flex-col justify-between h-60 px-4 pb-1 mt-1 overflow-y-auto">
        <div className="w-full flex flex-col my-1 justify-center border-t-2 gap-1.5">
          <div className="flex h-10 items-center">
            <input
              type="checkbox"
              defaultChecked={data.gender.checked}
              onChange={() => setChecked("gender")}
              className="checkbox checkbox-primary checkbox-xs ml-1"
            />
            <span className="label-text">جنسیت</span>
          </div>
          {data.gender.data.map((item: forType) => (
            <div
              className={`${
                !data.gender.checked && "hidden"
              } w-full flex  items-center text-xs`}
            >
              <input
                type="radio"
                name="gender"
                value={item.id}
                onChange={() => setRadioChecked("gender", item.id)}
                checked={item.checked}
                className="radio radio-xs ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary"
              />{" "}
              <span className="">{item.text}</span>
            </div>
          ))}
        </div>
        <div className="w-full flex flex-col my-1 py-1 border-t-2 gap-1.5">
          <div className="flex h-10 items-center">
            <input
              type="checkbox"
              defaultChecked={data.weight.checked}
              onChange={() => setChecked("weight")}
              className="checkbox checkbox-primary checkbox-xs ml-1"
            />
            <span className="label-text">شرایط وزنی</span>
          </div>

          {data.weight.data.map((item: forType) => (
            <div
              className={`${
                !data.weight.checked && "hidden"
              } w-full flex  items-center text-xs`}
            >
              <input
                type="radio"
                name="weight"
                value={item.id}
                onChange={() => setRadioChecked("weight", item.id)}
                checked={item.checked}
                className="radio radio-xs 	ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary"
              />{" "}
              <span className="">{item.text}</span>
            </div>
          ))}
        </div>
        <div className="w-full flex flex-col my-1 py-1 border-t-2 gap-1.5">
          <div className="flex h-10 items-center">
            <input
              type="checkbox"
              defaultChecked={data.place.checked}
              onChange={() => setChecked("place")}
              className="checkbox checkbox-primary checkbox-xs ml-1"
            />
            <span className="label-text">مکان</span>
          </div>

          {data.place.data.map((item: forType, idx: number) => (
            <div
              key={idx}
              className={`${
                !data.place.checked && "hidden"
              } w-full flex  items-center text-xs`}
            >
              <input
                type="radio"
                name="place"
                value={item.id}
                onChange={() => setRadioChecked("place", item.id)}
                checked={item.checked}
                className="radio radio-xs 	ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary"
              />{" "}
              <span className="">{item.text}</span>
            </div>
          ))}
        </div>

        <div className="w-full flex flex-col my-1 py-1 border-t-2 gap-1.5">
          <div className="flex h-10 items-center">
            <input
              type="checkbox"
              defaultChecked={data.level.checked}
              onChange={() => setChecked("level")}
              className="checkbox checkbox-primary checkbox-xs ml-1"
            />
            <span className="label-text">سطح</span>
          </div>

          {data.level.data.map((item: forType) => (
            <div
              className={`${
                !data.level.checked && "hidden"
              } w-full flex  items-center text-xs`}
            >
              <input
                type="radio"
                name="level"
                value={item.id}
                onChange={() => setRadioChecked("level", item.id)}
                checked={item.checked}
                className="radio radio-xs 	ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary"
              />{" "}
              <span className="">{item.text}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-between w-full h-11 items-start">
        <div className="h-3/4 ">
          <button
            onClick={(e: React.MouseEvent<HTMLElement>) => filterDone(e)}
            className=" bg-color1 text-white  px-3 h-full mr-2 rounded-md text-xs"
          >
            اعمال فیلتر
          </button>
        </div>
        <div className="h-3/4 ">
          <button
            onClick={(e: React.MouseEvent<HTMLElement>) => filterClear(e)}
            className=" bg-white text-black border px-3 h-full ml-2  rounded-md text-xs"
          >
            حذف همه
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanFilter;
