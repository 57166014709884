import React, { Dispatch, SetStateAction } from "react";
import CalendarSVG from "components/svg/calendarSvg";

interface Props {
  setter: Dispatch<SetStateAction<boolean>>;
}
const WeekDaysMobile = ({ setter }: Props) => {
  return (
    <nav className="md:hidden fixed right-0 top-[50%] rounded-e-lg z-20 h-12 w-6 bg-color2 flex  [&>*]:my-auto ">
      <button
        className="text-4xl flex text-white"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setter((value: boolean) => !value);
        }}
      >
        {" "}
        <CalendarSVG className="w-5 h-9 rotate-270" />
      </button>
    </nav>
  );
};

export default WeekDaysMobile;
