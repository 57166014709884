import { SVGProps } from "react";
import * as React from "react";

function ExitSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
    {...props}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 304.588 304.588"
      // fill="#7367F0"
    >
      <g id="SVGRepo_bgCarrier" />

      <g
        id="SVGRepo_tracerCarrier"
      
       
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <g>
            {" "}
            <g>
              {" "}
              <polygon
                // style={{ fill: "#7367F0" }}
                points="134.921,34.204 134.921,54.399 284.398,54.399 284.398,250.183 134.921,250.183 134.921,270.384 304.588,270.384 304.588,34.204 "
              />{" "}
            </g>{" "}
            <g>
              {" "}
              <polygon
              //  style={{ fill: "#7367F0" }}
                points="150.27,223.581 166.615,239.931 254.26,152.286 166.615,64.651 150.27,80.979 210.013,140.733 0,140.733 0,163.838 210.008,163.838 "
              />{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </g>
    </svg>

    // <svg
    //   {...props}
    //   version="1.1"
    //   id="Capa_1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 304.588 304.588"
    // >
    //   <g>
    //     <g>
    //       <g>
    //         <polygon
    //           points="134.921,34.204 134.921,54.399 284.398,54.399 284.398,250.183 134.921,250.183
    //      134.921,270.384 304.588,270.384 304.588,34.204 			"
    //         />
    //       </g>
    //       <g>
    //         <polygon
    //           className="bg-color1"
    //           points="150.27,223.581 166.615,239.931 254.26,152.286 166.615,64.651 150.27,80.979
    //      210.013,140.733 0,140.733 0,163.838 210.008,163.838 			"
    //         />
    //       </g>
    //     </g>
    //   </g>
    // </svg>
  );
}
export default ExitSVG;
