import React, { Dispatch, SetStateAction } from "react";
import { FiMenu as Icon } from "react-icons/fi";

interface Props {
  setter: Dispatch<SetStateAction<boolean>>;
}
const MenuBarMobile = ({ setter }: Props) => {
  return (
    <nav className="md:hidden z-20 h-12 bg-color1 flex  [&>*]:my-auto px-2">
      <button
        className="text-4xl flex text-white"
        onClick={() => {
          setter((value: boolean) => !value);
        }}
      >
        <Icon />
      </button>
    </nav>
  );
};

export default MenuBarMobile;
