import {
  onlyDigits,
  onlyLettersAndNumbers,
  removeCommas,
  thousandSeparator,
} from "components/helper/publicLib";
import SaveSVG from "components/svg/saveSvg";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { addPackage, getPackage, updatePackage } from "services/packageService";
import { toast } from "sonner";
import { packageType } from "types";

const initialValues: packageType = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "",
  duration: null,
  durationType: 0,
  price: null,
  description: "",
};

const Package = () => {
  const { packageId } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      isLoading,
    },
  } = useForm<packageType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAPI() {
      try {
        if (packageId) {
          // let filData: movementType;
          const retValPackageData = await getPackage(packageId);
          if (
            retValPackageData.status === 200 &&
            retValPackageData.data.result
          ) {
            // const { id, type, name, poster, description, movie, tags } =
            //   retValPackageData.data.result;

            reset(retValPackageData.data.result);
          }
        }
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  const onSubmit: SubmitHandler<packageType> = async (data) => {
    try {
      if (isSubmitting) return;

      if (!data.price) {
        setError("price", {
          type: "manual",
          message: "قیمت پکیج باید مقدار داشته باشد",
        });
        return;
      } else if (data.price < 500000) {
        setError("price", {
          type: "manual",
          message: "قیمت پکیج باید حداقل500,000 تومان باشد",
        });
        return;
      }
      let retValue;
      if (packageId)
        retValue = await updatePackage({
          ...data,
          price: data.price,
          duration: parseInt(data.duration!.toString()),
        });
      else
        retValue = await addPackage({
          ...data,
          price: data.price,
          duration: parseInt(data.duration!.toString()),
        });
      if (retValue.status === 200 && retValue.data) {
        toast.success("عملیات با موفقیت انجام پذیرفت");
        setTimeout(async () => {
          navigate(`/packages`);
        }, 2000);
      } else toast.error("خطا در ثبت اطلاعات");
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        // if (error.response.data.RsCode === 6) toast.error("حرکت تکراری است");
        // else toast.error("خطا در ثبت اطلاعات");
      } else toast.error("خطا در ثبت اطلاعات");
    }
  };
  const registerOptions = {
    name: {
      required: "نام پکیج اجباری است",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "نام پکیج معتبر نیست",
    },
    duration: {
      required: "مدت پکیج اجباری است",
    },
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full  h-full flex sm:ml-auto flex-col justify-start items-start px-3  sm:py-2 gap-y-3 text-sm "
    >
      <div className="w-full sm:w-96 flex px-2 ">
        <label
          data-tip={errors.name && errors.name.message}
          className={`form-control w-full  ${
            errors.name && "tooltip tooltip-bottom"
          }`}
        >
          <div className="label justify-start ">
            <span>نام پکیج</span>
          </div>
          <input
            autoFocus
            tabIndex={0}
            type="text"
            maxLength={60}
            {...register("name", registerOptions.name)}
            placeholder=""
            className={`input  h-8 input-bordered w-full  ${
              errors.name && "border border-red-600"
            }`}
          />
        </label>
      </div>
      <div className="w-full sm:w-96 flex px-2 justify-start items-end gap-x-2">
        <div className="w-2/4">
          <label
            data-tip={errors.duration && errors.duration.message}
            className={`form-control w-full  ${
              errors.duration && "tooltip tooltip-bottom"
            }`}
          >
            <div className="label justify-start ">
              <span>مدت پکیج</span>
            </div>
            <input
              tabIndex={0}
              type="text"
              maxLength={2}
              onKeyDown={(e: any) => {
                return onlyDigits(e);
              }}
              {...register("duration", registerOptions.duration)}
              placeholder=""
              className={`input   h-8 input-bordered w-full  ${
                errors.duration && "border border-red-600"
              }`}
            />
          </label>
        </div>
        <div className="w-1/6 sm:w-1/5">
          <select
            {...register("durationType", {
              valueAsNumber: true,
            })}
            defaultValue="0"
            className="rounded-md bg-transparent w-full text-sm"
          >
            <option value={0}>روز</option>
            <option value={1}>هفته</option>
            <option value={2}>ماه</option>
            <option value={3}>سال</option>
          </select>
        </div>
      </div>
      <div className="w-full sm:w-96 flex px-2 ">
        <label
          data-tip={errors.price && errors.price.message}
          className={`form-control w-full  ${
            errors.price && "tooltip tooltip-bottom"
          }`}
        >
          <div className="label justify-start ">
            <span>قیمت پکیج</span>
          </div>
          <div className="w-full flex justify-start items-center gap-x-3">
            <input
              tabIndex={0}
              type="text"
              maxLength={15}
              onKeyDown={(e: any) => {
                return onlyDigits(e);
              }}
              value={thousandSeparator(watch("price")?.toString() || "")}
              onChange={({ target }: any) => {
                let { value } = target;
                const priceTmp: string = removeCommas(value);
                if (parseFloat(priceTmp) >= 500000) clearErrors("price");
                setValue("price", parseFloat(removeCommas(value)));
              }}
              placeholder=""
              className={`input  h-8 input-bordered w-[50%] ltr ${
                errors.price && "border border-red-600"
              }`}
            />
            تومان
          </div>
        </label>
      </div>
      <button className="btn-1 bg-color1 text-white min-w-24 self-end mt-auto mb-1">
        {isSubmitting ? (
          <span
            className={`
         loading loading-spinner loading-xs`}
          ></span>
        ) : (
          <SaveSVG />
        )}
        ذخیره
      </button>
    </form>
  );
};

export default Package;
