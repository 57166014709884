import { SVGProps } from "react";
import * as React from "react";

function EyeoffSVG(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			fill="none"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3 3.9043L21 21.9043"
				stroke="black"
				
				stroke-linecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 3.9043L21 21.9043"
				stroke="#FFFFFF"
				stroke-opacity="0.6"
				
				stroke-linecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.584 11.4912C9.80248 12.2721 9.80203 13.5387 10.583 14.3202C11.3639 15.1017 12.6305 15.1021 13.412 14.3212"
				stroke="black"
				
				stroke-linecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.584 11.4912C9.80248 12.2721 9.80203 13.5387 10.583 14.3202C11.3639 15.1017 12.6305 15.1021 13.412 14.3212"
				stroke="#FFFFFF"
				stroke-opacity="0.2"
				
				stroke-linecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.15676 5.54828C8.75852 5.6622 8.52804 6.07739 8.64196 6.47563C8.75588 6.87387 9.17107 7.10435 9.56931 6.99043L9.15676 5.54828ZM12 5.90436L11.9974 6.65436H12V5.90436ZM22 12.9044L22.6512 13.2766C22.783 13.046 22.783 12.7629 22.6512 12.5322L22 12.9044ZM18.9463 15.8833C18.6651 16.1875 18.6838 16.662 18.988 16.9431C19.2922 17.2243 19.7667 17.2056 20.0478 16.9014L18.9463 15.8833ZM17.7764 18.8752C18.1198 18.6435 18.2104 18.1774 17.9788 17.834C17.7472 17.4906 17.281 17.3999 16.9376 17.6316L17.7764 18.8752ZM2 12.9044L1.34887 12.5322C1.21706 12.7628 1.21704 13.0459 1.34883 13.2765L2 12.9044ZM7.05301 8.18404C7.39581 7.95152 7.4852 7.48514 7.25268 7.14234C7.02017 6.79955 6.55378 6.71015 6.21099 6.94267L7.05301 8.18404ZM9.56931 6.99043C10.3588 6.76459 11.1762 6.65144 11.9974 6.65435L12.0027 5.15436C11.0402 5.15095 10.0821 5.28357 9.15676 5.54828L9.56931 6.99043ZM12 6.65436C15.64 6.65436 18.7632 8.75179 21.3489 13.2765L22.6512 12.5322C19.9029 7.72293 16.36 5.15436 12 5.15436V6.65436ZM21.3489 12.5321C20.5937 13.8533 19.792 14.9683 18.9463 15.8833L20.0478 16.9014C20.9841 15.8885 21.8504 14.6774 22.6512 13.2766L21.3489 12.5321ZM16.9376 17.6316C15.4284 18.6494 13.79 19.1544 12 19.1544V20.6544C14.094 20.6544 16.0236 20.0573 17.7764 18.8752L16.9376 17.6316ZM12 19.1544C8.36001 19.1544 5.23685 17.0569 2.65117 12.5322L1.34883 13.2765C4.09715 18.0858 7.63999 20.6544 12 20.6544V19.1544ZM2.65113 13.2765C3.98114 10.9498 5.45439 9.26839 7.05301 8.18404L6.21099 6.94267C4.37161 8.19032 2.75686 10.0689 1.34887 12.5322L2.65113 13.2765Z"
				fill="black"
			/>
			<path
				d="M9.15676 5.54828C8.75852 5.6622 8.52804 6.07739 8.64196 6.47563C8.75588 6.87387 9.17107 7.10435 9.56931 6.99043L9.15676 5.54828ZM12 5.90436L11.9974 6.65436H12V5.90436ZM22 12.9044L22.6512 13.2766C22.783 13.046 22.783 12.7629 22.6512 12.5322L22 12.9044ZM18.9463 15.8833C18.6651 16.1875 18.6838 16.662 18.988 16.9431C19.2922 17.2243 19.7667 17.2056 20.0478 16.9014L18.9463 15.8833ZM17.7764 18.8752C18.1198 18.6435 18.2104 18.1774 17.9788 17.834C17.7472 17.4906 17.281 17.3999 16.9376 17.6316L17.7764 18.8752ZM2 12.9044L1.34887 12.5322C1.21706 12.7628 1.21704 13.0459 1.34883 13.2765L2 12.9044ZM7.05301 8.18404C7.39581 7.95152 7.4852 7.48514 7.25268 7.14234C7.02017 6.79955 6.55378 6.71015 6.21099 6.94267L7.05301 8.18404ZM9.56931 6.99043C10.3588 6.76459 11.1762 6.65144 11.9974 6.65435L12.0027 5.15436C11.0402 5.15095 10.0821 5.28357 9.15676 5.54828L9.56931 6.99043ZM12 6.65436C15.64 6.65436 18.7632 8.75179 21.3489 13.2765L22.6512 12.5322C19.9029 7.72293 16.36 5.15436 12 5.15436V6.65436ZM21.3489 12.5321C20.5937 13.8533 19.792 14.9683 18.9463 15.8833L20.0478 16.9014C20.9841 15.8885 21.8504 14.6774 22.6512 13.2766L21.3489 12.5321ZM16.9376 17.6316C15.4284 18.6494 13.79 19.1544 12 19.1544V20.6544C14.094 20.6544 16.0236 20.0573 17.7764 18.8752L16.9376 17.6316ZM12 19.1544C8.36001 19.1544 5.23685 17.0569 2.65117 12.5322L1.34883 13.2765C4.09715 18.0858 7.63999 20.6544 12 20.6544V19.1544ZM2.65113 13.2765C3.98114 10.9498 5.45439 9.26839 7.05301 8.18404L6.21099 6.94267C4.37161 8.19032 2.75686 10.0689 1.34887 12.5322L2.65113 13.2765Z"
				fill="#FFFFFF"
				fillOpacity="0.2"
			/>
		</svg>
	);
}
export default EyeoffSVG;
