import { userLogedInType } from "types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface allStates {
  userLogedInData: userLogedInType;
  updateUserLogedInData: (item: userLogedInType) => void;
}

export const UseStore = create<allStates>()(
  persist(
    (set, get) => ({
      userLogedInData: {
        userName: "",
        userId: "",
        firstName: "",
        lastName: "",
        role:""
      },
      updateUserLogedInData: (item: userLogedInType) =>
        set({
          userLogedInData: { ...item },
        }),
    }),
    {
      name: "zustand-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
