import http from "services/authService";

const appUrl = process.env.REACT_APP_URL + "/Tag";

export function getTags() {
  return http.get(`${appUrl}/get-all-tags`);
}

// export function getTags() {
//   return { data: { resultData: movementTags } };
// }
