import React from "react";

const LoadingMovements = () => {
  return (
    <>
      {Array.apply(null, new Array(40)).map((el, i) => (
        <div
          key={i}
          className={`${
            i > 2 && i <= 30 ? "max-sm:hidden" : i > 30 && "sm:xl:hidden 2xl:flex"
          } w-44 h-60 md:w-36  md:h-52 border-base-content mb-4  skeleton `}
        ></div>
      ))}
    </>
  );
};

export default LoadingMovements;
