import { SVGProps } from "react";

function DashboardSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 22 22"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.6665"
        y="3.66675"
        width="5.5"
        height="4.58333"
        rx="2"
        stroke="black"
      />
      <rect
        x="3.6665"
        y="3.66675"
        width="5.5"
        height="4.58333"
        rx="2"
        stroke="white"
        // stroke-opacity="0.1"
      />
      <rect
        x="3.6665"
        y="11.9167"
        width="5.5"
        height="6.41667"
        rx="2"
        stroke="black"
      />
      <rect
        x="3.6665"
        y="11.9167"
        width="5.5"
        height="6.41667"
        rx="2"
        stroke="white"
        // stroke-opacity="0.1"
      />
      <rect
        x="12.8335"
        y="3.66675"
        width="5.5"
        height="6.41667"
        rx="2"
        stroke="black"
      />
      <rect
        x="12.8335"
        y="3.66675"
        width="5.5"
        height="6.41667"
        rx="2"
        stroke="white"
        // stroke-opacity="0.1"
      />
      <rect
        x="12.8335"
        y="13.75"
        width="5.5"
        height="4.58333"
        rx="2"
        stroke="black"
      />
      <rect
        x="12.8335"
        y="13.75"
        width="5.5"
        height="4.58333"
        rx="2"
        stroke="white"
        // stroke-opacity="0.1"
      />
    </svg>
  );
}
export default DashboardSVG;
