// import * as casdoorSetting from "components/helper/casdoorSetting";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MyContext } from "components/Context.js";
import { useEffect, useState } from "react";
import loadable from "@loadable/component";
import Elementary from "pages/elementary";
import Movements from "pages/movements";
import Athletes from "pages/athletes";
import Settings from "pages/settings";
import Branding from "pages/branding";

import Movement from "pages/movement";
import Analyse from "pages/analyse";
import { Toaster } from "sonner";
import Forms from "pages/forms";
import Plans from "pages/plans";
import Plan from "pages/plan";
import Main from "pages/main";
import "./App.css";
// import { movementType } from "types";
import SignUpLogin from "pages/auth/signUpLogin";
import SignUp from "pages/auth/signUp";
import { IsLoggedIn } from "components/helper/publicLib";
import MenuBarMobile from "components/sidebar/menuBarMobile";
import SideBar from "components/sidebar/sideBar";
import Login from "pages/auth/login";
import ForgetPassword from "pages/auth/forgetPassword";
import Package from "pages/package";
import Packages from "pages/packages";
import Athlete from "pages/athlete";

interface Props {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: Props) => {
  if (!IsLoggedIn()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const [username, setUsername] = useState("");
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [sdk] = useState(casdoorSetting.CasdoorSDK);
  const checkDarkMode = () => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) return true;
    else return false;
  };
  // const [movementsData, setMovementsData] = useState<movementType[]>([]);

  const LoadablePage = loadable(
    (props: any) => import(`./pages/${props.path}`),
    {
      fallback: (
        <div className="h-screen flex items-center justify-center mx-auto align-self-center">
          {" "}
          <i className="fa fa-spinner fa-spin fa-3x mx-1 visible"></i>
        </div>
      ),
      cacheKey: (props) => props.page,
    }
  );

  function gotoSignInPage() {
    // window.location.href = sdk.getSigninUrl();
    // document.getElementById("loginMethod") &&
    // (document.getElementById("loginMethod") as HTMLInputElement).value ===
    //   "signin"
    //   ? (window.location.href = sdk.getSigninUrl())
    //   : sdk.popupSignin(BACKEND_URL!);
  }

  // useEffect(() => {
  //   async function fetchAPI() {
  //     try {
  //       if (window.location.href.toString().startsWith("http://localhost:3003"))
  //         setIsLoggedIn(true);
  //       else if (window.location.href.indexOf("code") !== -1) {
  //         if (!sessionStorage.getItem("token")) {
  //           // const userData = await login(sdk);
  //           //setInfo(userData);
  //           setInfo({});
  //         } else
  //           getUserInfo()
  //             .then((res: any) => {
  //               if (res) {
  //                 setInfo(res);
  //               } else signOut();
  //             })
  //             .catch((err) => {
  //               toast.error("خطا در برقراری ارتباط با مرکز");
  //               signOut();
  //             });
  //       } else {
  //         if (sessionStorage.getItem("token")) {
  //           getUserInfo()
  //             .then((res: any) => {
  //               if (res) setInfo(res);
  //               else signOut();
  //             })
  //             .catch((err) => {
  //               toast.error("خطا در برقراری ارتباط با مرکز");
  //               signOut();
  //             });
  //         } else gotoSignInPage();
  //       }
  //     } catch (error) {
  //       toast.error("خطا در برقراری ارتباط با مرکز");
  //       signOut();
  //     }
  //   }
  //   fetchAPI();
  // }, []);

  // function setInfo(res: any) {
  //   try {
  //     let userinfo = res;
  //     setUsername(userinfo.displayName);
  //     setIsLoggedIn(true);
  //   } catch (error) {}
  // }
  // useEffect(() => {
  //   if (sessionStorage.getItem("token")) {
  //     getInfo().then((res) => setInfo(res));
  //   }
  // }, [tokenReceived]);

  // function signOut() {
  //   sessionStorage.removeItem("token");
  //   window.location.href = window.location.href.split(",")[0];
  // }

  const [isDarkMode] = useState<boolean>(checkDarkMode());
  const [typeOfMovement, setTypeOfMovement] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  return (
    <MyContext.Provider
      value={{
        isDarkMode,
        typeOfMovement,
        setTypeOfMovement,
        // movementsData,
        // setMovementsData,
      }}
    >
      <Toaster
        className="font-yekan"
        richColors
        position="top-left"
        theme={`${isDarkMode ? "dark" : "light"}`}
        duration={2000}
      />
      <div className="h-screen font-yekan flex max-md:flex-col">
        {/* <header className="App-header" /> */}
        {/* {isLoggedIn && ( */}
        <BrowserRouter>
          {IsLoggedIn() && (
            <div>
              <div className="flex max-md:flex-col h-full">
                <MenuBarMobile setter={setShowSidebar} />
                <SideBar show={showSidebar} setter={setShowSidebar} />
              </div>
            </div>
          )}
          <div className="flex flex-grow items-center max-md:h-[calc(100%-5rem)] justify-center     md:w-full  bg-[#F8F7FA]">
            <Routes>
              <Route
                key={0}
                path="/main"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Main} path="main" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={2}
                path="/elementary"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Elementary} path="elementary" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={3}
                path={"movement/:movementId?"}
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Movement} path="movement" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={4}
                path="/athletes"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Athletes} path="athletes" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={11}
                path="/athlete/:athleteId?"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Athlete} path="athlete" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={5}
                path="/packages"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Packages} path="packages" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={9}
                path="/package/:packageId?"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Package} path="package" />
                  </ProtectedRoute>
                }
              />
              {/*<Route
                key={7}
                path="/settings"
                element={<LoadablePage page={Settings} path="settings" />}
              /> */}
              <Route
                key={8}
                path="movements"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Movements} path="movements" />
                  </ProtectedRoute>
                }
              />

              <Route
                key={1}
                path="plans"
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Plans} path="plans" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={10}
                path={"plan/:planId?"}
                element={
                  <ProtectedRoute>
                    <LoadablePage page={Plan} path="plan" />
                  </ProtectedRoute>
                }
              />
              <Route
                key={4}
                path="/"
                element={
                  <LoadablePage page={SignUpLogin} path="auth/signUpLogin" />
                }
              />

              <Route
                key={5}
                path="/signUp"
                element={<LoadablePage page={SignUp} path="auth/signUp" />}
              />

              <Route
                key={6}
                path="/login"
                element={<LoadablePage page={Login} path="auth/login" />}
              />

              <Route
                key={7}
                path="/forgetPassword"
                element={
                  <LoadablePage
                    page={ForgetPassword}
                    path="auth/forgetPassword"
                  />
                }
              />
              {/* <Route path="/" element={<Main />} /> */}
              {/* <Route path="/callback" element={<Login />} /> */}
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </MyContext.Provider>
  );
}

export default App;