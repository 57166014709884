import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CopyFromWeekModal from "components/modals/copyFromWeekModal";
import { useFieldArray, useFormContext } from "react-hook-form";
import WorkoutSVG from "components/svg/workOutSvg";
import Numbring from "components/numbering.json";
import PasteSVG from "components/svg/pasteSvg";
import RestSVG from "components/svg/restSvg";
import Player from "components/player.json";
import { dayType } from "types";
import { toast } from "sonner";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;

interface Props {
  selectedWeek: number;
  setShowUpdateModal: (state: boolean) => void;
  selectedDay: number;
  setSelectedDay: (sDay: number) => void;
  setSelectedWeek: (sWeek: number) => void;
  setSelectedWeekTemp: (sWeek: number) => void;
  setSelectedDayTemp: (sDay: number) => void;
  show: boolean;
  setter: Dispatch<SetStateAction<boolean>>;
}

const WeekDays = ({
  selectedWeek,
  setShowUpdateModal,
  selectedDay,
  setSelectedDay,
  setSelectedWeek,
  setSelectedWeekTemp,
  setSelectedDayTemp,
  show,
  setter,
}: Props) => {
  // const [selectedWeekTemp, setSelectedWeekTemp] = useState<number>(-1);
  // const [selectedDayTemp, setSelectedDayTemp] = useState<number>(0);
  const [showCopyFromWeekModal, setShowCopyFromWeekModal] =
    useState<boolean>(false);
  const {
    register,
    control,
    formState,
    getValues,
    reset,
    setValue,
    clearErrors,
    watch,
    trigger,
    resetField,
    getFieldState,
    setError,
    setFocus,
  } = useFormContext();
  const { errors, dirtyFields, isValid } = formState;

  const { append: appendWeek } = useFieldArray({
    control,
    name: "weekDays",
  });

  const {
    fields: daysOfWeek,
    append: appendDay,
    remove: removeDay,
    // update: updateDay,
    // prepend,
  } = useFieldArray({
    control,
    name: `weekDays`,
  });

  const watchDaysOfWeek = watch(`weekDays`);

  const selectCopyFromBtnClick = (idx: number) => {
    setShowCopyFromWeekModal(false);
    const daysOfWeekCopyFrom = getDaysOfWeek(idx + 1);
    const daysOfWeekCopyTo = getDaysOfWeek(selectedWeek + 1);
    if (daysOfWeekCopyTo && daysOfWeekCopyTo.length > 0) {
      let idx = watchDaysOfWeek.findIndex(
        (item: dayType) => item.weekIndex === selectedWeek + 1
      );
      while (idx !== -1) {
        removeDay(idx);

        const index = watchDaysOfWeek.findIndex(
          (item: dayType) => item.weekIndex === selectedWeek + 1
        );
        if (idx === index) break;
      }
    }

    if (daysOfWeekCopyFrom.length === 7) {
      daysOfWeekCopyFrom.forEach((day: dayType) =>
        appendDay({ ...day, weekIndex: selectedWeek + 1 })
      );
      reset(watch(), {
        keepValues: false,
        keepDirty: false,
        keepDefaultValues: false,
      });
    } else toast.error("تعداد روزهای هفته انتخابی کامل نشده است");
  };

  const appendClass = show ? " mr-0" : " mr-[-210px] md:mr-0";
  const ModalOverlay = () => (
    <div
      className={`flex md:hidden fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-30`}
      onClick={() => {
        clickedMenu();
      }}
    />
  );

  const getDaysOfWeek = (weekIndex: number) => {
    const retVal: dayType[] = watchDaysOfWeek.filter(
      (day: dayType) => day && day.weekIndex === weekIndex
    );
    return retVal.length ? retVal : [];
  };
  const getDayOfWeek = (weekIdx: number, dayIdx: number) => {
    const retVal: dayType[] = watchDaysOfWeek.filter(
      (day: dayType) =>
        day && day.weekIndex === weekIdx && day.dayIndex === dayIdx
    );

    return retVal.length ? retVal[0] : null;
  };

  const clickedMenu = () => {
    setter((oldVal: boolean) => !oldVal);
  };
  return (
    // <div className={`h-full flex flex-col w-full sm:w-2/6 xl:w-1/6 `}>
    <>
      <div
        className={`h-[calc(100%-5rem)] top-16 md:top-0 max-md:bg-slate-100 max-md:rounded-e-md max-md:px-1 max-md:pt-1  md:h-full flex flex-col w-[210px] transition-[margin-right] ease-in-out duration-500 fixed md:static right-0 z-40 ${appendClass}`}
      >
        <label className="form-control w-full h-[11%] relative">
          <div>
            <span>انتخاب هفته</span>
          </div>
          <select
            className="select  max-2xl:select-sm select-bordered mt-2"
            name="planWeeks"
            onChange={(e) => {
              e.preventDefault();
              // newStrategy if (
              //   dirtyFields?.weekDays &&
              //   (dirtyFields?.weekDays as any)[selectedWeek]
              // ) {
              //   setSelectedWeekTemp(parseInt(e.target.value));
              //   setSelectedDayTemp(0);
              //   setShowUpdateModal(true);
              //   return false;
              // }

              setSelectedDay(0);
              setSelectedWeek(parseInt(e.target.value));
              const weeDaysLocal: dayType[] = watch("weekDays");
              if (
                weeDaysLocal.findIndex(
                  (item: dayType) =>
                    item.weekIndex === parseInt(e.target.value) + 1
                ) === -1
              )
                appendWeek({
                  weekIndex: parseInt(e.target.value) + 1,
                  dayIndex: 1,
                  isRest: false,
                  dayName: null,
                  dayDescription: null,
                  dayLongTime: 0,
                  // circuits: [],
                });
              // clickedMenu();
            }}
            value={selectedWeek}
            defaultValue={-1}
          >
            <option disabled value={-1} className="max-2xl:text-xs w-[100px]">
              انتخاب
            </option>
            {Array.apply(
              null,
              new Array(parseInt(getValues("planTime")?.toString() || "0"))
            ).map((el, i) => {
              return (
                <option
                  key={i}
                  disabled={
                    (selectedWeek === -1 &&
                      i > 0 &&
                      (getValues("weekDays")?.length || 0) === 0) ||
                    (getValues("weekDays")?.length || 0) < i ||
                    (i > 0 &&
                      !getDayOfWeek(i, 7)?.dayName &&
                      !getDayOfWeek(i, 7)?.isRest)
                  }
                  className="text-lime-600 disabled:text-slate-300 max-2xl:text-xs w-[100px]"
                  value={i}
                >
                  هفته {Numbring[i++]}{" "}
                </option>
              );
            })}
          </select>
          <div
            onClick={() => setShowCopyFromWeekModal(true)}
            data-tip="کپی از هفته..."
            className={`absolute left-2 h-[11%] mt-7 2xl:mt-11 cursor-pointer tooltip tooltip-bottom ${
              (selectedWeek < 1 || showCopyFromWeekModal) && " hidden"
            }`}
          >
            <PasteSVG className="w-5" />
          </div>
          {showCopyFromWeekModal && (
            <div className="absolute w-[200px] left-0 top-[80%] md:top-[100%]">
              <CopyFromWeekModal
                weeksList={selectedWeek}
                selectWeekBtnClick={selectCopyFromBtnClick}
                exitBtnClick={() => setShowCopyFromWeekModal(false)}
              />
            </div>
          )}
        </label>
        <div className="flex flex-col  mt-3  min-h-[87%] border-l-2 gap-y-2">
          {selectedWeek !== -1 &&
            Array.apply(null, new Array(7)).map((el, i) => {
              const daysLength = getDaysOfWeek(selectedWeek + 1).length || 0;
              return (
                <div
                  key={i}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    if (
                      selectedDay > 0 &&
                      !watchDaysOfWeek![selectedWeek * 7 + selectedDay]?.dayName
                    ) {
                      // resetField(`weekDays.${selectedWeek * 7 + selectedDay}`, {
                      //   keepError: false,
                      //   keepDirty: false,
                      //   keepTouched: false,
                      // });
                      removeDay(selectedWeek * 7 + selectedDay);
                    }
                    if (
                      dirtyFields?.weekDays &&
                      // dirtyFields?.weekDays.length > selectedWeek &&
                      // (dirtyFields?.weekDays as any)[selectedWeek] &&
                      // (dirtyFields?.weekDays as any)[selectedWeek].days.length >
                      //   selectedDay &&

                      (dirtyFields?.weekDays as any)[
                        selectedWeek * 7 + selectedDay
                      ] &&
                      watchDaysOfWeek &&
                      watchDaysOfWeek.length > 0 &&
                      watchDaysOfWeek![selectedWeek * 7 + selectedDay].dayName
                    ) {
                      setSelectedWeekTemp(selectedWeek);
                      setSelectedDayTemp(i);
                      setShowUpdateModal(true);
                      return false;
                    }

                    const { dayName, isRest } = getDayOfWeek(
                      selectedWeek + 1,
                      i
                    ) || { dayName: undefined, isRest: undefined };
                    if ((daysLength || 0) > 0 && i > 0 && !dayName && !isRest)
                      return false;
                    if (
                      (daysLength || 0) === i &&
                      ((daysLength || 0) === 0 ||
                        ((daysLength || 0) > 0 && (dayName || isRest)))
                    ) {
                      appendDay({
                        weekIndex: selectedWeek + 1,
                        dayIndex: daysLength + 1,
                        isRest: false,
                        dayName: null,
                        dayDescription: null,
                        dayLongTime: 0,
                      });
                      // setNewDay(true);
                    }
                    // else setNewDay(false);
                    // setCurrentDayIndex(i);
                    if ((daysLength || 0) >= i) setSelectedDay(i);
                    clickedMenu();
                  }}
                  className={`h-[12.5%] flex items-center justify-between ${
                    selectedDay === i
                      ? "bg-color2 dark:text-black"
                      : "bg-base-100 dark:text-white"
                  } shadow-xl rounded-lg p-2   ${
                    (((daysLength || 0) >= i &&
                      getDayOfWeek(selectedWeek + 1, i)?.dayName) ||
                      i === 0) &&
                    "cursor-pointer hover:bg-black-300"
                  }`}
                >
                  <div className="flex flex-col ">
                    <div
                      className={`text-xs font-bold ${
                        !getDayOfWeek(selectedWeek + 1, i + 1)?.dayName &&
                        "text-slate-400 "
                      }`}
                    >
                      روز {Numbring[i]}{" "}
                    </div>
                    <div
                      className={`text-xs font-thin ms-2 mt-1 ${
                        watchDaysOfWeek &&
                        !getDayOfWeek(selectedWeek + 1, i + 1)?.dayName &&
                        "text-slate-400 "
                      }`}
                    >
                      {watchDaysOfWeek &&
                        (getDayOfWeek(selectedWeek + 1, i + 1)?.isRest
                          ? "روز استراحت"
                          : getDayOfWeek(selectedWeek + 1, i + 1)?.dayName
                          ? getDayOfWeek(selectedWeek + 1, i + 1)?.dayName
                          : "این روز را ویرایش نمایید")}
                      {/* {getValues(`weekDays.${selectedWeek}.days.${i}.isRest`)
                                ? "روز استراحت"
                                : getValues(
                                    `weekDays.${selectedWeek}.days.${i}.dayName`
                                  )} */}
                    </div>
                  </div>
                  <div>
                    {watchDaysOfWeek &&
                      !getDayOfWeek(selectedWeek + 1, i + 1)?.isRest &&
                      getDayOfWeek(selectedWeek + 1, i + 1)?.dayName && (
                        <div className="flex relative">
                          {watchDaysOfWeek &&
                            getDayOfWeek(selectedWeek + 1, i + 1)?.circuits &&
                            getDayOfWeek(selectedWeek + 1, i + 1)!.circuits!
                              .length > 1 && (
                              <div className="badge badge-sm z-10 absolute -mr-5 ltr dark:bg-white dark:text-black">
                                <span>
                                  +
                                  {getDayOfWeek(selectedWeek + 1, i + 1)!
                                    .circuits!.length - 1}
                                </span>
                              </div>
                            )}
                          <div className="avatar w-8 h-8 ">
                            {getDayOfWeek(selectedWeek + 1, i + 1) &&
                              getDayOfWeek(selectedWeek + 1, i + 1)?.circuits &&
                              getDayOfWeek(selectedWeek + 1, i + 1)!.circuits!
                                .length > 0 &&
                              getDayOfWeek(selectedWeek + 1, i + 1)!
                                .circuits![0]?.id && (
                                <img
                                  src={
                                    getDayOfWeek(selectedWeek + 1, i + 1)!
                                      .circuits![0].circuitExercises![0]
                                      .movementId
                                      ? `${moviesUrl}/media/${
                                          getDayOfWeek(selectedWeek + 1, i + 1)!
                                            .circuits![0].circuitExercises![0]
                                            .movementId
                                        }/thumbnail.png`
                                      : Player.imageUrl
                                  }
                                  alt="exerciseLogo"
                                  className="relative mx-auto rounded-full overflow-hidden"
                                />
                              )}
                          </div>
                        </div>
                      )}
                    {watchDaysOfWeek &&
                      getDayOfWeek(selectedWeek + 1, i + 1)?.isRest && (
                        <RestSVG className="w-8 h-8" />
                      )}
                    {watchDaysOfWeek &&
                      !getDayOfWeek(selectedWeek + 1, i + 1) && (
                        <WorkoutSVG className="w-8 h-8" />
                      )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {show ? <ModalOverlay /> : <></>}
    </>
  );
};
export default WeekDays;