import Weeks from "components/numbering.json";
import React, { useState } from "react";

interface Props {
  weeksList: number | null;
  exitBtnClick: () => void;
  selectWeekBtnClick: (weekindex: number) => void;
}

const CopyFromWeekModal = ({
  exitBtnClick,
  selectWeekBtnClick,
  weeksList,
}: Props) => {
  const [selectedRadio, setSelectedRadio] = useState<number>(0);
  return (
    <div className="card card-compact !rounded-lg flex flex-col bg-slate-100  z-20 justify-start max-h-56  w-48  shadow-2xl ">
      <div className="w-full bg-color2 rounded-lg p-1 flex flex-col text-xs items-center border-b-2 h-1/6 ">
        <div className="pb-2">کپی از</div>
      </div>
      <div className="flex flex-col justify-between   px-4 pb-3 mt-1 h-4/6 overflow-y-auto">
        {Array.apply(null, new Array(weeksList)).map((el, i) => {
          return (
            <div key={i} className="flex items-center gap-x-1 mt-2 ">
              <input
                type="radio"
                onChange={() => setSelectedRadio(i)}
                checked={selectedRadio === i}
                className="radio radio-sm  radio-primary "
              />{" "}
              <span className="text-xs">هفته {Weeks[i]} </span>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between w-full h-1/6">
        <button
          onClick={() => selectWeekBtnClick(selectedRadio)}
          className=" bg-color1 text-white m-2 px-3 py-1 rounded-md text-xs"
        >
          اعمال
        </button>
        <button
          onClick={() => exitBtnClick()}
          className=" bg-white text-black border m-2 px-3 py-1 rounded-md text-xs"
        >
          انصراف
        </button>
      </div>
    </div>
  );
};

export default CopyFromWeekModal;
