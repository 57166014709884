import { SVGProps } from "react";

function AnalyticsSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28571 2H2V18.7143C2 19.0553 2.13546 19.3823 2.37658 19.6234C2.6177 19.8645 2.94472 20 3.28571 20H20V18.7143H3.28571V2Z"
        fill="black"
      />
      <path
        d="M3.28571 2H2V18.7143C2 19.0553 2.13546 19.3823 2.37658 19.6234C2.6177 19.8645 2.94472 20 3.28571 20H20V18.7143H3.28571V2Z"
        fill="black"
        // fillOpacity="0.1"
      />
      <path
        d="M19 6H14.625V7.42857H16.8688L12.125 12.85L9.44375 9.77857C9.38565 9.71162 9.31652 9.65848 9.24036 9.62222C9.1642 9.58596 9.08251 9.56729 9 9.56729C8.91749 9.56729 8.8358 9.58596 8.75964 9.62222C8.68348 9.65848 8.61435 9.71162 8.55625 9.77857L4 14.9929L4.88125 16L9 11.2929L11.6813 14.3643C11.7394 14.4312 11.8085 14.4844 11.8846 14.5206C11.9608 14.5569 12.0425 14.5756 12.125 14.5756C12.2075 14.5756 12.2892 14.5569 12.3654 14.5206C12.4415 14.4844 12.5106 14.4312 12.5687 14.3643L17.75 8.43571V11H19V6Z"
        fill="black"
      />
      <path
        d="M19 6H14.625V7.42857H16.8688L12.125 12.85L9.44375 9.77857C9.38565 9.71162 9.31652 9.65848 9.24036 9.62222C9.1642 9.58596 9.08251 9.56729 9 9.56729C8.91749 9.56729 8.8358 9.58596 8.75964 9.62222C8.68348 9.65848 8.61435 9.71162 8.55625 9.77857L4 14.9929L4.88125 16L9 11.2929L11.6813 14.3643C11.7394 14.4312 11.8085 14.4844 11.8846 14.5206C11.9608 14.5569 12.0425 14.5756 12.125 14.5756C12.2075 14.5756 12.2892 14.5569 12.3654 14.5206C12.4415 14.4844 12.5106 14.4312 12.5687 14.3643L17.75 8.43571V11H19V6Z"
        fill="black"
        // fillOpacity="0.1"
      />
    </svg>
  );
}
export default AnalyticsSVG;
