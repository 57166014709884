import React, { useRef, useState } from "react";
import SpinnerSVG from "./svg/spinnerSvg";

interface Props {
  src: string;
  classes?: string;

  poster?: string;
}

const VideoHover = ({ src, poster, classes }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      let p = videoRef.current.play();
      if (p !== undefined) p.catch(function () {});
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) videoRef.current.pause();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative  ${classes} `}
    >
      <video
        src={src}
        ref={videoRef}
        preload="none"
        loop
        muted
        className="w-full h-full  rounded-md object-fill"
        poster={poster}
        // onLoadStart={() => {
        //   setIsLoading(true);
        // }}
        // onLoadedData={() => {
        //   setIsLoading(false);
        // }}
        onCanPlayThrough={() => setIsLoading(false)}
      />
      {!isHovered && (
        <div className="w-full h-full bg-slate-950 absolute top-0 left-0 flex justify-center items-center text-white opacity-50 rounded-md">
          پخش ویدیو
        </div>
      )}
      {isLoading && isHovered && (
        <div className="w-full h-full bg-slate-950 absolute top-0 left-0 flex justify-center items-center text-white opacity-30 rounded-md">
          <SpinnerSVG className="animate-spin h-5 w-5 text-white" />
        </div>
      )}
    </div>
  );
};

export default VideoHover;
