import React from "react";

const LoadingPlans = () => {
  return (
    <>
      {Array.apply(null, new Array(40)).map((el, i) => (
        <div
          key={i}
          className="justify-between w-44 md:w-36 h-64 md:h-56 border-base-content mb-6  skeleton "
        ></div>
      ))}
    </>
  );
};

export default LoadingPlans;
