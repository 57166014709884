import React, { useEffect, useState } from "react";

interface Props {
  startTime: number;
  finished: () => void;
}

const CountDownTimer = ({ startTime, finished }: Props) => {
  const [time, setTime] = useState<number>(startTime);

  useEffect(() => {
    let interval = setInterval(() => {
      if (time === 0) {
        clearInterval(interval);
        finished();
        setTime(0);
      } else setTime(time - 1);
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <p>
        {`${Math.floor(time / 60)}`.padStart(2, "0")}:
        {`${time % 60}`.padStart(2, "0")}
      </p>
    </div>
  );
};

export default CountDownTimer;
