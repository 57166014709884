import {
  DefaultValues,
  SubmitHandler,
  useFieldArray,
  FormProvider,
  useForm,
} from "react-hook-form";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  getPlan,
  publishPlanData,
  savePlan,
  updatePlan,
  uploadMedia,
} from "services/planService";
import { onlyDigits, onlyLettersAndNumbers } from "components/helper/publicLib";
import professionalInfo from "components/professionalInfo.json";
import WeekDaysMobile from "components/weekdays/weekDaysMobile";
import { dayType, forType, planType, tagType } from "types";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmModal from "components/modals/confirmModal";
import DayComponent from "components/plan/dayComponent";
import PlanCoverSVG from "components/svg/planCoverSvg";
import WeekDays from "components/weekdays/weekDays";
import privatePlans from "components/plans.json";
import Numbring from "components/numbering.json";
import { GrFormPrevious } from "react-icons/gr";
import planTags from "components/planTags.json";
import { getTags } from "services/tagsService";
import { GiSaveArrow } from "react-icons/gi";
import SwapSVG from "components/svg/swapSvg";
import TagCtrl from "components/tag/tagCtrl";
import { Tag } from "react-tag-input";
import { toast } from "sonner";

// import Player from "../components/player.json";
const moviesUrl = process.env.REACT_APP_MOVIES_URL;

const initialValues: DefaultValues<planType> = {
  id: undefined,
  planName: "",
  planDescription: "",
  logo: null,
  tags: null,
  planTime: 0,
  gender: 1,
  weight: 1,
  place: 1,
  level: 1,
  state: 0,
  weekDays: [],
};

const Plan = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [logoFile, setLogoFile] = useState<any>(null);
  const [selectedWeek, setSelectedWeek] = useState<number>(-1);
  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [selectedWeekTemp, setSelectedWeekTemp] = useState<number>(-1);
  const [selectedDayTemp, setSelectedDayTemp] = useState<number>(0);
  const [showWeekDays, setShowWeekDays] = useState<boolean>(true);
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [logoFileChanged, setLogoFileChanged] = useState<boolean>(false);

  // const [showCopyFromWeekModal, setShowCopyFromWeekModal] =
  //   useState<boolean>(false);
  const navigate = useNavigate();
  const [localPlanTags, setlocalPlanTags] = useState<string[]>(
    []
    // planTags.map((tag) => tag.text)
  );

  const { planId } = useParams();
  const [originalTags, setOriginalTags] = useState<tagType[]>([]);

  const [tags, setTags] = useState<Tag[]>([]);
  const form = useForm<planType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    resetField,
    // formState,
    trigger,
    getFieldState,
    formState: {
      errors,
      // isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      // isLoading,
      dirtyFields,
    },
  } = form;

  // const {
  //   // fields: weekDays,
  //   append: appendWeek,
  //   // remove: removeWeek,
  // } = useFieldArray({
  //   control,
  //   name: "weekDays",
  // });

  // const {
  //   // fields: daysOfWeek,
  //   append: appendDay,
  //   // remove: removeDay,
  //   // update: updateDay,
  //   // prepend,
  // } = useFieldArray<PlanType>({
  //   control,
  //   name: `weekDays.${selectedWeek}.days`,
  // });

  // const watchDaysOfWeek = watch(`weekDays.${selectedWeek}.days`);
  const logoRef = useRef<HTMLInputElement>(null);
  const logoref = register("logo");
  useImperativeHandle(logoref.ref, () => logoRef.current);

  const planNameRef = useRef<HTMLInputElement>(null);
  const { ref, ...rest } = register("planName", {
    required: "نام برنامه را وارد نمایید است",
    validate: (value: string) =>
      onlyLettersAndNumbers(value) || "نام برنامه معتبر نیست",
  });
  useImperativeHandle(ref, () => planNameRef.current);

  const registerOptions = {
    planDescription: {
      required: "توضیحات برنامه را وارد نمایید",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "توضیحات اجرا معتبر نیست",
    },
    // planTime: {
    //   required: "مدت برنامه را وارد نمایید",
    //   validate: (value: any) =>
    //     onlyLettersAndNumbers(value) || "مدت برنامه معتبر نیست",
    // },
    // gender: {
    //   required: "جنسیت الزامی است",
    // },
  };

  useEffect(() => {
    planNameRef.current?.focus();
    async function fetchAPI() {
      try {
        const { data } = await getTags();
        // setOriginalTags(
        //   data.resultData.map((tag: any) => ({ id: tag.id, text: tag.name }))
        // );
        setOriginalTags(
          data.result.map((tag: string) => ({
            id: tag,
            text: tag,
          }))
        );
        let filData: any;

        if (planId) {
          //filData = privatePlans.filter((item) => item.id === planId)[0];
          const { data } = await getPlan(planId);
          if (data && data.result) {
            const { result: filData } = data;
            // setLogoFile(filData.logo?.toString());
            setTags(filData.tags!);
            if (filData.weekDays && filData.weekDays.length > 0)
              setSelectedWeek(0);
            const filledData: any = {
              ...filData,
              // gender: filData.gender.toString(),
              // weight: filData.weight!.toString(),
              // level: filData.level!.toString(),
              // place: filData.place!.toString(),
              // planTime: filData.planTime!.toString(),
            };
            reset(filledData);
          }
        }
      } catch (error) {}
    }
    fetchAPI();
  }, []);

  // const prepareTagsForSave = (data: planType) => {
  //   let tagsData: any = data.tags?.map((tag) => {
  //     const find = originalTags.find((oTag: tagType) => oTag.text === tag.id);
  //     if (find) return find.id;
  //   });
  //   return tagsData;
  // };
  const onSubmit: SubmitHandler<planType> = async (data) => {
    try {
      if (isSubmitting || !isValid) return;

      if (currentStep === 2 && stepValidation(currentStep)) return;

      const PlanDataForSave: planType = {
        ...data,
        logo: null,
        tags: !data.tags ? [] : data.tags,
        state: 0,
      };
      let savedPlan: any;
      if (data.id) savedPlan = await updatePlan(PlanDataForSave);
      else savedPlan = await savePlan(PlanDataForSave);
      if (savedPlan.status === 200) reset(savedPlan.data.result[0]);

      if (logoFileChanged && savedPlan.status === 200) {
        await uploadMedia({
          File: data.logo as File,
          IsPlanPoster: true,
          MovementId: "",
          PlanId: savedPlan.data.result.id,
        });
      }

      toast.success("عملیات با موفقیت انجام پذیرفت");

      navigate("/plans");
    } catch (error) {
      toast.error("خطا در ثبت اطلاعات");
    }
  };

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: Tag) => {
    if (onlyLettersAndNumbers(tag.text)) {
      setTags([...tags, tag]);
      setValue("tags", [...tags, tag]);
    }
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    //   console.log("The tag at index " + index + " was clicked");
  };

  const acceptUpdateBtnClick = () => {
    const errors = getFieldState(`weekDays`).error;

    if (!errors) {
      reset(watch(), {
        keepValues: false,
        keepDirty: false,
        keepDefaultValues: false,
      });
      setSelectedWeek(selectedWeekTemp);
      setSelectedDay(selectedDayTemp);
    }
    setShowUpdateModal(false);
  };

  const rejectUpdateBtnClick = () => {
    reset();
    setSelectedWeek(selectedWeekTemp);
    setSelectedDay(selectedDayTemp);
    setShowUpdateModal(false);
  };

  const stepValidation = (CurrentStep: number) => {
    if (CurrentStep === 1) {
      trigger();
    } else if (CurrentStep === 2) {
      if (
        getValues("gender") === null ||
        getValues("weight") === null ||
        getValues("place") === null ||
        getValues("level") === null ||
        getValues("planTime") === null ||
        getValues("planTime") === ""
      ) {
        if (getValues("planTime") === null) {
          setError("planTime", {
            type: "manual",
            message: "مدت برنامه اجباری است",
          });
        }
        if (getValues("gender") === null) {
          setError("gender", {
            type: "manual",
            message: "جنسیت اجباری است",
          });
        }
        if (getValues("weight") === null) {
          setError("weight", {
            type: "manual",
            message: ",وزن اجباری است",
          });
        }
        if (getValues("place") === null) {
          setError("place", {
            type: "manual",
            message: "مکان اجباری است",
          });
        }
        if (getValues("level") === null) {
          setError("level", {
            type: "manual",
            message: "سطح اجباری است",
          });
        }
        return true;
      } else if (
        parseInt(getValues("planTime")?.toString() || "0") > 12 ||
        parseInt(getValues("planTime")?.toString() || "0") < 1
      ) {
        setError("planTime", {
          type: "manual",
          message: "مدت برنامه معتبر نیست",
        });
        return true;
      } else {
        clearErrors(["planTime"]);
        return false;
      }
    }
  };

  const publishPlan = async () => {
    stepValidation(1);
    stepValidation(2);
    if (!isValid) {
      toast.error("بعلت تکمیل نبودن اطلاعات، امکان انتشار وجود ندارد");
      return false;
    }
    const planTimeLocal = parseInt(getValues("planTime")?.toString() || "0");

    const weeksOfExcer = getValues(`weekDays`);
    if (!weeksOfExcer || weeksOfExcer.length < planTimeLocal * 7) {
      const message = `اطلاعات تکمیل نشده است `;
      toast.error(message);
      return false;
    }
    for (let i = 0; i < planTimeLocal; i++) {
      // if (!weeksLocal) {
      //   toast.error("هفته های برنامه تکمیل نشده است");
      //   return false;
      // }
      // //for (let j = 0; j < planTimeLocal; j++) {
      // const daysLocal = getValues(`weekDays`);
      // const daysLength = daysLocal ? daysLocal.length : 0;
      // if (daysLength === 0) {
      //   const message = `اطلاعات تکمیل نشده است `;
      //   toast.error(message);
      //   return false;
      // }
      // const Remainder = Math.floor(daysLength % 7);
      // const Division = Math.floor(daysLength / 7);

      // if (Remainder !== 0) {
      //   const message = `روز ${Numbring[Division]} از هفته ${Numbring[Remainder]} تکمیل نشده است `;
      //   toast.error(message);
      //   return false;
      // }
      let stateOfDays: boolean = true;

      weeksOfExcer!.map((day: dayType) => {
        if (
          stateOfDays &&
          !day.isRest &&
          (!day.circuits || day.circuits.length === 0)
        ) {
          const message = `روز ${Numbring[day.dayIndex! - 1]} از هفته ${
            Numbring[day.weekIndex! - 1]
          } فاقد تمرین است `;
          toast.error(message);
          stateOfDays = false;
        }
      });
      if (!stateOfDays) return false;
    }
    // if (!stateOfDays) return false;

    const values = getValues();

    const PlanDataForSave: planType = {
      ...values,

      logo: null,
      tags: !values.tags ? [] : values.tags,
      state: 1,
    };
    let savedPlan: any;
    if (values.id) savedPlan = await updatePlan(PlanDataForSave);
    else savedPlan = await savePlan(PlanDataForSave);
    // reset(savedPlan);
    toast.success("انتشار با موفقیت انجام پذیرفت");
    navigate("/plans");
  };
  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full w-full flex flex-col items-center justify-start max-sm:mt-3 max-2xl:text-xs"
      >
        <ul className="mt-2 h-[10%] w-full sm:w-[60%] steps text-xs">
          <li
            className={`step cursor-pointer ${
              currentStep >= 1 && " step-primary"
            }`}
            onClick={() => setCurrentStep(1)}
          >
            اطلاعات کلی
          </li>
          <li
            className={`step ${currentStep >= 2 && " step-primary"} ${
              currentStep === 3 && "cursor-pointer"
            } `}
            onClick={() => currentStep === 3 && setCurrentStep(2)}
          >
            اطلاعات تخصصی
          </li>
          <li className={`step ${currentStep === 3 && " step-primary"}`}>
            ساخت برنامه
          </li>
        </ul>
        <div
          className={`w-full h-[84%] flex flex-col self-start justify-start items-start  border-t-2 py-3 px-4 gap-y-3 max-2xl:text-xs `}
        >
          {currentStep === 1 && (
            <>
              <div className=" ">تصویر کاور برنامه</div>
              <div
                data-tip={errors.logo && errors.logo.message}
                className={`${
                  touchedFields.logo && errors.logo && "tooltip tooltip-left"
                }`}
              >
                <div
                  className={`h-44 max-h-44 2xl:h-72  w-[138px]  relative ${
                    errors.logo && "border rounded-xl border-red-600"
                  } `}
                >
                  {logoFile || planId ? (
                    <img
                      className="object-fill h-full w-full rounded-xl"
                      // src={logoFile}
                      src={
                        logoFile
                          ? logoFile
                          : `${moviesUrl}/plan/${planId}/poster.png`
                      }
                      alt="logo"
                    />
                  ) : (
                    <PlanCoverSVG className="object-fill h-full w-full rounded-xl" />
                  )}

                  <i
                    data-tip={`انتخاب تصویر`}
                    className="cursor-pointer absolute bottom-2 right-2 tooltip tooltip-left"
                    onClick={(e) => {
                      e.preventDefault();
                      logoRef.current!.click();
                    }}
                  >
                    <SwapSVG className="w-3" />
                  </i>
                </div>

                <input
                  multiple={false}
                  ref={logoRef}
                  className="hidden"
                  type="file"
                  accept="image/x-icon,image/jpeg,image/png"
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    const files = (e.target as HTMLInputElement).files;
                    if (files?.length && files?.length > 0) {
                      if (files[0].size > 5000000 || files[0].size < 1) {
                        setValue("logo", null, { shouldTouch: true });
                        setError("logo", {
                          type: "manual",
                          message: "حجم تصویر بیش از 5 مگابایت است",
                        });
                        setLogoFile(null);
                        return false;
                      }
                      setLogoFileChanged(true);
                      setLogoFile(URL.createObjectURL(files?.[0]));
                      setValue("logo", files?.[0], {
                        shouldTouch: true,
                        shouldValidate: true,
                      });
                      clearErrors(["logo"]);
                    }
                  }}
                />
              </div>
              <div className="w-full flex ">
                <label
                  data-tip={errors.planName && errors.planName.message}
                  className={`form-control w-full  ${
                    errors.planName && "tooltip tooltip-bottom"
                  }`}
                >
                  <div className="label justify-start ">
                    <span className="">نام برنامه</span>
                  </div>
                  <input
                    tabIndex={0}
                    type="text"
                    {...rest}
                    ref={planNameRef}
                    maxLength={40}
                    placeholder=""
                    className={`input max-2xl:text-xs h-8 input-bordered w-full  ${
                      errors.planName && "border-1 border-red-600"
                    }`}
                  />
                </label>
              </div>
              <div className="w-full flex">
                <label
                  data-tip={
                    errors.planDescription && errors.planDescription.message
                  }
                  className={`form-control w-full  ${
                    errors.planDescription && "tooltip tooltip-bottom"
                  }`}
                >
                  <div className="label justify-start">
                    <span className="">توضیحات</span>
                  </div>
                  <textarea
                    tabIndex={1}
                    {...register(
                      "planDescription",
                      registerOptions.planDescription
                    )}
                    rows={3}
                    cols={10}
                    maxLength={300}
                    placeholder="توضیحات خود را وارد نمایید"
                    className={`input max-2xl:text-xs input-bordered w-full h-16 2xl:h-20  ${
                      errors.planDescription && "border-1 border-red-600"
                    }`}
                  />
                </label>
              </div>
              <div
                tabIndex={2}
                className="hidden sm:flex w-full justify-start text-xs border-1 border-red-600"
              >
                <label className={`form-control w-full  `}>
                  <div className={"label justify-start "}>
                    <span className={`${errors.tags && "text-red-400"}`}>
                      تگ
                    </span>
                  </div>
                  <TagCtrl
                    placeholder="انتخاب یا ایجاد تگ"
                    Position="inline"
                    tags={tags}
                    mabnaTags={localPlanTags}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    handleTagClick={handleTagClick}
                    autocomplete={true}
                  />
                </label>
              </div>
              <div className={`sm:hidden w-full flex justify-start mt-3 `}>
                <TagCtrl
                  placeholder="انتخاب یا ایجاد تگ"
                  Position="top"
                  tags={tags}
                  mabnaTags={localPlanTags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  autocomplete={true}
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className="flex flex-col w-full h-20 gap-y-3  max-sm:text-xs 2xl:mt-3">
                <div>مناسب برای:</div>
                <div className="flex items-center flex-wrap  ">
                  {professionalInfo.gender.data.map((item: forType, idex) => (
                    <div key={idex} className={`flex items-center  `}>
                      <input
                        {...register("gender", {
                          setValueAs: (v) => v.toString(), // Convert string to boolean
                        })}
                        type="radio"
                        value={item.id}
                        id={item.id.toString()}
                        // checked={
                        //   item.id ===
                        //   parseInt(getValues("gender")?.toString() )
                        // }
                        className={`radio radio-sm sm:radio-md	ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary  ${
                          errors.gender && "border border-red-600"
                        } `}
                      />{" "}
                      <span className="">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col w-full h-20 gap-y-3 max-sm:text-xs 2xl:mt-3">
                <div>مناسب برای شرایط وزنی:</div>
                <div className="flex items-center">
                  {professionalInfo.weight.data.map((item: forType, idex) => (
                    <div key={idex} className="flex items-center">
                      <input
                        type="radio"
                        {...register("weight", {
                          setValueAs: (v) => v.toString(), // Convert string to boolean
                        })}
                        value={item.id}
                        id={item.id.toString()}
                        // checked={
                        //   item.id ===
                        //   parseInt(getValues("weight")?.toString() || "1")
                        // }
                        className={`radio radio-sm sm:radio-md ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary ${
                          errors.weight && "border border-red-600"
                        }`}
                      />{" "}
                      <span className="">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col w-full h-20 gap-y-3 max-sm:text-xs 2xl:mt-3">
                <div>مناسب برای :</div>
                <div className="flex items-center">
                  {professionalInfo.place.data.map((item: forType, idex) => (
                    <div key={idex} className="flex items-center">
                      <input
                        type="radio"
                        {...register("place", {
                          setValueAs: (v) => v.toString(), // Convert string to boolean
                        })}
                        value={item.id}
                        id={item.id.toString()}
                        // checked={
                        //   item.id ===
                        //   parseInt(getValues("place")?.toString() || "1")
                        // }
                        className={`radio radio-sm sm:radio-md ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary ${
                          errors.place && "border border-red-600"
                        }`}
                      />{" "}
                      <span className="">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col w-full h-20 gap-y-3 max-sm:text-xs 2xl:mt-3">
                <div>سطح برنامه :</div>
                <div className="flex items-center">
                  {professionalInfo.level.data.map((item: forType, idex) => (
                    <div key={idex} className="flex items-center">
                      <input
                        type="radio"
                        {...register("level", {
                          setValueAs: (v) => v.toString(), // Convert string to boolean
                        })}
                        value={item.id}
                        id={item.id.toString()}
                        // checked={
                        //   item.id ===
                        //   parseInt(getValues("level")?.toString() || "1")
                        // }
                        className={`radio radio-sm sm:radio-md ml-1 sm:ml-2 mr-2 sm:mr-5 radio-primary ${
                          errors.level && "border border-red-600"
                        }`}
                      />{" "}
                      <span className="">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-40 sm:w-52 flex max-sm:text-xs text-sm 2xl:mt-3">
                <label
                  data-tip={errors.planTime && errors.planTime.message}
                  className={`form-control w-full  ${
                    errors.planTime && "tooltip tooltip-bottom"
                  }`}
                >
                  <div className="label justify-start ">
                    <span className="">مدت برنامه</span>
                  </div>
                  <div className="flex items-center gap-x-3 ">
                    <input
                      tabIndex={0}
                      type="text"
                      maxLength={2}
                      onKeyDown={(e: any) => {
                        return onlyDigits(e);
                      }}
                      {...register("planTime", {
                        valueAsNumber: true,
                      })}
                      placeholder="تعداد هفته"
                      className={`input input-sm input-bordered w-full text-center ltr max-sm:text-xs text-sm ${
                        errors.planTime && "border-1 border-red-600"
                      }`}
                    />
                    {/* <input
                      tabIndex={0}
                      type="number"
                      {...register("planTime")}
                      placeholder="تعداد هفته"
                      className={`input input-sm input-bordered w-full text-center ltr max-sm:text-xs text-sm ${
                        errors.planTime && "border-1 border-red-600"
                      }`}
                    /> */}
                    هفته
                  </div>
                </label>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <div className="flex w-full h-full gap-x-1">
              <WeekDaysMobile setter={setShowWeekDays} />

              <WeekDays
                show={showWeekDays}
                setter={setShowWeekDays}
                selectedWeek={selectedWeek}
                setShowUpdateModal={(state: boolean) =>
                  setShowUpdateModal(state)
                }
                setSelectedWeek={(sWeek: number) => setSelectedWeek(sWeek)}
                selectedDay={selectedDay}
                setSelectedDay={(sDay: number) => setSelectedDay(sDay)}
                setSelectedWeekTemp={(sWeek: number) =>
                  setSelectedWeekTemp(sWeek)
                }
                setSelectedDayTemp={(sDay: number) => setSelectedDayTemp(sDay)}
              />
              <div className="h-full flex flex-col w-full md:w-5/6 ">
                {selectedWeek >= 0 && (
                  <DayComponent
                    currentWeek={selectedWeek}
                    currentDay={selectedDay}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-full fixed bottom-0 left-0 flex justify-end max-md:mt-4  gap-x-3  pl-3 pb-1  dark:bg-white">
          <button className="btn-1  border-[#7367F0] text-color1  min-w-24  ">
            {isSubmitting && (
              <span
                className={`
         loading loading-spinner loading-xs`}
              ></span>
            )}
            <div className={`${isSubmitting && "hidden"}`}>
              <GiSaveArrow />
            </div>
            ذخیره برنامه
          </button>
          {currentStep < 3 && (
            <div
              onClick={() => {
                !stepValidation(currentStep) &&
                  isValid &&
                  setCurrentStep(currentStep + 1);
              }}
              className="btn-1 text-white  bg-color1"
            >
              ادامه
              <GrFormPrevious />
            </div>
          )}
          {currentStep === 3 && (
            <div onClick={publishPlan} className="btn-1 text-white bg-color1">
              انتشار برنامه
            </div>
          )}
        </div>
      </form>
      <ConfirmModal
        acceptMessage="ذخیره شود"
        rejectMessage="منصرف شدم"
        title="تغییرات روز ذخیره نشده است."
        desc="آیا میخواهید تغییرات را ذخیره نمایید؟"
        showModal={showUpdateModal}
        acceptBtnClick={acceptUpdateBtnClick}
        rejectBtnClick={rejectUpdateBtnClick}
      >
        <GiSaveArrow className="w-3" />
      </ConfirmModal>
    </FormProvider>
  );
};

export default Plan;
