import http from "services/authService";

const appUrl = process.env.REACT_APP_URL;

export const signUp = (data: {
  firstName: string;
  lastName: string;
  userId: string;
}) => {
  return http.post(`${appUrl}/User/coach/signup`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const forgetPassword = (data: { userId: string }) => {
  return http.post(`${appUrl}/User/forget-password`, null, {
    params: { userId: data.userId },
  });
};

export const verify = (data: { userId: string; token: string }) => {
  return http.post(`${appUrl}/User/Verify`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const retryVerification = (data: any) => {
  return http.post(`${appUrl}/User/retry-verification`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const setPassword = (data: {
  userId: string;
  token: string;
  password: string;
  repeatPassword: string;
}) => {
  return http.post(`${appUrl}/User/set-password`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const reSetPassword = (data: {
  userId: string;
  resetToken: string;
  password: string;
  repeatPassword: string;
}) => {
  return http.post(`${appUrl}/User/reset-password`, data, {
    headers: { "Content-Type": "application/json" },
  });
};
