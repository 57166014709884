import { RiDeleteBinLine } from "react-icons/ri";
import CloseSVG from "components/svg/closeSvg";
import Movements from "pages/movements";
import React from "react";

interface Props {
  showModal: boolean;
  children: React.ReactNode;
  exitBtnClick: () => void;
  selectExerciseBtnClick: (movementId:string,movementType:number) => void;
}

const MovementsModal = ({ showModal, children, exitBtnClick,selectExerciseBtnClick }: Props) => {
  return (
    <dialog
      id="my_modal_5"
      className="modal modal-bottom sm:modal-middle"
      open={showModal}
    >
      <div className="modal-box !max-w-full !h-full flex flex-col justify-start items-start bg-[#F8F7FA]">
        <div className="w-4 h-4 cursor-pointer text-xl mb-3" onClick={exitBtnClick}>
          <CloseSVG className="w-full h-full" fill="black"></CloseSVG>
        </div>
        <Movements isModal={true} selectMovement={selectExerciseBtnClick}></Movements>
      </div>
    </dialog>
  );
};

export default MovementsModal;
