import { useEffect, useRef, useState } from "react";

export function UseLayout() {
  const [dimension, setDimension] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height:
      window.innerWidth > 768
        ? window.innerHeight - 150
        : window.innerHeight - 150,
  });

  useEffect(() => {
    function handleResize() {
      setDimension({
        width: window.innerWidth,
        height:
          window.innerWidth > 768
            ? window.innerHeight - 150
            : window.innerHeight - 150,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { width: dimension.width, height: dimension.height };
}