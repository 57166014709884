import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import PreDefinedQuestions from "components/forms/preDefinedQuestions";
import { onlyLettersAndNumbers } from "components/helper/publicLib";
import DefinedQuestions from "components/forms/definedQuestions";
import PreviewQuestions from "components/forms/previewQuestions";
import PublishedSVG from "components/svg/publishedSvg";
import React, { useEffect, useState } from "react";
import { GrFormPrevious } from "react-icons/gr";
import { GiSaveArrow } from "react-icons/gi";
import EditSVG from "components/svg/editSvg";
import { elementaryFormType } from "types";
import { toast } from "sonner";

const initialValues: elementaryFormType = {
  id: undefined,
  formName: "",
  formDescription: "",
  state: 1,
  questions: [
    {
      id: "1",
      text: "وزن شما چقدر است؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "2",
      text: "سن شما چقدر است؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "3",
      text: "قد شما چقدر است؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "4",
      text: "آیا سابقه ورزشی دارید؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "5",
      text: "آیا سابقه بیماری یا مصدومیت دارید؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "6",
      text: "چنانچه داروی خاصی مصرف می‌کنید، نام آن را بنویسید.",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "7",
      text: "هدف شما از انجام تمرینات چیست؟",
      isChecked: true,
      isNeeded: true,
      isCustom: false,
    },
    {
      id: "8",
      text: "عرض شانه شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "9",
      text: "دورسینه شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "10",
      text: "دور شکم شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "11",
      text: "دور کمر شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "12",
      text: "دور باسن شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "13",
      text: "دور ران شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
    {
      id: "14",
      text: "دور بازوی شما چقدر است؟",
      isChecked: true,
      isNeeded: false,
      isCustom: false,
    },
  ],
};

const Elementary = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  const form = useForm<elementaryFormType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    trigger,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      isLoading,
    },
  } = form;

  const onSubmit: SubmitHandler<elementaryFormType> = async (data) => {
    try {
      if (isSubmitting) return;

      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          resolve({ ...data, type: 2 });
        }, 5000);
      });
    } catch (error) {
      toast.error("خطا در ثبت اطلاعات");
    }
  };

  const registerOptions = {
    formName: {
      required: "نام فرم را وارد نمایید",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "نام فرم معتبر نیست",
    },
    formDescription: {
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "توضیحات معتبر نیست",
    },
  };

  const stepValidation = (CurrentStep: number): boolean => {
    if (CurrentStep === 1) trigger();
    return true;
  };
  useEffect(() => {
    async function fetchAPI() {
      try {
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  const publishPlan = () => {
    if (isValid) {
      setValue("state", 2);
      trigger();
    }
  };
  return (
    <FormProvider {...form}>
      {getValues("state") === 2 ? (
        <div className="flex  sm:flex-col h-28 w-[95%] gap-y-4 self-start mt-6 ">
          <div className="flex max-sm:flex-col justify-around items-center sm:text-sm  sm:h-12 max-sm:h-64 max-sm:w-1/4">
            <div>نام فرم</div>
            <div>تـاریخ</div>
            <div>وضـعیـت</div>
            <div></div>
          </div>
          <div className="flex max-sm:flex-col justify-around items-center sm:text-xs bg-white sm:h-12 max-sm:h-72 max-sm:w-3/4">
            <div>فـرم ابتـدایـی</div>
            <div>1403/02/24</div>
            <div className="text-lime-500 flex ">
              <div>منتشر شده</div>
              <PublishedSVG className="w-4 text-lime-600" />
            </div>
            <div>
              {" "}
              <EditSVG className=" w-4 dark:text-white" />
            </div>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full h-full flex flex-col justify-start items-start px-3  sm:py-2 gap-y-3 text-sm "
        >
          <ul className="mt-2 h-[10%] w-full sm:w-[70%] steps text-2xs sm:text-xs self-center">
            <li
              className={`step cursor-pointer ${
                currentStep >= 1 && " step-primary"
              }`}
              onClick={() => setCurrentStep(1)}
            >
              اطلاعات
            </li>
            <li
              className={`step ${currentStep >= 2 && " step-primary"} ${
                currentStep === 3 && "cursor-pointer"
              } `}
              onClick={() => currentStep === 3 && setCurrentStep(2)}
            >
              سوالات پیش فرض
            </li>
            <li
              className={`step ${currentStep >= 3 && " step-primary"} ${
                currentStep === 4 && "cursor-pointer"
              } `}
              onClick={() => currentStep === 4 && setCurrentStep(3)}
            >
              سوالات اختصاصی
            </li>
            <li className={`step ${currentStep === 4 && " step-primary"}`}>
              مشاهده
            </li>
          </ul>
          <div className="w-full h-[84%] flex flex-col self-start justify-start items-start  border-t-2 py-3 px-4 gap-y-3 max-2xl:text-xs overflow-y-auto">
            {currentStep === 1 && (
              <>
                <div className=" w-full md:w-1/2 flex px-2 ">
                  <label
                    data-tip={errors.formName && errors.formName.message}
                    className={`form-control w-full  ${
                      errors.formName && "tooltip tooltip-bottom"
                    }`}
                  >
                    <div className="label justify-start ">
                      <span>نام فرم</span>
                    </div>
                    <input
                      tabIndex={0}
                      type="text"
                      {...register("formName", registerOptions.formName)}
                      className={`input max-2xl:text-xs 2xl:text-sm h-8 input-bordered w-full  ${
                        errors.formName && "border border-red-600"
                      }`}
                    />
                  </label>
                </div>
                <div className="w-full md:w-1/2  flex px-2">
                  <label
                    data-tip={
                      errors.formDescription && errors.formDescription.message
                    }
                    className={`form-control w-full  ${
                      errors.formDescription && "tooltip tooltip-bottom"
                    }`}
                  >
                    <div className="label justify-start">
                      <span>توضیحات</span>
                    </div>
                    <textarea
                      tabIndex={1}
                      {...register(
                        "formDescription",
                        registerOptions.formDescription
                      )}
                      rows={3}
                      cols={10}
                      placeholder="توضیحات خود را وارد نمایید"
                      className={`input max-2xl:text-xs 2xl:text-sm input-bordered w-full h-16 2xl:h-20  ${
                        errors.formDescription &&
                        touchedFields.formDescription &&
                        "border border-red-600"
                      }`}
                    />
                  </label>
                </div>
              </>
            )}
            {currentStep === 2 && <PreDefinedQuestions />}
            {currentStep === 3 && <DefinedQuestions />}
            {currentStep === 4 && <PreviewQuestions />}
          </div>

          <div className="w-full fixed bottom-0 left-0 flex justify-end max-md:mt-4  gap-x-3  pl-3 pb-1  dark:bg-white">
            <button className="btn-1  border-[#7367F0] text-color1  min-w-24  ">
              {isSubmitting && (
                <span
                  className={`
         loading loading-spinner loading-xs`}
                ></span>
              )}
              <div className={`${isSubmitting && "hidden"}`}>
                <GiSaveArrow />
              </div>
              ذخیره
            </button>
            {currentStep < 4 && (
              <div
                onClick={() => {
                  stepValidation(currentStep) &&
                    isValid &&
                    setCurrentStep(currentStep + 1);
                }}
                className="btn-1 text-white  bg-color1"
              >
                ادامه
                <GrFormPrevious />
              </div>
            )}
            {currentStep === 4 && (
              <div onClick={publishPlan} className="btn-1 text-white bg-color1">
                انتشار
              </div>
            )}
          </div>
        </form>
      )}
    </FormProvider>
  );
};

export default Elementary;
