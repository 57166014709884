import axios from "axios";
import { Change_REACT_APP_URL } from "components/helper/publicLib";

// const BACKEND_URL = process.env.REACT_APP_AUTH_URL;
// const FRONT_URL = process.env.REACT_APP_FRONT_URL;
const appUrl = process.env.REACT_APP_URL;

export const getToken = () => {
  return sessionStorage.getItem("accessToken");
};

export const signOut = () => {
  sessionStorage.removeItem("authenticated"); //signOut
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  window.location.href = `${Change_REACT_APP_URL(window.location.href)}/login`;
};

export const saveTokens = async (data: {
  accessToken: string;
  refreshToken: string;
}) => {
  sessionStorage.setItem("accessToken", data.accessToken);
  sessionStorage.setItem("refreshToken", data.refreshToken);
};

export const loginCasdoor = async (sdk: any) => {
  // const loginState = await sdk.signin(BACKEND_URL!);
  // sessionStorage.setItem("accessToken", loginState.accessToken);
  // sessionStorage.setItem("refreshToken", loginState.refresh_token);
  // const data = await getUserInfo();
  // return data;
};

export const getUserInfo1 = async () => {
  // try {
  //   let accessToken = sessionStorage.getItem("accessToken");
  //   const refreshToken = sessionStorage.getItem("refreshToken");
  //   if (!accessToken || accessToken === "undefined") {
  //     return;
  //   } else {
  //     const userInfo = await axios.get(
  //       `${BACKEND_URL}/api/getUserInfo?accessToken=${accessToken}`
  //     );
  //     // if (userInfo.status && userInfo.status === 200) return userInfo.data;
  //     if (userInfo.status && userInfo.status === 200) {
  //       if (userInfo.data && userInfo.data.state === 200) return userInfo.data;
  //       else if (userInfo.data && userInfo.data.state === 401) {
  //         const newToken = await axios.post(`${BACKEND_URL}/api/refreshToken`, {
  //           refreshToken: refreshToken,
  //         });
  //         if (
  //           newToken.status &&
  //           newToken.status === 200 &&
  //           newToken.data &&
  //           newToken.data.state === 200
  //         ) {
  //           sessionStorage.setItem("accessToken", newToken.data.accessToken);
  //           sessionStorage.setItem("refreshToken", newToken.data.refresh_token);
  //           getUserInfo();
  //         } else signOut();
  //       }
  //     }
  //   }
  // } catch (error) {
  //   throw error;
  // }
};

axios.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response?.status || 500;
    const originalRequest = error.config;
    switch (status) {
      case 401: {
        originalRequest._retry = true;
        const refreshToken = sessionStorage.getItem("refreshToken");
        const accessToken = sessionStorage.getItem("accessToken");
        return axios
          .post(`${appUrl}/User/refresh`, {
            refreshToken: refreshToken,
            accessToken: accessToken,
          })
          .then((res) => {
            if (
              res.status &&
              res.status === 200 &&
              res.data &&
              res.data.success
            ) {
              sessionStorage.setItem("accessToken", res.data.data.accessToken);
              sessionStorage.setItem(
                "refreshToken",
                res.data.data.refreshToken
              );
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + sessionStorage.getItem("accessToken");
              return axios(originalRequest);
            } else signOut();
          });
      }
      case 403:
        signOut();
        break;
      default:
        return Promise.reject(error);
    }
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
