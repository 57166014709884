import http from "services/authService";

const appUrl = process.env.REACT_APP_URL;
const mediaUrl = process.env.REACT_APP_URL + "/Media";

export const getAllPlans = () => {
  return http.post(`${appUrl}/Plan/getallplansinfo`, {
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const getPlan = (id: string) => {
  return http.post(`${appUrl}/Plan/getplandatabyid`, {
    planId: id,
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const savePlan = (data: any) => {
  return http.post(`${appUrl}/Plan/addplan`, {
    plan: { ...data },
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const updatePlan = (data: any) => {
  return http.post(`${appUrl}/Plan/updateplan`, {
    plan: { ...data },
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const publishPlanData = (data: any) => {
  return http.post(`${appUrl}/Plan/PublishPlan`, data);
};

export const deletePlan = (id: string) => {
  return http.delete(`${appUrl}/Plan/remove-plan`, {
    data: {
      planId: id,
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
  });
};

export const duplicatePlan = (id: string) => {
  return http.post(`${appUrl}/Plan/duplicate-plan`, {
    planId: id,
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};


export const uploadMedia = (data: {
  File: File;
  IsPlanPoster: boolean;
  MovementId: string;
  PlanId:string;
}) => {
  return http.post(`${mediaUrl}/upload-poster`, data, {
    headers: {
      // "x-api-version": "2.0",
      accept: "text/plain",
      "Content-Type": "multipart/form-data",
    },
  });
};