import React, {
  MouseEvent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { deletePackage, getPackages } from "services/packageService";
import { toast } from "sonner";
import { packageType } from "types";
import packagesMockData from "../components/packages.json";
import FilterSVG from "components/svg/filterSvg";
import CloseSVG from "components/svg/closeSvg";
import { SubmitHandler, useForm } from "react-hook-form";
import SearchSVG from "components/svg/searchSvg";
import LoadingMovements from "./loadingMovements";
import EditSVG from "components/svg/editSvg";
import DeleteSVG from "components/svg/deleteSvg";
import ConfirmModal from "components/modals/confirmModal";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  packageDurationType,
  thousandSeparator,
  toShamsi,
} from "components/helper/publicLib";
import { FixedSizeList } from "react-window";
import { UseLayout } from "components/helper/useLayout";

interface Search {
  searchTxt: string;
}

const Packages = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [firteredData, setFirteredData] = useState<packageType[]>([]);
  const [packagesData, setPackagesData] = useState<packageType[]>([]);
  const [selectedPackageId, setSelectedPackageId] = useState<
    string | undefined
  >("0");
  const [showModal, setShowModal] = useState<boolean>(false);

  const [dimension, setDimension] = useState<{
    width: number;
    height: number;
  }>();

  const { width, height } = UseLayout();
  // const control1Ref = useRef(null);

  // const { isDarkMode } = useContext(MyContext);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Search> = async (inputSearch: Search) => {
    const localPA = packagesData.filter(
      (item: packageType) =>
        item.name.includes(inputSearch.searchTxt) ||
        (
          item.duration!.toString() +
          " " +
          packageDurationType(item.durationType)
        ).includes(inputSearch.searchTxt)
    );
    setFirteredData(localPA);
  };

  const { register, handleSubmit } = useForm<Search>({
    mode: "onSubmit",
    defaultValues: { searchTxt: "" },
  });

  const getpackagesData = async () => {
    try {
      const { data: packagesDataFromApi } = await getPackages();
      if (packagesDataFromApi.result && packagesDataFromApi.result.length > 0) {
        setPackagesData(packagesDataFromApi.result);
        setFirteredData(packagesDataFromApi.result);
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      throw error;
    }
  };

  // const resizeControl = (sourceControl: any) => {
  //   if (sourceControl) {
  //     const { offsetWidth, offsetHeight } = sourceControl;
  //     setDimension({ height: offsetHeight, width: offsetWidth });
  //   }
  // };

  // useEffect(() => {
  //   resizeControl(control1Ref.current);
  // }, [control1Ref.current && (control1Ref.current as any).offsetWidth]);

  useEffect(() => {
    async function fetchAPI() {
      try {
        await getpackagesData();
      } catch (error) {
        toast.error("خطا در فراخوانی اطلاعات");
      }
    }
    fetchAPI();
    // function handleResize() {
    //   resizeControl(control1Ref.current);
    // }
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const acceptBtnClick = async (e: MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      // await removePackage(selectedPackageId!);
      await deletePackage(selectedPackageId!);
      const packagesDataLocal = packagesData.filter(
        (item: packageType) => item.id !== selectedPackageId
      );
      setPackagesData(packagesDataLocal);
      setFirteredData(packagesDataLocal);
      setShowModal(false);
      toast.success("عملیات با موفقیت انجام پذیرفت");
    } catch (error) {
      toast.error("خطا در  عملیات");
    }
    setIsLoadingData(false);
  };
  const rejectBtnClick = () => {
    setShowModal(false);
  };
  const rowStyles: string =
    "w-full max-md:border-t  max-md:h-11 max-md:flex max-md:items-center max-md:justify-start max-md:gap-x-2";

  const colStyles: string =
    "md:hidden h-full bg-slate-300 flex items-center w-32 justify-center";

  const Row = ({ index, style }: any) => (
    <div
      className=" md:bg-white max-md:h-60  border-b-2 text-center text-xs  h-12 flex flex-col   md:flex-row  items-center"
      style={style}
    >
      <div className={`md:w-[22%]  ${rowStyles}`}>
        <div className={`${colStyles}`}>نام پکیج</div>
        <div>{firteredData[index].name}</div>
      </div>
      <div className={`md:w-[12%] ${rowStyles}`}>
        <div className={`${colStyles}`}>تاریخ</div>
        {toShamsi(firteredData[index].createDatetime)}
      </div>
      <div className={`md:w-[12%] ${rowStyles}`}>
        <div className={`${colStyles}`}>مدت پکیج</div>
        <div>
          {firteredData[index].duration}
          &nbsp;
          {packageDurationType(firteredData[index].durationType)}
        </div>
      </div>
      <div className={`md:w-[22%] ${rowStyles}`}>
        <div className={`${colStyles}`}>قیمت پکیج</div>
        <div>
          {thousandSeparator(firteredData[index].price?.toString()!)}
          &nbsp; تومان
        </div>
      </div>
      <div className={`md:w-[22%] ${rowStyles}`}>
        <div className={`${colStyles}`}>افزودن به سایت</div>
        <div className="h-full w-[calc(100%-9rem)] md:w-full flex items-center md:justify-center">
          <input
            type="checkbox"
            checked={firteredData[index].addToSite || false}
            className="checkbox checkbox-primary checkbox-xs"
          />
        </div>
      </div>
      {/* <div className="w-[10%] cursor-pointer ">
        <div className="w-full h-full flex items-center justify-center">
          <CopySVG className=" w-4 " fill={`${isDarkMode && "white"}`} />
        </div>
      </div> */}
      <div className={`md:w-[10%] ${rowStyles}`}>
        <div className={`${colStyles}`}></div>
        <div className="h-full w-[calc(100%-9rem)] md:w-full flex max-md:justify-center items-center gap-x-5">
          <button
            className="cursor-pointer tooltip tooltip-right"
            data-tip="ویرایش"
            onClick={() => {
              navigate(`/package/${firteredData[index].id}`);
            }}
          >
            <EditSVG className="w-4 text-black h-4" />
          </button>
          <button
            data-tip="حذف"
            className="cursor-pointer tooltip tooltip-right"
            onClick={() => {
              setSelectedPackageId(firteredData[index].id!);
              setShowModal(true);
            }}
          >
            <DeleteSVG className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!isLoadingData && packagesData.length === 0 && (
        <div className="h-full w-full flex justify-center items-center">
          <div className="flex flex-col items-center gap-y-3">
            <div>لیست شما خالی است.</div>

            <div
              className="btn-1 !h-9 text-white  bg-color1 mt-2"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navigate("/package");
              }}
            >
              <div className="mx-2 text-xl dark:text-white">+ </div>
              اضافه کردن پکیج
            </div>
          </div>
        </div>
      )}
      {!isLoadingData && packagesData.length > 0 && (
        <div
          className={`w-full h-full flex flex-col justify-start items-center  text-xs`}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col-reverse md:flex-row  justify-between h-18 md:h-10 w-[98%] mt-5 md:mt-1  gap-y-2 "
          >
            <div
              className="btn-1 !h-9 text-white  bg-color1 mt-2"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navigate("/package");
              }}
            >
              <div className="mx-2 text-xl dark:text-white">+ </div>
              اضافه کردن پکیج
            </div>

            <div
              className={`h-8 w-full md:w-72 flex relative justify-start items-center gap-x-2 mt-2 ml-2 
                              `}
            >
              <div className="w-4 relative cursor-pointer">
                {!showFilter && (
                  <FilterSVG
                    // fill={
                    //   originalTags.filter((item) => item.checked).length !== 0
                    //     ? "blue"
                    //     : "black"
                    // }
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className="w-full "
                  />
                )}
                {showFilter && (
                  <CloseSVG
                    fill={`black`}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                )}

                {showFilter && (
                  <div className="absolute mt-4">
                    {/* <Filter
                      data={originalTags}
                      setChecked={setfilterChecked}
                      title="حرکت انتخاب شده حذف شود؟"
                      desc="در صورت پاک کردن این حرکت تمام محتوای آن حذف خواهد شد."
                      showModal={showFilter}
                      filterDone={filterData}
                      filterClear={filterClear}
                    /> */}
                  </div>
                )}
              </div>

              <div className=" h-full w-full flex relative justify-center items-center">
                <input
                  autoFocus
                  {...register("searchTxt")}
                  type="text"
                  className="w-full border h-full shadow py-2 px-6 rounded-lg "
                  placeholder="جستجو"
                />
                <button type="submit" className="absolute left-5">
                  <SearchSVG className="text-black  w-5  top-3.5 right-3 fill-current" />
                </button>
              </div>
            </div>
          </form>
          <div className="w-[97%] flex flex-col gap-y-2 items-center mt-4 mb-3 pt-2 h-[calc(100vh-4.8rem)] md:h-[calc(100vh-2.8rem)]">
            {isLoadingData ? (
              <LoadingMovements />
            ) : !packagesData ? (
              <div className="h-10 w-full mx-5 ">
                <div>لیست شما خالی است.</div>
                {/* <a className="btn-1 text-white  bg-color1" href="/#firteredData[index]">
                  <span className="mx-2 text-xl">+ </span>
                  اضافه کردن حرکت
                </a> */}
              </div>
            ) : (
              <div className="w-[97%] mx-2 ">
                <div className="w-full h-10 max-md:hidden">
                  <div className="w-full h-10 text-center flex">
                    <div className="w-[22%] ">نام پکیج</div>
                    <div className="w-[12%]">تاریخ</div>
                    <div className="w-[12%]">مدت پکیج</div>
                    <div className="w-[22%]">قیمت پکیج</div>
                    <div className="w-[22%]">افزودن به وبسایت</div>
                    {/* <div className="w-[10%]">دریافت لینک</div> */}
                    <div className="w-[10%]"></div>
                  </div>
                </div>
                <div className="h-full overflow-y-auto ">
                  <FixedSizeList
                    direction="rtl"
                    height={height}
                    itemCount={firteredData.length}
                    itemSize={width > 768 ? 45 : 270}
                    width={"100%"}
                  >
                    {Row}
                  </FixedSizeList>
                </div>
              </div>
            )}

            <ConfirmModal
              acceptMessage="حذف شود"
              rejectMessage="منصرف شدم"
              title="پکیج انتخاب شده حذف شود؟"
              desc="در صورت پاک کردن این پکیج تمام محتوای آن حذف خواهد شد."
              showModal={showModal}
              acceptBtnClick={acceptBtnClick}
              rejectBtnClick={rejectBtnClick}
            >
              <RiDeleteBinLine className="w-3" />
            </ConfirmModal>
          </div>
        </div>
      )}
    </>
  );
};

export default Packages;
