import * as React from "react";
import { SVGProps } from "react";

function UploadSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 512 512" >
     <g>
	<g>
		<g>
			<path d="M271.087,208.915c-0.004-0.004-0.008-0.006-0.011-0.01c-0.494-0.493-1.012-0.96-1.552-1.403
				c-0.247-0.203-0.507-0.379-0.761-0.569c-0.303-0.227-0.6-0.462-0.915-0.673c-0.304-0.204-0.62-0.38-0.932-0.566
				c-0.285-0.17-0.564-0.349-0.857-0.506c-0.318-0.17-0.646-0.315-0.971-0.468c-0.306-0.145-0.607-0.297-0.921-0.428
				c-0.315-0.13-0.637-0.236-0.957-0.35c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.646-0.168-0.969-0.249
				c-0.351-0.089-0.698-0.187-1.055-0.258c-0.375-0.074-0.753-0.118-1.13-0.173c-0.311-0.044-0.617-0.104-0.933-0.135
				c-1.4-0.138-2.811-0.138-4.211,0c-0.315,0.031-0.621,0.09-0.933,0.135c-0.377,0.054-0.756,0.098-1.13,0.173
				c-0.358,0.071-0.704,0.169-1.055,0.258c-0.324,0.081-0.649,0.152-0.969,0.249c-0.344,0.104-0.677,0.233-1.013,0.354
				c-0.32,0.115-0.642,0.22-0.957,0.35c-0.314,0.13-0.615,0.283-0.921,0.428c-0.325,0.153-0.653,0.297-0.971,0.468
				c-0.293,0.157-0.572,0.336-0.857,0.506c-0.312,0.186-0.628,0.363-0.932,0.566c-0.315,0.211-0.612,0.446-0.915,0.673
				c-0.254,0.19-0.514,0.366-0.761,0.569c-0.54,0.443-1.059,0.91-1.552,1.403c-0.004,0.004-0.008,0.006-0.011,0.01l-64,64
				c-8.331,8.331-8.331,21.839,0,30.17s21.839,8.331,30.17,0l27.582-27.582V480c0,11.782,9.551,21.333,21.333,21.333
				s21.333-9.551,21.333-21.333V275.503l27.582,27.582c8.331,8.331,21.839,8.331,30.17,0s8.331-21.839,0-30.17L271.087,208.915z"/>
			<path d="M446.853,164.762c-9.932-78.505-82.274-154.096-157.807-154.096c-44.338,0-87.774,21.612-116.824,55.447
				c-0.246-0.09-0.499-0.165-0.746-0.252c-0.981-0.348-1.964-0.688-2.958-1.001c-0.424-0.133-0.856-0.249-1.283-0.376
				c-0.878-0.261-1.758-0.518-2.645-0.751c-0.426-0.112-0.857-0.211-1.286-0.316c-0.915-0.225-1.832-0.44-2.755-0.636
				c-0.397-0.084-0.797-0.161-1.196-0.239c-0.992-0.195-1.989-0.373-2.99-0.534c-0.337-0.054-0.674-0.107-1.013-0.157
				c-1.129-0.167-2.262-0.31-3.401-0.432c-0.22-0.023-0.439-0.051-0.659-0.073c-2.833-0.282-5.69-0.441-8.569-0.441
				c-5.934,0-11.724,0.611-17.32,1.754c-0.088,0.018-0.174,0.04-0.262,0.059c-1.289,0.268-2.568,0.561-3.835,0.885
				c-0.2,0.051-0.396,0.111-0.596,0.164c-1.15,0.303-2.292,0.623-3.423,0.972c-0.275,0.085-0.546,0.181-0.82,0.268
				c-1.047,0.335-2.089,0.681-3.118,1.055c-0.325,0.118-0.644,0.247-0.967,0.368c-0.968,0.365-1.932,0.738-2.884,1.137
				c-0.358,0.15-0.71,0.31-1.065,0.465c-0.905,0.394-1.805,0.794-2.693,1.218c-0.38,0.181-0.754,0.372-1.13,0.558
				c-0.85,0.42-1.695,0.848-2.529,1.295c-0.395,0.212-0.784,0.433-1.175,0.651c-0.801,0.446-1.597,0.899-2.383,1.37
				c-0.403,0.241-0.799,0.491-1.198,0.739c-0.758,0.471-1.51,0.95-2.252,1.444c-0.406,0.27-0.806,0.547-1.207,0.824
				c-0.717,0.496-1.429,0.998-2.13,1.514c-0.404,0.298-0.804,0.602-1.203,0.906c-0.68,0.519-1.354,1.046-2.017,1.585
				c-0.399,0.324-0.792,0.652-1.185,0.982c-0.645,0.543-1.283,1.095-1.912,1.656c-0.39,0.348-0.775,0.7-1.159,1.055
				c-0.612,0.567-1.215,1.142-1.811,1.726c-0.378,0.371-0.753,0.745-1.125,1.123c-0.58,0.59-1.15,1.189-1.712,1.796
				c-0.364,0.392-0.725,0.786-1.082,1.185c-0.549,0.614-1.086,1.237-1.617,1.867c-0.347,0.411-0.693,0.824-1.033,1.242
				c-0.519,0.639-1.025,1.287-1.526,1.941c-0.327,0.427-0.654,0.854-0.974,1.287c-0.49,0.665-0.965,1.34-1.436,2.019
				c-0.306,0.441-0.613,0.881-0.911,1.329c-0.461,0.692-0.906,1.395-1.347,2.101c-0.282,0.451-0.566,0.9-0.84,1.357
				c-0.433,0.722-0.847,1.455-1.259,2.19c-0.256,0.458-0.517,0.912-0.765,1.375c-0.405,0.755-0.79,1.522-1.173,2.29
				c-0.228,0.458-0.462,0.913-0.683,1.376c-0.377,0.793-0.733,1.597-1.086,2.403c-0.199,0.454-0.406,0.904-0.597,1.362
				c-0.351,0.838-0.677,1.688-1.001,2.539c-0.167,0.439-0.343,0.873-0.504,1.315c-0.325,0.896-0.623,1.804-0.918,2.714
				c-0.133,0.41-0.277,0.815-0.404,1.228c-0.3,0.974-0.571,1.961-0.838,2.95c-0.097,0.358-0.205,0.712-0.297,1.072
				c-0.279,1.094-0.527,2.2-0.764,3.31c-0.056,0.264-0.124,0.524-0.178,0.789c-0.265,1.301-0.497,2.613-0.703,3.934
				c-0.012,0.079-0.029,0.157-0.041,0.237c-0.434,2.842-0.734,5.728-0.883,8.654C22.923,164.303,0,204.446,0,245.333
				c0,51.181,32.639,107.13,76.869,126.249l4.051,1.751H128c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333
				H90.149c-26.097-13.77-47.482-51.905-47.482-85.333c0-29.044,18.953-58.769,44.525-71.023l12.115-5.805v-13.434
				c0-0.349,0.02-0.856,0.069-1.711c0.063-1.063,0.063-1.063,0.087-1.471l0.007-2.337c-0.054-0.935-0.054-0.935-0.102-1.615
				c-0.049-19.771,12.178-36.079,29.198-41.984c4.434-1.528,9.187-2.37,14.139-2.37c6.704,0,13.052,1.521,18.72,4.232
				c0.124,0.06,0.247,0.123,0.371,0.184c0.425,0.208,0.844,0.424,1.261,0.646c0.217,0.116,0.434,0.232,0.65,0.352
				c0.314,0.174,0.624,0.355,0.933,0.536c0.569,0.334,1.131,0.681,1.686,1.042c0.131,0.085,0.265,0.167,0.395,0.253l0.399,0.265
				c11.468,7.81,19,20.965,19,35.882c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333
				c0-21.614-7.987-41.349-21.147-56.463c21.119-22.725,51.408-37.182,81.407-37.182c49.199,0,100.887,51.122,113.432,105.342
				c-5.923,0.105-11.871,0.508-17.732,1.311c-0.88,0.123-0.88,0.123-1.763,0.254c-11.649,1.765-19.661,12.64-17.896,24.289
				s12.64,19.661,24.289,17.896c0.578-0.086,0.578-0.086,1.16-0.167c8.911-1.22,18.862-1.18,28.185-0.349
				c1.979,0.176,3.457,0.345,4.339,0.461c29.21,5.668,46.274,29.3,46.274,64.297c0,31.137-18.507,56.637-45.318,64H384
				c-11.782,0-21.333,9.551-21.333,21.333s9.551,21.333,21.333,21.333h45.126l2.395-0.56C479.303,361.609,512,318.012,512,266.667
				C512,218.157,487.865,179.501,446.853,164.762z"/>
		</g>
	</g>
</g>
    </svg>
  );
}
export default UploadSVG;
