import {
  updateMovement,
  addMovement,
  getMovement,
  uploadMedia,
} from "services/movementService";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { onlyLettersAndNumbers } from "components/helper/publicLib";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import privateMovements from "components/movements.json";
import movementTags from "components/movementTags.json";
import UploadSVG from "components/svg/uploadSvg";
import { getTags } from "services/tagsService";
import VideoHover from "components/videoHover";
import { tagType, movementType } from "types";
import TagCtrl from "components/tag/tagCtrl";
import SwapSVG from "components/svg/swapSvg";
import ExitSVG from "components/svg/exitSvg";
import { Tag } from "react-tag-input";
import { toast } from "sonner";
import Player from "components/player.json";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;

const initialValues: movementType = {
  id: undefined,
  name: "",
  description: "",
  movie: null,
  tags: null,
  type: 1,
};

const Movement = () => {
  // const fileInput = useRef<any>(null);
  const [localMovementTags, setLocalMovementTags] = useState<string[]>(
    []
    // movementTags.map((tag) => tag.text)
  );
  const [tags, setTags] = useState<Tag[]>([]);
  const [originalTags, setOriginalTags] = useState<tagType[]>([]);
  const [movieFile, setMovieFile] = useState<any>();
  const [movieFileChanged, setMovieFileChanged] = useState<boolean>(false);
  // const [isPlaying, setIsPlaying] = useState<boolean>();
  // const { setMovementsData, movementsData } = useContext(MyContext);
  const { movementId } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: {
      errors,
      isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      isLoading,
    },
  } = useForm<movementType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const watchMovementType = watch("type");

  const nameRef = useRef<HTMLInputElement>(null);
  const { ref, ...rest } = register("name", {
    required: "نام حرکت اجباری است",
    validate: (value: string) =>
      onlyLettersAndNumbers(value) || "نام حرکت معتبر نیست",
  });
  useImperativeHandle(ref, () => nameRef.current);

  const movieRef = useRef<HTMLInputElement>(null);
  const movieref = register("movie");
  useImperativeHandle(movieref.ref, () => movieRef.current);

  const onSubmit: SubmitHandler<movementType> = async (data) => {
    try {
      if (isSubmitting) return;
      // const movie: string | ArrayBuffer | null | File =
      //   getValues("movie") || null;
      if (!movieFile) {
        setError("movie", {
          type: "manual",
          message: "انتخاب مدیا اجباری است",
        });
      } else {
        // let TagIds: any = data.tags?.map((tag) => {
        //   const find = originalTags.find(
        //     (oTag: tagType) => oTag.text === tag.id
        //   );
        //   if (find) return { id: find.id, text: find.text };
        // });
        // console.log(tagId);
        let retValue: any;
        let movieUrl: string = "";
        const { id, type, name, poster, description, movie } = data;

        if (!id) {
          const movementData = {
            movement: {
              id: "2a6db6e1-8967-4511-9839-a7cb3c895710",
              type: 0,
              name: name,
              description: description,
              tags: data.tags || [],
              movie: movieUrl,
              poster: "",
            },
            coachId: "2a6db6e1-8967-4511-9839-a7cb3c895710",
          };
          retValue = await addMovement(movementData);
        } else {
          const movementData = {
            movement: {
              id: id,
              type: 0,
              name: name,
              description: description,
              tags: data.tags || [],
              movie: movieUrl,
              poster: "",
            },
            coachId: "2a6db6e1-8967-4511-9839-a7cb3c895710",
          };
          retValue = await updateMovement(movementData);
        }
        if (retValue.status === 200 && retValue.data) {
          if (movieFileChanged) {
            const retval = await uploadMedia({
              File: movie as File,
              IsPlanPoster: false,
              MovementId: retValue.data.result.id,
              PlanId: "",
            });
            if (retval.status === 200) movieUrl = retval.data;
          } else movieUrl = movie as string;

          toast.success("عملیات با موفقیت انجام پذیرفت");
          navigate(`/movements`);
        } else toast.error("خطا در ثبت اطلاعات");
        // return new Promise((resolve, reject) => {
        //   setTimeout(async () => {
        //     resolve({ ...data, type: 2 });
        //     navigate(`/movements`);
        //   }, 5000);
        // });
      }
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        if (error.response.data.RsCode === 6) toast.error("حرکت تکراری است");
        else toast.error("خطا در ثبت اطلاعات");
      } else toast.error("خطا در ثبت اطلاعات");
    }
  };

  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
    setValue(
      "tags",
      tags.filter((tag, index) => index !== i)
    );
  };

  const handleAddition = (tag: Tag) => {
    if (onlyLettersAndNumbers(tag.text)) {
      setTags([...tags, tag]);
      setValue("tags", [...tags, tag]);
    }
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: number) => {
    //   console.log("The tag at index " + index + " was clicked");
  };

  // const handleMouseEnter = (e: any) => {
  //   try {
  //     const vid = e.target;
  //     let p = vid.play();
  //     if (p !== undefined) p.catch(function () {});
  //     setIsPlaying(true);
  //   } catch (error) {}
  // };
  // const handleMouseLeave = (e: any) => {
  //   try {
  //     if (isPlaying) {
  //       const vid = e.target;
  //       vid.currentTime = 0;
  //       vid.pause();
  //     }
  //   } catch (error) {}
  // };

  const registerOptions = {
    description: {
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "نحوه اجرا معتبر نیست",
    },
  };

  useEffect(() => {
    nameRef.current?.focus();
    async function fetchAPI() {
      try {
        if (movementId) {
          // let filData: movementType;
          const retValMovementData = await getMovement(movementId);
          if (
            retValMovementData.status === 200 &&
            retValMovementData.data.result
          ) {
            const { id, type, name, poster, description, movie, tags } =
              retValMovementData.data.result;
            const filData: movementType = {
              id: id,
              type: type,
              name: name,
              poster: poster || "",
              description: description,
              movie: movie,
              tags: tags
                ? tags.map((tag: tagType) => ({ id: tag.id, text: tag.text }))
                : [],
            };

            setMovieFile(filData.id);
            setTags(filData.tags!);
            reset(filData);
          }
        }
        const retVal = await getTags();

        if (retVal.status === 200) {
          setOriginalTags(
            retVal.data.result.map((tag: string) => ({
              id: tag,
              text: tag,
            }))
          );
          setLocalMovementTags(retVal.data.result.map((tag: string) => tag));
        } else {
          toast.error("خطا در فراخوانی اطلاعات");
          return;
        }
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex flex-col justify-start items-start px-3  sm:py-2 gap-y-3 text-sm "
    >
      <div
        className={`w-[12rem] h-[16rem] max-sm:h-[16rem] max-sm:mt-1  relative    mr-3
        ${errors.movie && "border border-red-600"} ${
          getValues("movie") ? "border-0" : !movementId && "border"
        }
        }`}
      >
        {movieFile ? (
          <VideoHover
            // src={movieFile}
            src={
              movementId
                ? `${moviesUrl}/media/${movementId}/media.mp4`
                : movieFile
            }
            // poster={Player.imageUrl}
            poster={`${moviesUrl}/media/${movementId}/thumbnail.png`}
            classes="w-full h-full "
          />
        ) : (
          // <video
          //   className="w-full h-full rounded-lg "
          //   muted
          //   onMouseEnter={handleMouseEnter}
          //   onMouseLeave={handleMouseLeave}
          //   src={movieFile}
          // ></video>
          movementId && (
            <div className="w-full h-full  skeleton rounded-lg "></div>
          )
        )}
        {watchMovementType === 1 && (!movementId || movieFile) && (
          <i
            className="cursor-pointer flex  flex-col justify-center items-center"
            onClick={(e) => {
              e.preventDefault();
              movieRef.current!.click();
            }}
          >
            <div
              data-tip={`${
                errors.movie ? errors.movie.message : "انتخاب ویدیو"
              }`}
              className={` absolute ${
                movieFile
                  ? " bottom-0 right-0 bg-white opacity-60 w-full h-6 flex  pr-1 items-center"
                  : "top-[40%]"
              }`}
            >
              {movieFile && (
                <div data-tip="انتخاب ویدیو" className="tooltip tooltip-left">
                  <SwapSVG className="w-4 " />
                </div>
              )}
              {!movieFile && <UploadSVG className="w-9" />}
            </div>

            <div
              className={`absolute hidden sm:flex tooltip tooltip-left ${
                movieFile ? "sm:hidden" : "top-[55%]"
              }`}
            >
              برای آپلود کلیک نمایید
            </div>
          </i>
        )}
        <input
          className="w-full hidden"
          multiple={false}
          ref={movieRef}
          // style={{ display: "none" }}
          type="file"
          accept="image/gif,video/mp4,video/mpeg,video/webm,video/quicktime"
          onChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const files = (e.target as HTMLInputElement).files;
            if (files?.length && files?.length > 0) {
              if (files[0].size > 30000000 || files[0].size < 1) {
                setValue("movie", null, { shouldTouch: true });
                setError("movie", {
                  type: "manual",
                  message: "حجم مدیا بیش از 30 مگابایت است",
                });
                setMovieFile(null);
                return false;
              }
              // setValue("movie", files[0], {
              //   shouldTouch: true,
              //   shouldValidate: true,
              // });
              const reader = new FileReader();
              reader.onloadend = () => {
                setMovieFile(reader.result);
                setValue("movie", files[0]);
                setMovieFileChanged(true);
                // setValue("movie", reader.result, {
                //   shouldTouch: true,
                //   shouldValidate: true,
                // });
                clearErrors(["movie"]);
              };

              reader.readAsDataURL(files![0]);
            }
          }}
        />
      </div>

      <div className="w-full flex px-2 ">
        <label
          data-tip={errors.name && errors.name.message}
          className={`form-control w-full  ${
            touchedFields.name && errors.name && "tooltip tooltip-bottom"
          }`}
        >
          <div className="label justify-start ">
            <span>نام حرکت</span>
          </div>
          <input
            tabIndex={0}
            type="text"
            {...rest}
            ref={nameRef}
            placeholder=""
            maxLength={50}
            readOnly={watchMovementType !== 1}
            className={`input max-2xl:text-xs 2xl:text-sm h-8 input-bordered w-full  ${
              errors.name && touchedFields.name && "border border-red-600"
            }`}
          />
        </label>
      </div>
      <div className="w-full  flex px-2">
        <label
          data-tip={errors.description && errors.description.message}
          className={`form-control w-full  ${
            errors.description && "tooltip tooltip-bottom"
          }`}
        >
          <div className="label justify-start">
            <span>نحوه اجرا</span>
          </div>
          <textarea
            tabIndex={1}
            {...register("description", registerOptions.description)}
            rows={3}
            cols={10}
            readOnly={watchMovementType !== 1}
            placeholder="توضیحات خود را وارد نمایید"
            className={`input max-2xl:text-xs 2xl:text-sm input-bordered w-full h-16 2xl:h-20  ${
              errors.description &&
              touchedFields.description &&
              "border border-red-600"
            }`}
          />
        </label>
      </div>
      <div
        tabIndex={2}
        className="hidden sm:flex w-full text-xs  justify-start px-2"
      >
        <label className={`form-control w-full  `}>
          <div className="label justify-start">
            <span className="text-sm">تگ</span>
          </div>
          <TagCtrl
            placeholder="انتخاب یا ایجاد تگ"
            Position="inline"
            tags={tags}
            mabnaTags={localMovementTags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            autocomplete={true}
          />
        </label>
      </div>
      <div className="sm:hidden w-full flex justify-start mt-3  ">
        <TagCtrl
          placeholder="انتخاب یا ایجاد تگ"
          Position="top"
          tags={tags}
          mabnaTags={localMovementTags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          autocomplete={true}
        />
      </div>

      <div className="w-full fixed bottom-2 left-2  flex justify-end gap-x-3">
        {/* <div className="flex justify-center btn bg-color1 text-white min-w-24"> */}
        <button
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            navigate(`/movements`);
          }}
          className="btn-1  border-[#7367F0] hover:bg-slate-100 text-color1 hover:text-color1 "
        >
          <ExitSVG className="w-3" fill="#7367F0" />
          بازگشت
        </button>
        {watchMovementType === 1 && (
          <button
            className="btn-1 bg-color1 text-white min-w-24 "
            // disabled={!isValid}
          >
            {isSubmitting && (
              <span
                className={`
         loading loading-spinner loading-xs`}
              ></span>
            )}
            <div className={`${isSubmitting && "hidden"}`}>
              {" "}
              {`${movementId ? "" : "+"}`}
            </div>
            {`${movementId ? "ثبت" : "اضافه کردن"}`}
          </button>
        )}
      </div>

      {/* </div> */}
    </form>
  );
};

export default Movement;
