import { MyContext } from "components/Context";
import CloseSVG from "components/svg/closeSvg";
import React, { MouseEvent, MouseEventHandler, useContext } from "react";

interface Props {
  showModal: boolean;
  title: string;
  desc: string;
  acceptBtnClick: (event:MouseEvent<HTMLElement>) => void;
  rejectBtnClick: () => void;
  acceptMessage: string;
  rejectMessage: string;
  children: React.ReactNode;
}

const ConfirmModal = ({
  title,
  desc,
  showModal,
  acceptBtnClick,
  rejectBtnClick,
  acceptMessage,
  rejectMessage,
  children,
}: Props) => {
  const {isDarkMode} = useContext(MyContext);
  return (
    <dialog
      id="my_modal_5"
      className="modal modal-bottom sm:modal-middle"
      open={showModal}
    >
      <div className="modal-box lg:!w-96 ">
        <h1 className="font-bold text-base text-justify">{title}</h1>
        <p className="py-4 text-sm text-wrap text-justify">{desc}</p>
        <div className="modal-movement">
          <form method="dialog" className="w-full flex justify-between">
            {/* if there is a button in form, it will close the modal */}
            <button
              className="btn-1 text-white  bg-orange-400"
              onClick={(event:MouseEvent<HTMLElement>)=>acceptBtnClick(event)}
            >
              {children}
              {acceptMessage}
            </button>
            <button
              className="btn-1 hover:bg-slate-300 hover:text-black"
              onClick={rejectBtnClick}
            >
              <CloseSVG
                className="h-3 w-3 "
                fill={`${isDarkMode ? "white" : "black"}`}
              />

              {rejectMessage}
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default ConfirmModal;
