import SearchSVG from "components/svg/searchSvg";
import React, { useState } from "react";

interface Props {
  data: any;
  showModal: boolean;
  title: string;
  desc: string;
  setChecked: (id: number) => void;
  filterDone: (e:React.MouseEvent<HTMLElement>) => void;
  filterClear:(e:React.MouseEvent<HTMLElement>) => void;
}

const Filter = ({
  data,
  setChecked,
  filterDone,
  filterClear
}: //   acceptBtnClick,
//   rejectBtnClick,
Props) => {
  const [localData, setLocalData] = useState<any>(data);
  const searchTextCahnge = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchedData: any = data.filter((item: any) =>
      item.text.includes(e.target.value)
    );
    setLocalData(searchedData);
  };
  return (
    <div className="card card-compact flex flex-col  bg-white z-20 justify-start  w-56 h-50  shadow-2xl ">
      <div className="w-full rounded-xl p-2 flex flex-col text-sm items-center ">
        <div className="pb-2">فیلتر</div>

        <div className=" h-full w-full flex relative justify-center pb-2 items-center">
          <input
            autoFocus
            onChange={searchTextCahnge}
            type="text"
            className="w-full border h-8 shadow py-3 px-6 rounded-lg text-sm"
            placeholder="جستجو"
          />
          <button className="absolute left-5">
            <SearchSVG className="text-black h-3 w-3  top-3.5 right-3 fill-current" />
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-between max-h-48 px-4 pb-3 mt-1 overflow-y-auto">
        {localData.map((item: any, idx: number) => (
          <div key={idx} className="flex my-1 py-1 border-b-2 gap-1.5">
            <input
              type="checkbox"
              defaultChecked={item.checked}
              onChange={() => setChecked(item.id)}
              className="checkbox checkbox-primary checkbox-sm"
            />
            <span className="label-text">{item.text}</span>
          </div>
        ))}
      </div>
      <div className="flex justify-between w-full min-h-11">
        <button
          onClick={(e:React.MouseEvent<HTMLElement>) => filterDone(e)}
          className=" bg-color1 text-white m-2 px-3 py-1 rounded-md text-xs"
        >
          اعمال فیلتر
        </button>
        <button
          onClick={(e:React.MouseEvent<HTMLElement>) => filterClear(e)}
          className=" bg-white text-black border m-2 px-3 py-1 rounded-md text-xs"
        >
         حذف همه
        </button>
      </div>
    </div>
  );
};

export default Filter;
