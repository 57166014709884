import LogoSVG from "components/svg/logoSvg";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SignUpLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.removeItem("authenticated"); //signOut
  });
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center font-yekan gap-y-4">
      <div className="h-2/5 w-full flex justify-center items-end">
        <LogoSVG className="w-44 h-44 xl:w-48 xl:h-48 " />
      </div>

      <div className="h-3/5 w-full flex flex-col justify-start items-center gap-y-6 px-3">
        <div className="text-color1 text-3xl">راحت مربیگری کن!</div>
        <div
          onClick={() => {
            navigate("/signUp");
          }}
          className="btn text-white bg-color1 w-full sm:w-72 hover:text-black "
        >
          ثبت نام
        </div>
        <button
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            navigate("/login");
          }}
          className="btn bg-white text-black border border-color1 w-full sm:w-72 "
        >
          ورود
        </button>
      </div>
    </div>
  );
};

export default SignUpLogin;
