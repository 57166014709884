import http from "services/authService";
import athletesMockData from "../components/athletes.json";
import { athleteType } from "types";
const appUrl = process.env.REACT_APP_URL;

export const addNote = (data: any) => {
  return http.post(`${appUrl}/Athlete/add-note`, {
    note: {
      ...data,
      noteId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      planId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      insertDatetime: "2024-09-13T14:17:33.442Z",
      updateDatetime: "2024-09-13T14:17:33.442Z",
    },
  });
};

export const updateNote = (data: any) => {
  return http.put(`${appUrl}/Athlete/edit-note`, {
    note: {
      ...data,
      planId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      insertDatetime: "2024-09-13T14:17:33.442Z",
      updateDatetime: "2024-09-13T14:17:33.442Z",
    },
  });
};

export const deleteNote = (noteId: string) => {
  return http.delete(`${appUrl}/Athlete/delete-note`, {
    data: {
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      noteId: noteId,
    },
  });
};

export const updatePackage = (data: any) => {
  return http.put(`${appUrl}/Package/updatepackage`, {
    package: { ...data },
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const getAthletes = () => {
  return http.get(
    `${appUrl}/Athlete/get-all-athlete?coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6`
  );
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve(athletesMockData);
  //   }, 500);
  // });
};

export const addPlanToAthlete = (data: any) => {
  return http.post(`${appUrl}/Plan/assign-athlete-plan`, {
    ...data,
    coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  });
};

export const getAthletePlanById = (planId: string) => {
  return http.get(`${appUrl}/Plan/get-plan-athletes?planId=${planId}`);
};

export const getAthleteNotes = (athleteId: string) => {
  return http.get(
    `${appUrl}/Athlete/get-notes-List?AthleteId=${athleteId}&coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6`
  );
};

export const getAthleteActivities = (athleteId: string) => {
  return http.get(
    `${appUrl}/Athlete/get-activity-List?AthleteId=${athleteId}&coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6`
  );
};

export const getAthletePlans = (athleteId: string) => {
  return http.get(`${appUrl}/Athlete/get-athlete-plans?AthleteId=${athleteId}`);
};

export const deleteAthletePlan = (planId: string, athleteId: string) => {
  return http.delete(`${appUrl}/Plan/remove-plan-assignment`, {
    data: {
      coachId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      planId: planId,
      athleteId: athleteId,
    },
  });
};

export const getAthlete = (athleteId: string) => {
  return http.get(
    `${appUrl}/Athlete/get-athlete-profile?AthleteId=${athleteId}`
  );
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       status: 200,
  //       data: {
  //         result: athletesMockData.data.result.find(
  //           (item: athleteType) => item.athleteId === athleteId
  //         ),
  //       },
  //     });
  //   }, 500);
  // });
  // return http.get(
  //   `${appUrl}/Package/getpackage?coachId=3fa85f64-5717-4562-b3fc-2c963f66afa6&PackageId=${athleteId}`
  // );
};
