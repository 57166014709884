import CountDownTimer from "components/countDownTimer";
import {
  maskUserId,
  onlyDigits,
  onlyLettersAndNumbers,
} from "components/helper/publicLib";
import EyeoffSVG from "components/svg/EyeoffSVG";
import EyeSVG from "components/svg/EyeSVG";
import LogoSVG from "components/svg/logoSvg";
import SpinnerSVG from "components/svg/spinnerSvg";
import React, { useEffect, useState } from "react";
import {
  DefaultValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { GrFormPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";
import {
  retryVerification,
  setPassword,
  signUp,
  verify,
} from "services/signupService";
import { toast } from "sonner";
import { signUpType } from "types";

const initialValues: DefaultValues<signUpType> = {
  userName: "",
  userFamily: "",
  userEmailOrMobile: "",
  verificationCode: null,
  password: "",
  rePassword: "",
};

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isContinuing, setIsContinuing] = useState<boolean>(false);
  const [countDownStarted, setCountDownStarted] = useState<boolean>(true);
  const [showRePassword, setShowRePassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [invalidVerificationCount, setInvalidVerificationCount] =
    useState<number>(0);

  const form = useForm<signUpType>({
    mode: "onBlur",
    defaultValues: initialValues,
  });
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    resetField,
    // formState,
    trigger,
    getFieldState,
    setFocus,
    formState: {
      errors,
      // isDirty,
      isSubmitting,
      touchedFields,
      isValid,
      // isLoading,
      dirtyFields,
    },
  } = form;

  const registerOptions = {
    userName: {
      required: "نام خود را وارد نمایید",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "اطلاعات شده معتبر نیست",
    },
    userFamily: {
      required: "نام خانوادگی خود را وارد نمایید",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "اطلاعات وارد شده معتبر نیست",
    },
    userEmailOrMobile: {
      required: "تلفن همراه یا ایمیل خود را وارد نمایید",
      validate: (value: any) => {
        if (!isNaN(value)) {
          setIsMobile(true);
          if (!value.startsWith("09") || value.length !== 11)
            //mobile
            return "شماره همراه وارد شده معتبر نیست";
        } else {
          setIsMobile(false);
          var re = /\S+@\S+\.\S+/;
          if (!re.test(value)) return "ایمیل وارد شده معتبر نیست";
        }
      },
    },
    verificationCode: {
      validate: (value: any) => {
        if (
          !value ||
          isNaN(value) ||
          parseInt(value) > 999999 ||
          parseInt(value) < 11111
        ) {
          const retval: string = isMobile
            ? "کد صحیح ارسال شده به موبایل را وارد نمایید"
            : "کد صحیح ارسال شده به ایمیل را وارد نمایید";
          return retval;
        }
      },
    },
    password: {
      required: "رمز عبور را وارد نمایید",

      validate: (value: any) => {
        const validPass =
          /(\d.*[A-Z])|([A-Z].*\d)/.test(value) &&
          /\d/.test(value) &&
          /[a-z]/.test(value);
        if (!validPass) return "حداقل یک حرف بزرگ، کوچک و عدد وارد نمایید";
      },
      minLength: {
        value: 6,
        message: "طول رمز عبور باید حداقل 6 کاراکتر باشد",
      },
    },
    rePassword: {
      required: "تکرار رمز عبور را وارد نمایید",
      validate: (value: any) => {
        if (watch("password") !== value) {
          return "تکرار رمز عبور با رمز عبور تطابق ندارد";
        }
      },
    },
  };
  const stepValidation = async (CurrentStep: number): Promise<boolean> => {
    if (CurrentStep < 3) trigger();
    if (!isValid) return false;
    setIsContinuing(true);
    try {
      if (currentStep === 1) {
        const { data } = await signUp({
          firstName: watch("userName"),
          lastName: watch("userFamily"),
          userId: maskUserId(watch("userEmailOrMobile")),
        });
        console.log(data);
        if (!data.data.isSuccess) {
          if (data.data.rsCode === 3) {
            toast.error("کاربری با این تلفن همراه/ایمیل وجود دارد");
            return false;
          } else if (data.data.rsCode === 16) {
            retryVerificationCode();
            return true;
          } else return false;
        }
      } else if (currentStep === 2) {
        const { data } = await verify({
          userId: maskUserId(watch("userEmailOrMobile")),
          token: watch("verificationCode")!.toString(),
        });
        console.log(data);
        if (!data.data.isSuccess) {
          if (data.data.rsCode === 5) toast.error("کد وارد شده صحیح نمی باشد");
          setInvalidVerificationCount((prev) => prev + 1);
          if (invalidVerificationCount > 4) {
            setCurrentStep(1);
            setInvalidVerificationCount(0);
          }
          return false;
        }
      }
      return true;
    } catch (error) {
      toast.error("خطا در ثبت اطلاعات");
      return false;
    } finally {
      setIsContinuing(false);
    }
  };

  const showRePasswordEvent = (event: any) => {
    // if (event.type === "mousedown") {
    setShowRePassword((prev) => !prev);
    // } else {
    //   setShowRePassword(false);
    // }
  };

  const showPasswordEvent = (event: any) => {
    // if (event.type === "mousedown") {
    setShowPassword((prev) => !prev);
    // } else {
    //   setShowPassword(false);
    // }
  };
  const retryVerificationCode = async () => {
    setCountDownStarted(true);
    const retVal = await retryVerification({
      firstName: getValues("userName"),
      lastName: getValues("userFamily"),
      userId: maskUserId(getValues("userEmailOrMobile")),
    });
    console.log(retVal);
  };
  const onSubmit: SubmitHandler<signUpType> = async (data: signUpType) => {
    try {
      if (isSubmitting || !isValid || !stepValidation(currentStep)) return;

      const { data: retval } = await setPassword({
        userId: maskUserId(data.userEmailOrMobile),
        password: data.password!,
        token: data.verificationCode?.toString()!,
        repeatPassword: data.rePassword!,
      });
      console.log(retval);
      if (retval.data.isSuccess) setCurrentStep(currentStep + 1);
      else toast.error("خطا در ثبت اطلاعات");
      // reset(savedPlan);
      // toast.success("ثبت با موفقیت انجام پذیرفت");

      // navigate("/plans");
    } catch (error) {
      toast.error("خطا در ثبت اطلاعات");
    }
  };

  useEffect(() => {
    if (currentStep === 2) setFocus("verificationCode");
    if (currentStep === 3) setFocus("password");
    else setFocus("userName");
  }, [setFocus, currentStep]);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-screen w-screen flex flex-col items-center justify-start gap-y-3 2xl:gap-y-6 "
      >
        <LogoSVG className="w-40 h-40 2xl:w-48 2xl:h-48 mt-2 2xl:mt-7 " />

        {currentStep > 0 && currentStep < 4 && (
          <>
            {" "}
            <div className="text-color1 text-base 2xl:text-xl">ثبت نام</div>
            <ul className="w-full h-16 xl:w-96 steps text-xs ">
              <li
                className={`step cursor-pointer ${
                  currentStep >= 1 && " step-primary"
                }`}
                onClick={() => setCurrentStep(1)}
              >
                ثبت اطلاعات
              </li>
              <li
                className={`step  ${currentStep >= 2 && " step-primary"} ${
                  currentStep === 3 && "cursor-pointer"
                } `}
                onClick={() => currentStep === 3 && setCurrentStep(2)}
              >
                دریافت کد تایید
              </li>
              <li className={`step ${currentStep === 3 && " step-primary"}`}>
                تعیین رمز عبور
              </li>
            </ul>
          </>
        )}
        {currentStep === 1 && (
          <>
            <div className="w-full sm:w-96 flex text-sm  px-2">
              <label
                data-tip={errors.userName && errors.userName.message}
                className={`form-control w-full  ${
                  errors.userName && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">نام</span>
                </div>
                <input
                  tabIndex={1}
                  type="text"
                  {...register("userName", registerOptions.userName)}
                  maxLength={20}
                  placeholder="نام خود را وارد نمایید"
                  className={`input  h-7 2xl:h-9 input-bordered w-full placeholder:text-xs focus:outline-none ${
                    errors.userName && "border-1 border-red-600"
                  }`}
                />
              </label>
            </div>
            <div className="w-full sm:w-96 flex text-sm  px-2">
              <label
                data-tip={errors.userFamily && errors.userFamily.message}
                className={`form-control w-full  ${
                  errors.userFamily && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">نام خانوادگی</span>
                </div>
                <input
                  tabIndex={1}
                  type="text"
                  {...register("userFamily", registerOptions.userFamily)}
                  maxLength={30}
                  placeholder="نام خانوادگی خود را وارد نمایید"
                  className={`input   h-7 2xl:h-9 input-bordered w-full placeholder:text-xs focus:outline-none ${
                    errors.userFamily && "border-1 border-red-600"
                  }`}
                />
              </label>
            </div>
            <div className="w-full sm:w-96 flex text-sm  px-2">
              <label
                data-tip={
                  errors.userEmailOrMobile && errors.userEmailOrMobile.message
                }
                className={`form-control w-full  ${
                  errors.userEmailOrMobile && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">تلفن همراه/ایمیل</span>
                </div>
                <input
                  tabIndex={1}
                  type="text"
                  {...register(
                    "userEmailOrMobile",
                    registerOptions.userEmailOrMobile
                  )}
                  maxLength={50}
                  placeholder="تلفن همراه یا ایمیل خود را وارد نمایید"
                  className={`input h-7 2xl:h-9 placeholder:text-right  input-bordered w-full placeholder:text-xs ltr focus:outline-none ${
                    errors.userEmailOrMobile && "border-1 border-red-600"
                  }`}
                />
              </label>
            </div>
          </>
        )}
        {currentStep === 2 && (
          <>
            <div className="w-full sm:w-96 flex text-sm  px-2">
              <label
                data-tip={
                  errors.verificationCode && errors.verificationCode.message
                }
                className={`form-control w-full  ${
                  errors.verificationCode && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">کد تایید</span>
                </div>
                <input
                  tabIndex={1}
                  type="text"
                  maxLength={6}
                  onKeyDown={(e: any) => {
                    return onlyDigits(e);
                  }}
                  {...register(
                    "verificationCode",
                    registerOptions.verificationCode
                  )}
                  placeholder="کد تایید را وارد نمایید"
                  className={`input text-lg  h-9 input-bordered w-full placeholder:text-right placeholder:text-xs ltr pr-6 ${
                    errors.verificationCode && "border-1 border-red-600"
                  }`}
                />
              </label>
            </div>
          </>
        )}
        {currentStep === 3 && (
          <>
            <div className="w-full sm:w-96 flex text-sm px-2 relative">
              <label
                data-tip={errors.password && errors.password.message}
                className={`form-control w-full  ${
                  errors.password && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">رمز عبور</span>
                </div>
                <input
                  tabIndex={1}
                  type={!showPassword ? "password" : "text"}
                  maxLength={20}
                  {...register("password", registerOptions.password)}
                  placeholder="رمز ورود می بایست حداقل 6 کاراکتر شامل حداقل یک حرف بزرگ، کوچک و عدد باشد"
                  className={`input text-lg  h-9 input-bordered  w-full  placeholder:text-right placeholder:text-2xs ltr focus:outline-none ${
                    errors.password && "border-1 border-red-600"
                  }`}
                />
              </label>
              <div
                onMouseDown={showPasswordEvent}
                // onMouseUp={showPasswordEvent}
                className={`${
                  !getValues("password") && "hidden"
                } absolute cursor-pointer  w-6 right-4 bottom-2`}
              >
                {!showPassword ? (
                  <EyeSVG className="h-[20px] w-[20px]" />
                ) : (
                  <EyeoffSVG className="h-[20px] w-[20px]" />
                )}
                {/* <i
                  className="fa fa-eye "
                  aria-hidden="true"
                  onMouseDown={showPasswordEvent}
                  onMouseUp={showPasswordEvent}
                ></i> */}
              </div>
            </div>
            <div className="w-full self-center sm:w-96 flex text-sm px-2 relative">
              <label
                data-tip={errors.rePassword && errors.rePassword.message}
                className={`form-control w-full  ${
                  errors.rePassword && "tooltip tooltip-bottom"
                }`}
              >
                <div className="label justify-start">
                  <span className="">تکرار رمز عبور</span>
                </div>
                <input
                  tabIndex={1}
                  type={!showRePassword ? "password" : "text"}
                  maxLength={20}
                  {...register("rePassword", registerOptions.rePassword)}
                  //   placeholder="تکرار رمز عبور را وارد نمایید"
                  className={`input text-lg  h-9 input-bordered w-full ltr placeholder:text-right focus:outline-none ${
                    errors.rePassword && "border-1 border-red-600"
                  }`}
                />
              </label>
              <div
                onMouseDown={showRePasswordEvent}
                // onMouseUp={showRePasswordEvent}
                className={`${
                  !getValues("rePassword") && "hidden"
                } absolute cursor-pointer  w-6 right-4 bottom-2`}
              >
                {!showRePassword ? (
                  <EyeSVG className="h-[20px] w-[20px]" />
                ) : (
                  <EyeoffSVG className="h-[20px] w-[20px]" />
                )}
                {/* <i
                  className="fa fa-eye "
                  aria-hidden="true"
                  onMouseDown={showRePasswordEvent}
                  onMouseUp={showRePasswordEvent}
                ></i> */}
              </div>
            </div>
          </>
        )}
        {currentStep === 4 && (
          <div className="w-full sm:w-[30rem] flex flex-col justify-start items-center text-color1 gap-y-2 mt-12 px-3">
            <div className="text-lg">مربی عزیز</div>
            <div className="text-justify leading-10	">
              ثبت نام شما با موفقیت انجام شد. پس از بررسی های لازم، حساب کاربری
              شما فعال و نتیجه ی آن از طریق پیامک یا ایمیل برایتان ارسال خواهد
              شد.
            </div>
          </div>
        )}
        {currentStep < 3 && (
          //    <div class="flex items-center justify-center">
          //    <button type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
          //      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          //        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          //        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          //      </svg>
          //      Processing...
          //    </button>
          //  </div>

          <div className="w-full sm:w-96 flex justify-end text-xs px-2 mt-3">
            <div
              onClick={async () => {
                (await stepValidation(currentStep)) &&
                  isValid &&
                  setCurrentStep(currentStep + 1);
              }}
              className=" btn-1 text-white   bg-color1 w-full md:w-1/2 2xl:w-1/3 flex justify-center"
            >
              {isContinuing && (
                <SpinnerSVG className="animate-spin h-5 w-5 text-white" />
              )}
              <div>ادامه</div>
              <div>
                <GrFormPrevious />
              </div>
            </div>
          </div>
        )}
        {currentStep === 1 && (
          <div className="w-full sm:w-96 flex justify-center text-sm 2xl:text-base  px-2">
            <Link
              to={"/login"}
              className="text-sm text-center transition-all  text-color1"
            >
              قبلا ثبت نام کرده اید؟ وارد شوید
            </Link>
          </div>
        )}
        {currentStep === 2 && (
          <div className="w-full sm:w-96 flex justify-start  px-2 gap-x-2">
            <div
              onClick={async () => {
                if (!countDownStarted) {
                  retryVerificationCode();
                  // setCountDownStarted(true);
                  // const retVal = await retryVerification({
                  //   firstName: watch("userName"),
                  //   lastName: watch("userFamily"),
                  //   userId: watch("userEmailOrMobile"),
                  // });
                  // console.log(retVal);
                }
              }}
              className={`${
                !countDownStarted ? "link link-primary" : "text-slate-500"
              }  `}
            >
              درخواست مجدد کد تایید
            </div>
            {countDownStarted && (
              <CountDownTimer
                finished={() => setCountDownStarted(false)}
                startTime={180}
              />
            )}
          </div>
        )}
        {currentStep === 3 && (
          <div className="w-full sm:w-96 flex flex-col justify-center text-xs px-2 mt-2 gap-y-2">
            <button className="btn-1 text-white flex justify-center  bg-color1 w-full">
              {isSubmitting && (
                <SpinnerSVG className="animate-spin h-5 w-5 text-white" />
              )}
              <span>ثبت</span>
            </button>
            <span className="text-color1">
              ثبت نام شما به معنی پذیرفتن کلیه
              <a className="underline" href="http://w3schools.com">
                {" "}
                قوانین و مقررات{" "}
              </a>
              مربی می باشد.
            </span>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default SignUp;
