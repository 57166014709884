import { SVGProps } from "react";
import * as React from "react";

function SwapSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      //   className="svg-icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M320 640H64c-35.328 0-64 28.672-64 64v256a64 64 0 0 0 64 64h256a64 64 0 0 0 64-64V704c0-35.328-28.672-64-64-64z m-64 256H128v-128h128v128zM960 0H704a64 64 0 0 0-64 64v256a64 64 0 0 0 64 64h256a64 64 0 0 0 64-64V64a64 64 0 0 0-64-64z m-64 256h-128V128h128v128zM448 64a64.021333 64.021333 0 0 1 0 128h-128c-35.328 0-64 28.672-64 64h64c25.856 0 49.28 15.616 59.136 39.488a64.106667 64.106667 0 0 1-13.888 69.76l-128 128A63.829333 63.829333 0 0 1 192 512a63.829333 63.829333 0 0 1-45.248-18.752l-128-128a64.213333 64.213333 0 0 1-13.888-69.76A64.042667 64.042667 0 0 1 64 256h64a192 192 0 0 1 192-192h128z m429.248 466.752l128 128a64.213333 64.213333 0 0 1 13.888 69.76A64.042667 64.042667 0 0 1 960 768h-64c0 105.984-86.016 192-192 192h-128a64 64 0 1 1 0-128h128c35.392 0 64-28.608 64-64h-64a64.042667 64.042667 0 0 1-59.136-39.488 64.106667 64.106667 0 0 1 13.888-69.76l128-128A63.829333 63.829333 0 0 1 832 512c16.384 0 32.768 6.272 45.248 18.752z"
        
      />
    </svg>
  );
}
export default SwapSVG;
