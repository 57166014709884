import React, { MouseEvent, useEffect, useState } from "react";
import { deletePackage, getPackages } from "services/packageService";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { athleteType } from "types";
import FilterSVG from "components/svg/filterSvg";
import CloseSVG from "components/svg/closeSvg";
import { SubmitHandler, useForm } from "react-hook-form";
import SearchSVG from "components/svg/searchSvg";
import LoadingMovements from "./loadingMovements";
import ConfirmModal from "components/modals/confirmModal";
import { RiDeleteBinLine } from "react-icons/ri";
import { dateMask, toShamsi } from "components/helper/publicLib";
import { FixedSizeList } from "react-window";
import { UseLayout } from "components/helper/useLayout";
import { getAthletes } from "services/athleteService";
import AvatarSVG from "components/svg/avatarSvg";

interface Search {
  searchTxt: string;
}

const Athletes = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [firteredData, setFirteredData] = useState<athleteType[]>([]);
  const [athletesData, setAthletesData] = useState<athleteType[]>([]);
  const [selectedAthleteId, setSelectedAthleteId] = useState<
    string | undefined
  >("0");
  const [showModal, setShowModal] = useState<boolean>(false);

  const [dimension, setDimension] = useState<{
    width: number;
    height: number;
  }>();

  const navigate = useNavigate();

  const { width, height } = UseLayout();
  // const control1Ref = useRef(null);

  // const { isDarkMode } = useContext(MyContext);

  const onSubmit: SubmitHandler<Search> = async (inputSearch: Search) => {
    const localPA = athletesData.filter(
      (item: athleteType) =>
        item.athleteFullName!.includes(inputSearch.searchTxt) ||
        item.lastNotes!.includes(inputSearch.searchTxt)
    );
    setFirteredData(localPA);
  };

  const { register, handleSubmit } = useForm<Search>({
    mode: "onSubmit",
    defaultValues: { searchTxt: "" },
  });

  const getathletesData = async () => {
    try {
      //const { data: athletesDataFromApi } = await getAthletes();
      const athletesDataFromApi = await getAthletes();
      if (
        (athletesDataFromApi as any).data.result &&
        (athletesDataFromApi as any).data.result.length > 0
      ) {
        setAthletesData((athletesDataFromApi as any).data.result);
        setFirteredData((athletesDataFromApi as any).data.result);
      }
      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
      throw error;
    }
  };

  // const resizeControl = (sourceControl: any) => {
  //   if (sourceControl) {
  //     const { offsetWidth, offsetHeight } = sourceControl;
  //     setDimension({ height: offsetHeight, width: offsetWidth });
  //   }
  // };
  // useEffect(() => {
  //   resizeControl(control1Ref.current);
  // }, [control1Ref.current && (control1Ref.current as any).offsetWidth]);

  useEffect(() => {
    async function fetchAPI() {
      try {
        await getathletesData();
      } catch (error) {
        toast.error("خطا در فراخوانی اطلاعات");
      }
    }
    fetchAPI();
    // function handleResize() {
    //   resizeControl(control1Ref.current);
    // }
    // window.addEventListener("resize", handleResize);
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  const acceptBtnClick = async (e: MouseEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      // await removePackage(selectedAthleteId!);
      await deletePackage(selectedAthleteId!);
      const athletesDataLocal = athletesData.filter(
        (item: athleteType) => item.athleteId !== selectedAthleteId
      );

      setAthletesData(athletesDataLocal);
      setFirteredData(athletesDataLocal);
      setShowModal(false);
      toast.success("عملیات با موفقیت انجام پذیرفت");
    } catch (error) {
      toast.error("خطا در  عملیات");
    }
    setIsLoadingData(false);
  };
  const rejectBtnClick = () => {
    setShowModal(false);
  };
  const rowStyles: string =
    "w-full max-md:border-t  max-md:h-11 max-md:flex max-md:items-center max-md:justify-start max-md:gap-x-2";

  const colStyles: string =
    "md:hidden h-full bg-slate-300 flex items-center w-32 justify-center";

  const Row = ({ index, style }: any) => (
    <div
      className=" md:bg-white max-md:h-60  border-b-2 text-center text-xs  h-12 flex flex-col   md:flex-row  items-center "
      style={style}
    >
      <div className={`md:w-[8%] md:flex md:justify-center ${rowStyles}`}>
        <div className={`${colStyles}`}></div>
        <div
          onClick={() => navigate(`/athlete/${firteredData[index].athleteId}`)}
          className="w-9 h-9 rounded-full overflow-hidden flex justify-center items-center cursor-pointer"
        >
          {firteredData[index].athletePhotoUrl ? (
            <img
              src={firteredData[index].athletePhotoUrl!}
              alt={`athlets ${firteredData[index].athleteFullName}`}
            />
          ) : (
            <AvatarSVG />
          )}
        </div>
      </div>
      <div className={`md:w-[21%] ${rowStyles}`}>
        <div className={`${colStyles}`}>نام</div>
        <div>{firteredData[index].athleteFullName}</div>
      </div>
      <div className={`md:w-[10%] ${rowStyles}`}>
        <div className={`${colStyles}`}>تاریخ شروع </div>
        {toShamsi(firteredData[index].subscriptionDateTime!)}
      </div>
      <div className={`md:w-[8%] ${rowStyles}`}>
        <div className={`${colStyles}`}>وضعیت</div>
        <div
          className={`${
            firteredData[index].state &&
            "w-12 h-8 rounded-xl bg-green-300 flex items-center justify-center mx-auto px-7 py-1"
          }`}
        >{`${firteredData[index].state ? "فعال" : "غیر فعال"}`}</div>
      </div>
      <div className={`md:w-[8%] ${rowStyles}`}>
        <div className={`${colStyles}`}>میزان پیشرفت</div>
        <div>
          <span className="text-lg">%</span>{" "}
          {firteredData[index].progress?.toString()}{" "}
        </div>
      </div>
      <div className={`md:w-[10%] ${rowStyles}`}>
        <div className={`${colStyles}`}>تاریخ آخرین فرم</div>
        <div className="h-full w-[calc(100%-9rem)] md:w-full flex items-center md:justify-center">
          {toShamsi(firteredData[index].lastFormDate!)}
        </div>
      </div>
      <div className={`md:w-[15%] ${rowStyles}`}>
        <div className={`${colStyles}`}>نیاز به بررسی</div>
        <div className="h-full w-[calc(100%-9rem)] md:w-full flex items-center md:justify-center">
          {`${firteredData[index].needAssessment ? "دارد" : "ندارد"}`}
        </div>
      </div>
      <div className={`md:w-[20%] ${rowStyles}`}>
        <div className={`${colStyles}`}>یادداشت ها</div>
        <div
          data-tip={firteredData[index].lastNotes}
          className="h-full w-[calc(100%-9rem)] md:w-full flex items-center md:justify-center tooltip 	tooltip-bottom  tooltip-custom lg:tooltip-right right-bottom"
        >
          <div className="truncate w-44">
            {firteredData[index].lastNotes ? firteredData[index].lastNotes : ""}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {!isLoadingData && athletesData.length > 0 && (
        <div
          className={`w-full h-full flex flex-col justify-start items-center text-xs `}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col-reverse md:flex-row  justify-between h-18 md:h-10 w-[98%] mt-5 md:mt-1  gap-y-2 "
          >
            <div></div>

            <div
              className={`h-8 w-full md:w-72 flex relative justify-start items-center gap-x-2 mt-2 ml-2 
                              `}
            >
              {/* <div className="w-4 relative cursor-pointer">
                {!showFilter && (
                  <FilterSVG
                    
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                    className="w-full "
                  />
                )}
                {showFilter && (
                  <CloseSVG
                    fill={`black`}
                    onClick={() => {
                      setShowFilter(!showFilter);
                    }}
                  />
                )}

                {showFilter && (
                  <div className="absolute mt-4">
                    <Filter
                      data={originalTags}
                      setChecked={setfilterChecked}
                      title="حرکت انتخاب شده حذف شود؟"
                      desc="در صورت پاک کردن این حرکت تمام محتوای آن حذف خواهد شد."
                      showModal={showFilter}
                      filterDone={filterData}
                      filterClear={filterClear}
                    /> 
                  </div>
                )}
              </div> */}

              <div className=" h-full w-full flex relative justify-center items-center">
                <input
                  autoFocus
                  {...register("searchTxt")}
                  type="text"
                  className="w-full border h-full shadow py-2 px-6 rounded-lg "
                  placeholder="جستجو"
                />
                <button type="submit" className="absolute left-5">
                  <SearchSVG className="text-black  w-5  top-3.5 right-3 fill-current" />
                </button>
              </div>
            </div>
          </form>
          <div className="w-[97%] flex flex-col gap-y-2 items-center mt-4 h-[calc(100vh-4.8rem)] md:h-[calc(100vh-2.8rem)] border mb-3 pt-2 bg-gradient-to-r from-[#D7D4F9] rounded-lg">
            {isLoadingData ? (
              <LoadingMovements />
            ) : !athletesData ? (
              <div className="h-10 w-full mx-5 ">
                <div>لیست شما خالی است.</div>
                {/* <a className="btn-1 text-white  bg-color1" href="/#firteredData[index]">
                  <span className="mx-2 text-xl">+ </span>
                  اضافه کردن حرکت
                </a> */}
              </div>
            ) : (
              <div className="w-[97%] mx-2 ">
                <div className="w-full h-10 max-md:hidden">
                  <div className="w-full h-10 text-center  flex ">
                    <div className="w-[8%] "></div>
                    <div className="w-[21%] ">نام</div>
                    <div className="w-[10%]"> تاریخ شروع</div>
                    <div className="w-[8%]">وضعیت</div>
                    <div className="w-[8%]">میزان پیشرفت</div>
                    <div className="w-[10%]">تاریخ فرم آخر</div>
                    <div className="w-[15%]">نیاز به بررسی</div>
                    <div className="w-[20%]">یادداشت ها</div>
                  </div>
                </div>
                <div className="h-full overflow-y-auto ">
                  <FixedSizeList
                    // className="List"
                    direction="rtl"
                    height={height}
                    itemCount={firteredData.length}
                    itemSize={width > 768 ? 45 : 270}
                    width={"100%"}
                  >
                    {Row}
                  </FixedSizeList>
                </div>
              </div>
            )}

            <ConfirmModal
              acceptMessage="حذف شود"
              rejectMessage="منصرف شدم"
              title="پکیج انتخاب شده حذف شود؟"
              desc="در صورت پاک کردن این پکیج تمام محتوای آن حذف خواهد شد."
              showModal={showModal}
              acceptBtnClick={acceptBtnClick}
              rejectBtnClick={rejectBtnClick}
            >
              <RiDeleteBinLine className="w-3" />
            </ConfirmModal>
          </div>
        </div>
      )}
    </>
  );
};

export default Athletes;
