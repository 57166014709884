import { SVGProps } from "react";
import * as React from "react";

function WorkoutSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2982_81548)">
        <rect x="2" y="0.5" width="39" height="40" fill="black" />
        <rect
          x="2"
          y="0.5"
          width="39"
          height="40"
          fill="white"
          fillOpacity="0.95"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        stroke="#7367F0"
        
      />
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        stroke="white"
        stroke-opacity="0.6"
        
      />
      <path
        d="M12.75 20.75C12.75 16.9788 12.75 15.0931 13.9216 13.9216C15.0931 12.75 16.9788 12.75 20.75 12.75H21.25C25.0212 12.75 26.9069 12.75 28.0784 13.9216C29.25 15.0931 29.25 16.9788 29.25 20.75V21.25C29.25 25.0212 29.25 26.9069 28.0784 28.0784C26.9069 29.25 25.0212 29.25 21.25 29.25H20.75C16.9788 29.25 15.0931 29.25 13.9216 28.0784C12.75 26.9069 12.75 25.0212 12.75 21.25V20.75Z"
        stroke="black"
       
      />
      <path
        d="M12.75 20.75C12.75 16.9788 12.75 15.0931 13.9216 13.9216C15.0931 12.75 16.9788 12.75 20.75 12.75H21.25C25.0212 12.75 26.9069 12.75 28.0784 13.9216C29.25 15.0931 29.25 16.9788 29.25 20.75V21.25C29.25 25.0212 29.25 26.9069 28.0784 28.0784C26.9069 29.25 25.0212 29.25 21.25 29.25H20.75C16.9788 29.25 15.0931 29.25 13.9216 28.0784C12.75 26.9069 12.75 25.0212 12.75 21.25V20.75Z"
        stroke="white"
        stroke-opacity="0.5"
       
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2477 22.5203C26.9569 22.2295 26.7119 21.9857 26.496 21.797C26.2609 21.5914 26.0208 21.415 25.7363 21.2949C25.0524 21.0061 24.2809 21.0061 23.597 21.2949C23.3126 21.415 23.0725 21.5914 22.8373 21.797C22.6114 21.9945 22.3536 22.2523 22.0448 22.5611L22.0273 22.5786C21.7903 22.8156 21.6418 22.9634 21.5246 23.0643C21.4509 23.1277 21.4145 23.1501 21.4046 23.1558C21.329 23.177 21.2478 23.1613 21.1854 23.1136C21.1784 23.1047 21.1528 23.0703 21.108 22.9841C21.0366 22.8468 20.9536 22.6544 20.8216 22.3465L20.7727 22.2322L20.759 22.2002L20.759 22.2002C20.4717 21.5299 20.2368 20.9817 20.0125 20.5631C19.7838 20.1362 19.5217 19.7551 19.1382 19.4902C18.5043 19.0524 17.7158 18.9005 16.9647 19.0716C16.5102 19.1751 16.1253 19.4317 15.7545 19.743C15.463 19.9878 15.1344 20.3071 14.75 20.6897C14.75 20.7097 14.75 20.7297 14.75 20.7499V21.2499C14.75 21.8261 14.7504 22.3418 14.7541 22.8064L15.4894 22.0712C16.0357 21.5248 16.4114 21.1501 16.7191 20.8918C17.0254 20.6345 17.19 20.5587 17.2978 20.5342C17.6392 20.4564 17.9976 20.5254 18.2857 20.7244C18.3767 20.7872 18.5014 20.9187 18.6903 21.2714C18.88 21.6255 19.0896 22.1129 19.394 22.8231L19.4429 22.9373L19.4532 22.9614C19.5718 23.238 19.6767 23.4829 19.7771 23.6759C19.8807 23.8752 20.0163 24.095 20.2281 24.2686C20.6853 24.6433 21.3008 24.7618 21.8645 24.5837C22.1257 24.5012 22.3332 24.3475 22.5034 24.201C22.6683 24.059 22.8566 23.8706 23.0695 23.6578L23.0879 23.6393C23.4188 23.3084 23.6411 23.0867 23.8246 22.9262C24.0032 22.7701 24.105 22.7086 24.1805 22.6767C24.4913 22.5455 24.842 22.5455 25.1529 22.6767C25.2284 22.7086 25.3301 22.7701 25.5087 22.9262C25.6922 23.0867 25.9146 23.3084 26.2454 23.6393L27.1953 24.5892C27.231 24.0232 27.2434 23.3489 27.2477 22.5203Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2477 22.5203C26.9569 22.2295 26.7119 21.9857 26.496 21.797C26.2609 21.5914 26.0208 21.415 25.7363 21.2949C25.0524 21.0061 24.2809 21.0061 23.597 21.2949C23.3126 21.415 23.0725 21.5914 22.8373 21.797C22.6114 21.9945 22.3536 22.2523 22.0448 22.5611L22.0273 22.5786C21.7903 22.8156 21.6418 22.9634 21.5246 23.0643C21.4509 23.1277 21.4145 23.1501 21.4046 23.1558C21.329 23.177 21.2478 23.1613 21.1854 23.1136C21.1784 23.1047 21.1528 23.0703 21.108 22.9841C21.0366 22.8468 20.9536 22.6544 20.8216 22.3465L20.7727 22.2322L20.759 22.2002L20.759 22.2002C20.4717 21.5299 20.2368 20.9817 20.0125 20.5631C19.7838 20.1362 19.5217 19.7551 19.1382 19.4902C18.5043 19.0524 17.7158 18.9005 16.9647 19.0716C16.5102 19.1751 16.1253 19.4317 15.7545 19.743C15.463 19.9878 15.1344 20.3071 14.75 20.6897C14.75 20.7097 14.75 20.7297 14.75 20.7499V21.2499C14.75 21.8261 14.7504 22.3418 14.7541 22.8064L15.4894 22.0712C16.0357 21.5248 16.4114 21.1501 16.7191 20.8918C17.0254 20.6345 17.19 20.5587 17.2978 20.5342C17.6392 20.4564 17.9976 20.5254 18.2857 20.7244C18.3767 20.7872 18.5014 20.9187 18.6903 21.2714C18.88 21.6255 19.0896 22.1129 19.394 22.8231L19.4429 22.9373L19.4532 22.9614C19.5718 23.238 19.6767 23.4829 19.7771 23.6759C19.8807 23.8752 20.0163 24.095 20.2281 24.2686C20.6853 24.6433 21.3008 24.7618 21.8645 24.5837C22.1257 24.5012 22.3332 24.3475 22.5034 24.201C22.6683 24.059 22.8566 23.8706 23.0695 23.6578L23.0879 23.6393C23.4188 23.3084 23.6411 23.0867 23.8246 22.9262C24.0032 22.7701 24.105 22.7086 24.1805 22.6767C24.4913 22.5455 24.842 22.5455 25.1529 22.6767C25.2284 22.7086 25.3301 22.7701 25.5087 22.9262C25.6922 23.0867 25.9146 23.3084 26.2454 23.6393L27.1953 24.5892C27.231 24.0232 27.2434 23.3489 27.2477 22.5203Z"
        fill="white"
        fillOpacity="0.5"
      />
      <circle cx="25.125" cy="16.875" r="1.375" fill="black" />
      <circle
        cx="25.125"
        cy="16.875"
        r="1.375"
        fill="white"
        fillOpacity="0.5"
      />
      <defs>
        <clipPath id="clip0_2982_81548">
          <rect x="2" y="2" width="38" height="38" rx="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default WorkoutSVG;
