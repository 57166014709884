import * as React from "react";
import { SVGProps } from "react";

function SaveSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.75V14.625C12 13.3394 12 12.6965 11.7155 12.2218C11.5464 11.9397 11.3103 11.7036 11.0282 11.5345C10.5535 11.25 9.91065 11.25 8.625 11.25V11.25C7.33935 11.25 6.69653 11.25 6.22179 11.5345C5.93969 11.7036 5.70363 11.9397 5.53454 12.2218C5.25 12.6965 5.25 13.3394 5.25 14.625V15.75"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M5.25 6H9"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M2.25 8.25C2.25 5.42157 2.25 4.00736 3.12868 3.12868C4.00736 2.25 5.42157 2.25 8.25 2.25H11.9216C12.3303 2.25 12.5347 2.25 12.7185 2.32612C12.9022 2.40224 13.0468 2.54676 13.3358 2.83579L15.1642 4.66421C15.4532 4.95324 15.5978 5.09776 15.6739 5.28153C15.75 5.4653 15.75 5.66968 15.75 6.07843V9.75C15.75 12.5784 15.75 13.9926 14.8713 14.8713C13.9926 15.75 12.5784 15.75 9.75 15.75H8.25C5.42157 15.75 4.00736 15.75 3.12868 14.8713C2.25 13.9926 2.25 12.5784 2.25 9.75V8.25Z"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );
}
export default SaveSVG;
