import { useFieldArray, useFormContext } from "react-hook-form";
import { questionType } from "types";

const PreDefinedQuestions = () => {
  const {
    register,
    control,
    formState,
    reset,
    setValue,
    clearErrors,
    watch,
    resetField,
    getFieldState,
    getValues,
    trigger,
  } = useFormContext();
  const { isValid, errors } = formState;

  const {
    fields: questions,
    append: appendQuestion,
    remove: removeQuestion,
    // update: updateExercise,
  } = useFieldArray({
    control,
    name: `questions`,
  });

  return (
    <div className="flex flex-col w-full gap-y-5 max-sm:pb-3">
      <div className="flex max-sm:hidden  justify-between w-full ">
        <div className="sm:w-2/3">سوال</div>
        <div className="sm:w-1/3">اضافه کردن به فرم</div>
        <div></div>
      </div>
      {questions
        .filter((question: questionType) => question.isCustom === false)
        .map((question: questionType, idx) => (
          <div
            key={idx}
            className="flex flex-col sm:flex-row  justify-between w-full"
          >
            <div className="w-full sm:w-2/3 flex max-sm:gap-x-2 max-sm:mb-2">
              <span className="sm:hidden ">سوال:</span>
              {question.text}
            </div>
            <div className="w-full sm:w-1/3 flex max-sm:gap-x-2 max-sm:mt-2">
              <span className="sm:hidden pr-5">اضافه کردن به فرم</span>
              <input
                className="sm:mr-10 disabled:!bg-color2"
                type="checkbox"
                {...register(`questions.${idx}.isChecked`)}
                id={idx.toString()}
                disabled={getValues(`questions.${idx}.isNeeded`)}
              />
            </div>
            <div></div>
          </div>
        ))}
    </div>
  );
};

export default PreDefinedQuestions;
