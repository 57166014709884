import { SVGProps } from "react";
import * as React from "react";

function RestSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2982_81547)">
        <rect x="2" y="1" width="39" height="39" fill="black" />
        <rect
          x="2"
          y="1"
          width="39"
          height="39"
          fill="white"
          fillOpacity="0.95"
        />
      </g>
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        stroke="#7367F0"
        
      />
      <rect
        x="1"
        y="1"
        width="40"
        height="40"
        rx="20"
        stroke="white"
        stroke-opacity="0.4"
        
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5833 20.0833C15.5959 20.0833 16.4167 20.9041 16.4167 21.9166V23.7499H25.5833V21.9166C25.5833 20.9041 26.4041 20.0833 27.4167 20.0833C28.4292 20.0833 29.25 20.9041 29.25 21.9166V25.5833C29.25 26.5958 28.4292 27.4166 27.4167 27.4166H14.5833C13.5708 27.4166 12.75 26.5958 12.75 25.5833V21.9166C12.75 20.9041 13.5708 20.0833 14.5833 20.0833Z"
        stroke="black"
       
      
       
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5833 20.0833C15.5959 20.0833 16.4167 20.9041 16.4167 21.9166V23.7499H25.5833V21.9166C25.5833 20.9041 26.4041 20.0833 27.4167 20.0833C28.4292 20.0833 29.25 20.9041 29.25 21.9166V25.5833C29.25 26.5958 28.4292 27.4166 27.4167 27.4166H14.5833C13.5708 27.4166 12.75 26.5958 12.75 25.5833V21.9166C12.75 20.9041 13.5708 20.0833 14.5833 20.0833Z"
        stroke="white"
        stroke-opacity="0.1"
       
      
       
      />
      <path
        d="M14.584 20.0833V15.5C14.584 13.9812 15.8152 12.75 17.334 12.75H24.6673C26.1861 12.75 27.4173 13.9812 27.4173 15.5V20.0833"
        stroke="black"
       
      
       
      />
      <path
        d="M14.584 20.0833V15.5C14.584 13.9812 15.8152 12.75 17.334 12.75H24.6673C26.1861 12.75 27.4173 13.9812 27.4173 15.5V20.0833"
        stroke="white"
        stroke-opacity="0.1"
       
      
       
      />
      <path
        d="M15.4993 27.4167V29.2501"
        stroke="black"
       
      
       
      />
      <path
        d="M15.4993 27.4167V29.2501"
        stroke="white"
        stroke-opacity="0.1"
       
      
       
      />
      <path
        d="M26.4993 27.4167V29.2501"
        stroke="black"
       
      
       
      />
      <path
        d="M26.4993 27.4167V29.2501"
        stroke="white"
        stroke-opacity="0.1"
       
      
       
      />
      <defs>
        <clipPath id="clip0_2982_81547">
          <rect x="2" y="2" width="38" height="38" rx="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default RestSVG;
