import {
  useFormContext,
  SubmitHandler,
  useFieldArray,
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  exerciseType,
  movementType,
  circuitType,
  dayType,
  setType,
} from "types";
import { onlyDigits, onlyLettersAndNumbers } from "components/helper/publicLib";
import MovementsModal from "components/modals/movementsModal";
import { getMovement } from "services/movementService";
import React, { useEffect, useState } from "react";
import DeleteSVG from "components/svg/deleteSvg";
import Numbring from "components/numbering.json";
import VideoHover from "components/videoHover";
import { GiSaveArrow } from "react-icons/gi";
import SwapSVG from "components/svg/swapSvg";
import ExitSVG from "components/svg/exitSvg";
import { toast } from "sonner";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;

interface Props {
  currentWeek: number;
  currentDay: number;
  dayData: dayType;
  circuitData: circuitType | null;
  currentExercise: number;
  setShowExercise: (state: number) => void;
  // insUpExercise: (
  //   exerciseFormData: exerciseType,
  //   exerciseInitData: exerciseType | null
  // ) => void;
}

interface excerciseMovie {
  movementId: string;
}
const Exercise = ({
  currentWeek,
  currentDay,
  dayData,
  circuitData,
  currentExercise,
  setShowExercise,
}: // insUpExercise,
Props) => {
  const [showMovementsModal, setShowMovementsModal] = useState<boolean>(false);
  const [movieData, setMovieData] = useState<excerciseMovie>();
  const [isSubmittingExercise, setIsSubmittingExercise] =
    useState<boolean>(false);

  const {
    register,
    control,
    formState,
    getValues,
    reset,
    setValue,
    clearErrors,
    watch,
    trigger,
    resetField,
    getFieldState,
    setError,
  } = useFormContext();
  const { errors, dirtyFields, isValid } = formState;

  const currentExerciseData = watch(
    `weekDays.${
      currentWeek * 7 + currentDay
    }.circuits.${currentExercise}.circuitExercises.${0}`
  );

  const {
    fields: exerciseSets,
    append: appendSet,
    remove: removeSet,
    update: updateSet,
  } = useFieldArray({
    control,
    name: `weekDays.${
      currentWeek * 7 + currentDay
    }.circuits.${currentExercise}.circuitExercises.${0}.exerciseSets`,
  });

  useEffect(() => {
    async function fetchAPI() {
      try {
        if (circuitData) {
          const {
            data: { result: tempMovementData },
          } = await getMovement(circuitData!.circuitExercises![0].movementId!);

          const movement: movementType = {
            id: tempMovementData.id,
            type: tempMovementData.type,
            name: tempMovementData.name,
            poster: tempMovementData.poster,
            description: tempMovementData.description,
            movie: tempMovementData.movie,
            // tags: tempMovementData.tags || [],
          };

          // const movement: movementType = privateMovements.find(
          //   (item) =>
          //     item.id === circuitData!!.movementId &&
          //     item.type === circuitData!!.movementType
          // )!;
          if (movement) {
            setValue(
              `weekDays.${
                currentWeek * 7 + currentDay
              }.circuits.${currentExercise}.movementId`,
              movement.id
            );
            setValue(
              `weekDays.${
                currentWeek * 7 + currentDay
              }.circuits.${currentExercise}.movementType`,
              movement.type
            );
            // setValue(
            //   `weekDays.${currentWeek}.days.${currentDay}.circuits.${currentExercise}.movementType`,
            //   movement.type
            // );
            setValue(
              `weekDays.${
                currentWeek * 7 + currentDay
              }.circuits.${currentExercise}.exerciseName`,
              movement.name
            );
            clearErrors([
              `weekDays.${
                currentWeek * 7 + currentDay
              }.circuits.${currentExercise}.exerciseName`,
            ]);
            setMovieData({
              movementId: movement.id!,
            });
          } else setShowExercise(0);
        }
      } catch (error) {
        toast.error("خطا در بارگزاری اطلاعات");
      }
    }
    fetchAPI();
  }, []);

  // const handleMouseEnter = (e: any) => {
  //   const vid = e.target;
  //   vid.muted = true;
  //   vid.play();
  // };
  // const handleMouseLeave = (e: any) => {
  //   const vid = e.target;
  //   vid.muted = false;
  //   vid.currentTime = 0;
  //   vid.pause();
  // };

  const returnError = () => {
    const localError = getFieldState(
      `weekDays.${
        currentWeek * 7 + currentDay
      }.circuits.${currentExercise}.circuitExercises.${0}`
    ).error;
    return localError;
  };

  const returnErrorForCircuit = () => {
    const localError = getFieldState(
      `weekDays.${currentWeek * 7 + currentDay}.circuits.${currentExercise}`
    ).error;
    return localError;
  };

  const selectExercise = async (movementId: string, movementType: number) => {
    const {
      data: { result: tempMovementData },
    } = await getMovement(movementId);

    const movement: movementType = {
      id: tempMovementData.id,
      type: tempMovementData.type,
      name: tempMovementData.name,
      poster: tempMovementData.poster,
      description: tempMovementData.description,
      movie: tempMovementData.movie,
      // tags: movement.tags || [],
    };

    // const movement: movementType = privateMovements.find(
    //   (item) => item.id === movementId && item.type === movementType
    // )!;

    setValue(
      `weekDays.${
        currentWeek * 7 + currentDay
      }.circuits.${currentExercise}.circuitExercises.${0}.movementId`,
      movement.id
    );
    setValue(
      `weekDays.${
        currentWeek * 7 + currentDay
      }.circuits.${currentExercise}.circuitExercises.${0}.movementType`,
      movement.type
    );
    setValue(
      `weekDays.${
        currentWeek * 7 + currentDay
      }.circuits.${currentExercise}.circuitExercises.${0}.exerciseName`,
      movement.name
    );
    // setValue(
    //   `weekDays.${
    //     currentWeek * 7 + currentDay
    //   }.circuits.${currentExercise}.circuitName`,
    //   movement.name
    // );
    clearErrors([
      `weekDays.${currentWeek * 7 + currentDay}.circuits.${currentExercise}`,
    ]);

    setMovieData({
      movementId: movement.id!,
    });
  };
  const registerOptions = {
    exerciseDescription: {
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "توضیحات معتبر نیست",
    },
    circuitRestTime: {
      valueAsNumber: true,
      required: "زمان استراحت را وارد نمایید",
      // pattern: {
      //   value: /d+/,
      //   message: "مقدار باید عدد باشد",
      // },
      maxLength: {
        value: 3,
        message: "تعداد ارقام بیش از حد مجاز است",
      },
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "زمان استراحت معتبر نیست",
    },
    exerciseSets: {
      valueAsNumber: true,
      required: "مقدار ست را وارد نمایید",
      // pattern: {
      //   value: /d+/,
      //   message: "مقدار باید عدد باشد",
      // },
      maxLength: {
        value: 20,
        message: "تعداد کاراکتر ها بیش از حد مجاز است",
      },
    },
  };
  return (
    // <FormProvider {...form}>
    <div
      // onSubmit={handleSubmit(onSubmit)}
      className="w-full h-full flex flex-col gap-y-2 justify-start items-start  text-xs"
    >
      <div className="w-full  flex flex-col items-start justify-start border rounded-md gap-y-3 p-2">
        <span>
          هفته {Numbring[currentWeek]} / روز {Numbring[currentDay]} / تمرین{" "}
          {circuitData
            ? Numbring[currentExercise]
            : Numbring[
                (dayData?.circuits?.length || 0) > 0
                  ? dayData!.circuits!.length - 1
                  : 0
              ]}
        </span>

        <div className="flex w-full justify-end items-center ">
          <div className="flex items-center gap-x-2">
            <button
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                resetField(
                  `weekDays.${
                    currentWeek * 7 + currentDay
                  }.circuits.${currentExercise}`,
                  { keepError: false, keepDirty: false, keepTouched: false }
                );
                reset(watch(), {
                  keepValues: false,
                  keepDirty: false,
                  keepDefaultValues: false,
                });
                setShowExercise(0);
              }}
              className="btn-1   border-[#7367F0] hover:bg-slate-100 text-color1 hover:text-color1  "
            >
              <ExitSVG className="w-3" fill="#7367F0" />
              بازگشت
            </button>
            <button
              //type="submit"
              onClick={async (e: React.MouseEvent) => {
                e.preventDefault();

                if (!currentExerciseData.exerciseName) {
                  setError(
                    `weekDays.${
                      currentWeek * 7 + currentDay
                    }.circuits.${currentExercise}.circuitExercises.${0}.exerciseName`,
                    {
                      type: "manual",
                      message: "انتخاب حرکت اجباری است",
                    }
                  );
                  trigger();
                  return false;
                }
                if (!isValid) {
                  trigger();
                  return false;
                } else {
                  setIsSubmittingExercise(true);
                  clearErrors(
                    `weekDays.${
                      currentWeek * 7 + currentDay
                    }.circuits.${currentExercise}.circuitExercises.${0}.exerciseSets`
                  );
                  reset(watch(), {
                    keepValues: false,
                    keepDirty: false,
                    keepDefaultValues: false,
                  });

                  return new Promise((resolve, reject) => {
                    setTimeout(async () => {
                      setIsSubmittingExercise(false);
                      setShowExercise(0);
                      resolve();
                    }, 2000);
                  });
                }
                //   reset({}, { keepValues: true });
              }}
              className="btn-1 bg-color1 text-white  min-w-24  "
            >
              {isSubmittingExercise ? (
                <span
                  className={`
         loading loading-spinner loading-xs`}
                ></span>
              ) : (
                <GiSaveArrow />
              )}
              ذخیره
            </button>
          </div>
        </div>
      </div>
      <div className="w-full   flex flex-col overflow-y-auto max-md:gap-y-2 gap-y-1">
        <div className="w-full flex justify-start items-start ">
          <div
            className={`w-[10rem] h-[12rem] flex flex-col items-center justify-center border relative rounded-md ${
              (returnError() as any)?.exerciseName && "border border-red-600"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setShowMovementsModal(true);
            }}
          >
            {(currentExerciseData.movementId || movieData?.movementId) && (
              <>
                <h2 className="absolute top-3 right-3 text-sm z-10">
                  {watch(
                    `weekDays.${
                      currentWeek * 7 + currentDay
                    }.circuits.${currentExercise}.circuitExercises.${0}.exerciseName`
                  )}
                </h2>
                <VideoHover
                  src={`${moviesUrl}/media/${
                    currentExerciseData.movementId
                      ? currentExerciseData.movementId
                      : movieData?.movementId
                  }/media.mp4`}
                  poster={`${moviesUrl}/media/${
                    currentExerciseData.movementId
                      ? currentExerciseData.movementId
                      : movieData?.movementId
                  }/thumbnail.png`}
                  classes="w-full h-full"
                />
              </>
            )}

            {!movieData?.movementId && !currentExerciseData?.movementId && (
              <>
                {" "}
                <i className="cursor-pointer flex flex-col justify-center items-center text-4xl font-light">
                  +
                </i>
                <div className="text-2xs text-center">
                  حرکت مورد نظر را از لیست حرکات اضافه نمایید
                </div>
              </>
            )}
            {currentExerciseData?.movementId && (
              <div
                data-tip="انتخاب حرکت"
                className="bottom-0 right-0 absolute  pr-1 bg-white opacity-60 w-full h-6 flex items-center tooltip tooltip-left"
              >
                <SwapSVG className="w-4 cursor-pointer" />
              </div>
            )}
          </div>
        </div>
        <div className="w-full  flex ">
          <label
            data-tip={
              (returnError() as any)?.exerciseDescription &&
              (returnError() as any)?.exerciseDescription.message
            }
            className={`form-control w-full  ${
              (returnError() as any)?.exerciseDescription &&
              "tooltip tooltip-bottom"
            }`}
          >
            <div className="label justify-start">
              <span className="label-text-alt ">توضیحات</span>
            </div>
            <textarea
              tabIndex={1}
              {...register(
                `weekDays.${
                  currentWeek * 7 + currentDay
                }.circuits.${currentExercise}.circuitExercises.${0}.exerciseDescription`,
                registerOptions.exerciseDescription
              )}
              rows={3}
              cols={10}
              maxLength={300}
              placeholder="توضیحات خود را وارد نمایید"
              className={`input input-bordered w-full text-sm  min-h-12`}
            />
          </label>
        </div>
        <div className="w-full  flex items-end gap-x-3  ">
          <label
            data-tip={
              (returnErrorForCircuit() as any)?.circuitRestTime &&
              (returnErrorForCircuit() as any)?.circuitRestTime.message
            }
            className={`form-control w-28  ${
              (returnErrorForCircuit() as any)?.circuitRestTime &&
              "tooltip tooltip-left"
            }`}
          >
            <div className="label justify-start">
              <span className="label-text-alt ">استراحت بین ست</span>
            </div>
            <input
              type="text"
              maxLength={2}
              onKeyDown={(e: any) => {
                return onlyDigits(e);
              }}
              tabIndex={2}
              {...register(
                `weekDays.${
                  currentWeek * 7 + currentDay
                }.circuits.${currentExercise}.circuitRestTime`,
                registerOptions.circuitRestTime
              )}
              placeholder=""
              className={`input input-bordered w-full text-center h-7 ltr  ${
                (returnErrorForCircuit() as any)?.circuitRestTime &&
                "border border-red-600"
              }`}
            />
          </label>
          <select
            {...register(
              `weekDays.${
                currentWeek * 7 + currentDay
              }.circuits.${currentExercise}.circuitRestType`,
              {
                valueAsNumber: true,
              }
            )}
            defaultValue="0"
            className="rounded-md bg-transparent "
          >
            <option value={0}>ثانیه</option>
            <option value={1}>دقیقه</option>
          </select>
        </div>

        <div className="flex flex-col bg-white w-full items-start  justify-center rounded-md ">
          <div className="w-full flex justify-start items-start">
            <div className="form-control  ">
              <label className="cursor-pointer label !gap-x-3">
                <span className={`label-text `}>تعداد</span>
                <input
                  type="checkbox"
                  className="toggle toggle-primary "
                  {...register(
                    `weekDays.${
                      currentWeek * 7 + currentDay
                    }.circuits.${currentExercise}.circuitExercises.${0}.exerciseSetType`
                  )}
                />
                <span className={`label-text `}>زمان</span>
              </label>
            </div>
          </div>
          <div className="flex flex-col w-full justify-start items-start max-md:p-1 p-3 gap-y-3">
            {currentExerciseData &&
              currentExerciseData.exerciseSets &&
              currentExerciseData.exerciseSets.map(
                (exercise: setType, index: any) => (
                  <fieldset
                    key={exercise.id}
                    className="flex w-full justify-start gap-x-2 "
                  >
                    <div className="min-w-14">ست {Numbring[index]}</div>
                    <div
                      data-tip={
                        (returnError() as any)?.exerciseSets &&
                        (returnError() as any)?.exerciseSets[index] &&
                        (returnError() as any)?.exerciseSets[index].value
                          .message
                      }
                      className={`${
                        (returnError() as any)?.exerciseSets &&
                        (returnError() as any)?.exerciseSets[index] &&
                        "tooltip tooltip-left"
                      }`}
                    >
                      <input
                        type="text"
                        tabIndex={2}
                        maxLength={20}
                        {...register(
                          `weekDays.${
                            currentWeek * 7 + currentDay
                          }.circuits.${currentExercise}.circuitExercises.${0}.exerciseSets.${index}.value`,
                          registerOptions.exerciseSets
                        )}
                        placeholder=""
                        className={`input max-md:input-sm input-bordered  text-center h-7   ltr  ${
                          (returnError() as any)?.exerciseSets &&
                          (returnError() as any)?.exerciseSets[index] &&
                          "border border-red-600"
                        }`}
                      />
                    </div>
                    <div>
                      {!currentExerciseData.exerciseSetType && (
                        <select
                          {...register(
                            `weekDays.${
                              currentWeek * 7 + currentDay
                            }.circuits.${currentExercise}.circuitExercises.${0}.exerciseSets.${index}.type`,
                            {
                              valueAsNumber: true,
                            }
                          )}
                          defaultValue="0"
                          className="rounded-md bg-transparent h-7"
                        >
                          <option value={0}>ثانیه</option>
                          <option value={1}>دقیقه</option>
                        </select>
                      )}
                    </div>
                    <div onClick={() => index > 0 && removeSet(index)}>
                      <DeleteSVG className="w-4 h-7 cursor-pointer" />
                    </div>
                  </fieldset>
                )
              )}
          </div>

          <div className="flex justify-center items-center gap-x-3 mr-3 my-3">
            <span
              className="text-lg bg-sky-100 w-6  text-center rounded-md cursor-pointer"
              onClick={() => {
                // const values = getValues();
                if (
                  currentExerciseData?.exerciseSets?.length === 0 ||
                  (currentExerciseData?.exerciseSets as any)[
                    currentExerciseData?.exerciseSets?.length! - 1
                  ].value !== ""
                ) {
                  appendSet(
                    {
                      id: currentExerciseData?.exerciseSets?.length + 1,
                      type: 0,
                    },
                    {
                      shouldFocus: true,
                    }
                  );
                }
              }}
            >
              +
            </span>
            <span>اضافه کردن ست</span>
          </div>
        </div>
      </div>
      <MovementsModal
        showModal={showMovementsModal}
        exitBtnClick={() => setShowMovementsModal(false)}
        selectExerciseBtnClick={(movementId: string, type: number) => {
          selectExercise(movementId, type);
          setShowMovementsModal(false);
        }}
      >
        <GiSaveArrow className="w-3" />
      </MovementsModal>
    </div>
    // </FormProvider>
  );
};

export default Exercise;