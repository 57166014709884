import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { onlyLettersAndNumbers, uuidv4 } from "components/helper/publicLib";
import { useEffect, useRef, useState, DragEvent } from "react";
import { exerciseType, circuitType, dayType } from "types";
import DraggableSVG from "components/svg/draggableSvg";
import DeleteSVG from "components/svg/deleteSvg";
import Numbring from "components/numbering.json";
import Exercise from "components/plan/exercise";
import Circuit from "components/plan/circuit";
import { GiSaveArrow } from "react-icons/gi";
import Player from "components/player.json";
import { toast } from "sonner";

const moviesUrl = process.env.REACT_APP_MOVIES_URL;

const exerciseInitialValues: circuitType = {
  id: uuidv4().toString(),
  circuitOrder: null,
  isExercise: true,
  circuitName: "",
  circuitDescription: null,
  circuitRestType: 0,
  circuitRestTime: null,
  circuitExercises: [
    {
      id: uuidv4().toString(),
      exerciseOrder: 1,
      movementId: null,
      movementType: null,
      exerciseName: null,
      exerciseDescription: null,
      // exerciseRestType: 0,
      //exerciseRestTime: null,
      exerciseSetType: true,
      exerciseSets: [
        {
          id: 1,
          type: 0,
        },
        {
          id: 2,
          type: 0,
        },
        {
          id: 3,
          type: 0,
        },
      ],
    },
  ],
};

const circuitInitialValues: circuitType = {
  id: uuidv4().toString(),
  circuitOrder: null,
  isExercise: false,
  circuitName: null,
  circuitDescription: null,
  circuitRestType: 0,
  circuitRestTime: null,
  circuitExercises: [
    {
      id: uuidv4().toString(),
      exerciseOrder: 1,
      movementId: null,
      movementType: null,
      exerciseName: null,
      exerciseDescription: null,
      //exerciseRestType: 0,
      //exerciseRestTime: null,
      exerciseSetType: true,
      exerciseSets: [
        {
          id: 1,
          type: 0,
        },
        {
          id: 2,
          type: 0,
        },
        {
          id: 3,
          type: 0,
        },
      ],
    },
    {
      id: uuidv4().toString(),
      exerciseOrder: 2,
      movementId: null,
      movementType: null,
      exerciseName: null,
      exerciseDescription: null,
      //exerciseRestType: 0,
      //exerciseRestTime: null,
      exerciseSetType: true,
      exerciseSets: [
        {
          id: 1,
          type: 0,
        },
        {
          id: 2,
          type: 0,
        },
        {
          id: 3,
          type: 0,
        },
      ],
    },
  ],
};

interface Props {
  currentWeek: number;
  currentDay: number;
}

const DayComponent = ({ currentWeek, currentDay }: Props) => {
  const {
    register,
    control,
    formState,
    reset,
    setValue,
    clearErrors,
    watch,
    resetField,
    getFieldState,
    getValues,
    trigger,
  } = useFormContext();
  const { isValid, errors } = formState;

  const watchDaysOfWeek = watch(`weekDays`);
  const getCurrentDayOfWeek = (weekIdx: number, dayIdx: number) => {
    const retVal: dayType[] = watchDaysOfWeek.filter(
      (day: dayType) => day.weekIndex === weekIdx && day.dayIndex === dayIdx
    );
    return retVal[0];
  };
  const currentDayData = getCurrentDayOfWeek(currentWeek + 1, currentDay + 1);

  const {
    fields: circuits,
    append: appendCircuits,
    remove: removeCircuits,
    // update: updateExercise,
  } = useFieldArray({
    control,
    name: `weekDays.${currentWeek * 7 + currentDay}.circuits`,
  });

  const watchCircuits = getCurrentDayOfWeek(
    currentWeek + 1,
    currentDay + 1
  )?.circuits;

  const [showExercise, setShowExercise] = useState<number>(0);
  const [currentCircuitData, setCurrentCircuitData] =
    useState<circuitType | null>(null);

  const [currentExercise, setCurrentCircuit] = useState<number>(0);
  const [isSubmittingDay, setIsSubmittingDay] = useState<boolean>(false);
  useEffect(() => {
    if (currentDayData?.dayName)
      resetField(`weekDays.${currentWeek * 7 + currentDay}`);

    setShowExercise(0);
  }, [currentDay, currentWeek]);

  const dragItem = useRef(0);
  const dragStart = (e: DragEvent<HTMLDivElement>, position: number) => {
    dragItem.current = position as number;
  };
  const dragOverItem = useRef(0);
  const dragEnter = (e: DragEvent<HTMLDivElement>, position: number) => {
    dragOverItem.current = position;
  };
  const drop = (e: DragEvent<HTMLDivElement>, index: number) => {
    const copyListItems = [...watchCircuits!];
    const dragItemContent = copyListItems[dragItem.current!];
    copyListItems.splice(dragItem.current!, 1);
    copyListItems.splice(dragOverItem.current!, 0, dragItemContent);
    const sortedItems = copyListItems.map((item, idx) => ({
      ...item,
      circuitOrder: idx + 1,
    }));
    dragItem.current = 0;
    dragOverItem.current = 0;
    setValue(`weekDays.${currentWeek * 7 + currentDay}.circuits`, sortedItems, {
      shouldDirty: true,
    });
  };

  const selectAnExecerciseOrCircuit = (
    circuitData: circuitType,
    index: number
  ) => {
    setCurrentCircuitData(circuitData);
    setCurrentCircuit(index);
    if (circuitData.isExercise) setShowExercise(1);
    else setShowExercise(2);
  };
  const callInUpsFromParent = () => {
    //  const values1 = getValues();
    // console.log(values1);

    clearErrors("circuits");
    reset(watch(), {
      keepValues: false,
      keepDirty: false,
      keepDefaultValues: false,
    });
    // const values = getValues();
    // insUpDay(values, dayData || null);
  };

  const returnError = () => {
    const localError = getFieldState(
      `weekDays.${currentWeek * 7 + currentDay}`
    ).error;
    return localError;
  };

  const registerOptions = {
    dayName: {
      required: "نام روز را وارد نمایید",
      validate: (value: any) =>
        onlyLettersAndNumbers(value) || "نام روز اجرا معتبر نیست",
    },
    // dayLongTime:{
    //   min:  {
    //     value: 1,
    //     message: "زمان تمرین را انتخاب نمایید"
    // },

    //}
    // planTime: {
    //   required: "مدت برنامه را وارد نمایید",
    //   validate: (value: any) =>
    //     onlyLettersAndNumbers(value) || "مدت برنامه معتبر نیست",
    // },
  };

  return (
    <>
      {currentDayData && (
        <div className="w-full h-full">
          {!showExercise ? (
            <>
              <div className="w-full  flex flex-col items-start justify-start text-xs border rounded-md gap-y-3 p-1">
                <span>
                  هفته {Numbring[currentWeek]} / روز {Numbring[currentDay]}
                </span>

                <div
                  className="flex w-full max-md:flex-col justify-between items-center max-md:items-start max-md:gap-y-3 "
                  //   key={index}
                >
                  <div className="flex items-center ">
                    <span className=" ml-2">روز استراحت</span>
                    <input
                      type="checkbox"
                      {...register(
                        `weekDays.${currentWeek * 7 + currentDay}.isRest`,
                        {
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            event.target.checked &&
                              setValue(
                                `weekDays.${
                                  currentWeek * 7 + currentDay
                                }.dayName`,
                                "روز استراحت"
                              );
                          },
                        }
                      )}
                      defaultChecked={false}
                      className="checkbox checkbox-primary checkbox-sm ml-1"
                    />
                  </div>
                  {!currentDayData.isRest && (
                    <div className="flex items-center justify-center gap-x-2 max-md:w-full">
                      <button
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();

                          if (!isValid) {
                            // trigger();
                            if (
                              errors.weekDays &&
                              (errors.weekDays as any)[
                                currentWeek * 7 + currentDay
                              ].circuits
                            )
                              return false;
                          }
                          const newExercise: circuitType = {
                            ...exerciseInitialValues,
                            circuitOrder:
                              currentDayData.circuits &&
                              currentDayData.circuits.length > 0
                                ? Math.max.apply(
                                    null,
                                    currentDayData.circuits
                                      .filter((item: circuitType) => item)
                                      .map((o: circuitType) => o.circuitOrder!)
                                  ) + 1
                                : 1,
                            id:
                              currentDayData.circuits &&
                              currentDayData.circuits.length > 0
                                ? `${uuidv4().toString().substring(0, 34)}${(
                                    Math.max.apply(
                                      null,
                                      currentDayData.circuits
                                        .filter((item: circuitType) => item)
                                        .map(
                                          (o: circuitType) => o!.circuitOrder!
                                        )
                                    ) + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}`
                                : `${uuidv4().toString().substring(0, 34)}01`,
                          };

                          appendCircuits(newExercise);
                          setCurrentCircuitData(null);
                          setCurrentCircuit(
                            currentDayData.circuits
                              ? currentDayData.circuits.length - 1
                              : 0
                          );
                          setShowExercise(1);
                        }}
                        className="btn-1   border-[#7367F0] text-color1    "
                      >
                        <span className="mx-1 text-lg">+ </span>
                        اضافه کردن تمرین
                      </button>
                      <button
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          if (!isValid) {
                            // trigger();
                            if (
                              errors.weekDays &&
                              (errors.weekDays as any)[
                                currentWeek * 7 + currentDay
                              ]
                            )
                              return false;
                          }
                          const newCircuit: circuitType = {
                            ...circuitInitialValues,
                            circuitOrder:
                              currentDayData.circuits &&
                              currentDayData.circuits.length > 0
                                ? Math.max.apply(
                                    null,
                                    currentDayData.circuits
                                      .filter((item: circuitType) => item)
                                      .map((o: circuitType) => o!.circuitOrder!)
                                  ) + 1
                                : 1,
                            id:
                              currentDayData.circuits &&
                              currentDayData.circuits.length > 0
                                ? `${uuidv4().toString().substring(0, 34)}${(
                                    Math.max.apply(
                                      null,
                                      currentDayData.circuits
                                        .filter((item: circuitType) => item)
                                        .map(
                                          (o: circuitType) => o.circuitOrder!
                                        )
                                    ) + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}`
                                : `${uuidv4().toString().substring(0, 34)}01`,
                          };

                          appendCircuits(newCircuit);
                          setCurrentCircuitData(null);
                          setCurrentCircuit(
                            currentDayData.circuits
                              ? currentDayData.circuits.length - 1
                              : 0
                          );
                          setShowExercise(2);
                        }}
                        className="btn-1  border-[#7367F0] text-color1    "
                      >
                        <span className="mx-1 text-lg">+ </span>
                        اضافه کردن سیرکت
                      </button>
                    </div>
                  )}
                  <button
                    onClick={async (e: React.MouseEvent) => {
                      e.preventDefault();
                      trigger();
                      if (!isValid) {
                        return false;
                      } else {
                        setIsSubmittingDay(true);

                        callInUpsFromParent();
                        return new Promise((resolve, reject) => {
                          setTimeout(async () => {
                            setIsSubmittingDay(false);
                            toast.success("عملیات با موفقیت انجام پذیرفت");
                            resolve();
                          }, 2000);
                          
                        });
                      }
                      //   reset({}, { keepValues: true });
                    }}
                    className="btn-1 bg-color1 text-white  min-w-24 max-md:mx-auto "
                  >
                    {isSubmittingDay ? (
                      <span
                        className={`
         loading loading-spinner loading-xs`}
                      ></span>
                    ) : (
                      <GiSaveArrow />
                    )}
                    ذخیره روز
                  </button>
                </div>
              </div>
              {/* <div className="w-full min-h-96 h-full   "> */}

              <div className="w-full h-[85%]  flex flex-col gap-y-3 mt-2 text-xs">
                {!currentDayData.isRest && (
                  <div className="w-full h-[15%] flex flex-col space-y-2 p-2 ">
                    <span className=" ml-2">زمان کل تمرین(دقیقه)</span>
                    <input
                      type="range"
                      // value={rangeSliderValue}
                      min={0}
                      max={160}
                      step={5}
                      {...register(
                        `weekDays.${currentWeek * 7 + currentDay}.dayLongTime`,
                        {
                          valueAsNumber: true,
                        }
                      )}
                      list="markers"
                      className="cursor-pointer"
                      // className={`bg-[#6457f3]  active:bg-color1 hover:bg-[#6b5ef5] `}
                    />
                    <datalist id="markers">
                      {Array.apply(null, new Array(320)).map((el, i) => (
                        <option key={i++} value={i.toString()}></option>
                      ))}
                    </datalist>
                    <ul className="flex justify-between w-full px-[10px]">
                      {Array.apply(null, new Array(17)).map((el, i) => (
                        <li
                          key={i}
                          className="flex justify-center relative max-md:text-2xs"
                        >
                          <span className="absolute">{i * 10}</span>
                        </li>
                      ))}
                    </ul>

                    {/* <div className="flex justify-center">
                          <p className="pl-4 text-center text-sm font-medium">
                            {rangeSliderValue % 2 === 0
                              ? `${rangeSliderValue / 2} دقیقه`
                              : `${Math.floor(rangeSliderValue / 2) + 5} دقیقه `}
                          </p>
                        </div> */}
                  </div>
                )}
                <div className="w-full h-[12%]">
                  <label
                    data-tip={
                      (returnError() as any)?.dayName &&
                      (returnError() as any)?.dayName.message
                    }
                    className={`w-full ${
                      (returnError() as any)?.dayName &&
                      "tooltip tooltip-bottom"
                    }`}
                  >
                    <div className="label justify-start">
                      <span className="">نام روز </span>
                    </div>
                    <input
                      tabIndex={0}
                      readOnly={currentDayData.isRest}
                      maxLength={25}
                      type="text"
                      {...register(
                        // `day.${index}.dayName` as const,
                        `weekDays.${currentWeek * 7 + currentDay}.dayName`,
                        registerOptions.dayName
                      )}
                      placeholder="نام روز را وارد نمایید"
                      className={`input text-xs h-8 input-bordered w-full dark:bg-white
                                ${
                                  (returnError() as any)?.dayName &&
                                  "border-1 border-red-600"
                                }
                              `}
                    />
                    {/* {errors.day && (errors.day as any)[0].dayName.message} */}
                  </label>
                </div>
                <div className="w-full flex h-[20%]">
                  <label className={`form-control w-full `}>
                    <div className="label justify-start">
                      <span className="">توضیحات</span>
                    </div>
                    <textarea
                      tabIndex={1}
                      {...register(
                        `weekDays.${
                          currentWeek * 7 + currentDay
                        }.dayDescription`
                      )}
                      rows={3}
                      cols={10}
                      maxLength={300}
                      placeholder="توضیحات خود را وارد نمایید"
                      className={`input text-xs input-bordered w-full h-16 2xl:h-20 `}
                    />
                  </label>
                </div>

                {watchCircuits &&
                  watchCircuits?.length > 0 &&
                  !currentDayData.isRest && (
                    <div className="justify-start h-[2%] pr-1">تمرینات</div>
                  )}

                {!currentDayData.isRest && (
                  <div className="w-full h-[35%] flex flex-col overflow-y-auto">
                    <label className={`form-control w-full  `}></label>
                    {watchCircuits &&
                      watchCircuits!
                        .sort(
                          (a: any, b: any) => a.circuitOrder - b.circuitOrder
                        )
                        .map((circuit: circuitType, idx: number) => {
                          return circuit ? (
                            <div
                              key={idx}
                              onDragStart={(e: DragEvent<HTMLDivElement>) =>
                                dragStart(e, idx)
                              }
                              onDragEnter={(e: DragEvent<HTMLDivElement>) =>
                                dragEnter(e, idx)
                              }
                              onDragEnd={(e: DragEvent<HTMLDivElement>) =>
                                drop(e, idx)
                              }
                              className="flex w-full border rounded-md items-center mt-1 gap-x-4 p-1 bg-white"
                              draggable
                            >
                              <div
                                data-tip="جابجایی تمرین"
                                className="tooltip tooltip-left ml-3"
                              >
                                <DraggableSVG className="w-5 h-3 cursor-pointer " />
                              </div>

                              <div
                                className="flex relative cursor-pointer"
                                onClick={() => {
                                  selectAnExecerciseOrCircuit(circuit, idx);
                                }}
                              >
                                {circuit.isExercise &&
                                  circuit.circuitExercises &&
                                  circuit.circuitExercises.length > 1 && (
                                    <div className="badge badge-sm z-10 absolute -mr-5 ltr dark:bg-white dark:text-black text-black">
                                      <span>
                                        + {circuit.circuitExercises.length - 1}
                                      </span>
                                    </div>
                                  )}
                                <div className="avatar w-7 h-7 ">
                                  <img
                                    src={`${moviesUrl}/media/${circuit.circuitExercises![0].movementId}/thumbnail.png`}
                                    alt="exerciseLogo"
                                    className="relative mx-auto rounded-full overflow-hidden"
                                  />
                                </div>
                              </div>

                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  selectAnExecerciseOrCircuit(circuit, idx);
                                }}
                              >
                                {circuit.isExercise
                                  ? circuit!.circuitExercises![0].exerciseName
                                  : circuit.circuitName!}
                              </div>
                              <div
                                data-tip={`حذف تمرین ${Numbring[idx]}`}
                                className="mr-auto tooltip tooltip-right"
                                onClick={() => removeCircuits(idx)}
                              >
                                <DeleteSVG className="w-4 h-6 cursor-pointer" />
                              </div>
                            </div>
                          ) : (
                            false
                          );
                        })}
                  </div>
                )}
              </div>
            </>
          ) : showExercise === 1 ? (
            <Exercise
              currentWeek={currentWeek}
              currentDay={currentDay}
              dayData={currentDayData!}
              circuitData={currentCircuitData}
              currentExercise={currentExercise}
              setShowExercise={setShowExercise}
            />
          ) : (
            <Circuit
              currentWeek={currentWeek}
              currentDay={currentDay}
              dayData={currentDayData!}
              circuitData={currentCircuitData}
              currentCircuit={currentExercise}
              setShowExercise={setShowExercise}
            />
          )}
        </div>
      )}
    </>
  );
};

export default DayComponent;