import { SVGProps } from "react";

function TrainerSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      {/* <g clip-path="url(#clip0_3103_82406)"> */}
      <g>
        <circle cx="11.0002" cy="7.33342" r="2.91667" stroke="black" />
        <circle
          cx="11.0002"
          cy="7.33342"
          r="2.91667"
          stroke="black"
          // stroke-opacity="0.1"
          fill="white"
        />
        <path
          d="M14.0179 7.75C14.2832 7.29063 14.72 6.95543 15.2324 6.81815C15.7447 6.68086 16.2906 6.75273 16.75 7.01795C17.2094 7.28317 17.5446 7.72 17.6819 8.23236C17.8191 8.74472 17.7473 9.29063 17.4821 9.75C17.2168 10.2094 16.78 10.5446 16.2676 10.6819C15.7553 10.8191 15.2094 10.7473 14.75 10.4821C14.2906 10.2168 13.9554 9.78 13.8181 9.26764C13.6809 8.75528 13.7527 8.20937 14.0179 7.75L14.0179 7.75Z"
          stroke="black"
        />
        <path
          d="M14.0179 7.75C14.2832 7.29063 14.72 6.95543 15.2324 6.81815C15.7447 6.68086 16.2906 6.75273 16.75 7.01795C17.2094 7.28317 17.5446 7.72 17.6819 8.23236C17.8191 8.74472 17.7473 9.29063 17.4821 9.75C17.2168 10.2094 16.78 10.5446 16.2676 10.6819C15.7553 10.8191 15.2094 10.7473 14.75 10.4821C14.2906 10.2168 13.9554 9.78 13.8181 9.26764C13.6809 8.75528 13.7527 8.20937 14.0179 7.75L14.0179 7.75Z"
          stroke="white"
          // stroke-opacity="0.1"
        />
        <path
          d="M4.01795 7.75C4.28317 7.29063 4.72 6.95543 5.23236 6.81815C5.74472 6.68086 6.29063 6.75273 6.75 7.01795C7.20937 7.28317 7.54457 7.72 7.68185 8.23236C7.81914 8.74472 7.74727 9.29063 7.48205 9.75C7.21683 10.2094 6.78 10.5446 6.26764 10.6819C5.75528 10.8191 5.20937 10.7473 4.75 10.4821C4.29063 10.2168 3.95543 9.78 3.81815 9.26764C3.68086 8.75528 3.75273 8.20937 4.01795 7.75L4.01795 7.75Z"
          stroke="black"
        />
        <path
          d="M4.01795 7.75C4.28317 7.29063 4.72 6.95543 5.23236 6.81815C5.74472 6.68086 6.29063 6.75273 6.75 7.01795C7.20937 7.28317 7.54457 7.72 7.68185 8.23236C7.81914 8.74472 7.74727 9.29063 7.48205 9.75C7.21683 10.2094 6.78 10.5446 6.26764 10.6819C5.75528 10.8191 5.20937 10.7473 4.75 10.4821C4.29063 10.2168 3.95543 9.78 3.81815 9.26764C3.68086 8.75528 3.75273 8.20937 4.01795 7.75L4.01795 7.75Z"
          stroke="white"
          // stroke-opacity="0.1"
        />
        <path
          d="M15.4718 16.4999L14.7276 16.5928L14.8096 17.2499H15.4718V16.4999ZM18.9613 15.4076L19.664 15.1455L19.664 15.1455L18.9613 15.4076ZM13.5488 13.4846L13.0952 12.8873L12.2278 13.5462L13.1527 14.1214L13.5488 13.4846ZM15.4594 16.4272L14.7261 16.5842L14.7261 16.5842L15.4594 16.4272ZM18.117 15.7499H15.4718V17.2499H18.117V15.7499ZM18.2586 15.6696C18.2657 15.6887 18.2628 15.6929 18.2636 15.688C18.2642 15.685 18.2647 15.6881 18.258 15.6965C18.2431 15.715 18.198 15.7499 18.117 15.7499V17.2499C18.6374 17.2499 19.1196 17.0204 19.4291 16.6337C19.747 16.2364 19.8663 15.6878 19.664 15.1455L18.2586 15.6696ZM15.5831 13.5833C17.1553 13.5833 17.8945 14.6933 18.2586 15.6697L19.664 15.1455C19.2221 13.9605 18.1063 12.0833 15.5831 12.0833V13.5833ZM14.0025 14.0818C14.3834 13.7925 14.8879 13.5833 15.5831 13.5833V12.0833C14.555 12.0833 13.7329 12.4029 13.0952 12.8873L14.0025 14.0818ZM13.1527 14.1214C14.1352 14.7325 14.5533 15.7778 14.7261 16.5842L16.1928 16.2701C15.9839 15.2947 15.4314 13.7722 13.9449 12.8477L13.1527 14.1214ZM14.7261 16.5842C14.7268 16.5877 14.7273 16.5905 14.7276 16.5928L16.216 16.407C16.2103 16.3612 16.2026 16.3156 16.1928 16.2701L14.7261 16.5842Z"
          fill="black"
        />
        <path
          d="M15.4718 16.4999L14.7276 16.5928L14.8096 17.2499H15.4718V16.4999ZM18.9613 15.4076L19.664 15.1455L19.664 15.1455L18.9613 15.4076ZM13.5488 13.4846L13.0952 12.8873L12.2278 13.5462L13.1527 14.1214L13.5488 13.4846ZM15.4594 16.4272L14.7261 16.5842L14.7261 16.5842L15.4594 16.4272ZM18.117 15.7499H15.4718V17.2499H18.117V15.7499ZM18.2586 15.6696C18.2657 15.6887 18.2628 15.6929 18.2636 15.688C18.2642 15.685 18.2647 15.6881 18.258 15.6965C18.2431 15.715 18.198 15.7499 18.117 15.7499V17.2499C18.6374 17.2499 19.1196 17.0204 19.4291 16.6337C19.747 16.2364 19.8663 15.6878 19.664 15.1455L18.2586 15.6696ZM15.5831 13.5833C17.1553 13.5833 17.8945 14.6933 18.2586 15.6697L19.664 15.1455C19.2221 13.9605 18.1063 12.0833 15.5831 12.0833V13.5833ZM14.0025 14.0818C14.3834 13.7925 14.8879 13.5833 15.5831 13.5833V12.0833C14.555 12.0833 13.7329 12.4029 13.0952 12.8873L14.0025 14.0818ZM13.1527 14.1214C14.1352 14.7325 14.5533 15.7778 14.7261 16.5842L16.1928 16.2701C15.9839 15.2947 15.4314 13.7722 13.9449 12.8477L13.1527 14.1214ZM14.7261 16.5842C14.7268 16.5877 14.7273 16.5905 14.7276 16.5928L16.216 16.407C16.2103 16.3612 16.2026 16.3156 16.1928 16.2701L14.7261 16.5842Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M8.45103 13.4846L8.84713 14.1214L9.77211 13.5462L8.90467 12.8873L8.45103 13.4846ZM3.03856 15.4076L2.33584 15.1455L2.33584 15.1455L3.03856 15.4076ZM6.52806 16.4999V17.2499H7.19028L7.27229 16.5928L6.52806 16.4999ZM6.5404 16.4272L7.27376 16.5842L7.27376 16.5842L6.5404 16.4272ZM6.4168 13.5833C7.11197 13.5833 7.61644 13.7925 7.99739 14.0818L8.90467 12.8873C8.26697 12.4029 7.44487 12.0833 6.4168 12.0833V13.5833ZM3.74127 15.6696C4.10542 14.6933 4.84455 13.5833 6.4168 13.5833V12.0833C3.89363 12.0833 2.77781 13.9605 2.33584 15.1455L3.74127 15.6696ZM3.8829 15.7499C3.80188 15.7499 3.75679 15.715 3.74191 15.6965C3.73521 15.6881 3.7357 15.685 3.73622 15.688C3.73708 15.6929 3.73415 15.6887 3.74127 15.6696L2.33584 15.1455C2.13355 15.6878 2.25284 16.2364 2.57078 16.6337C2.88025 17.0204 3.36243 17.2499 3.8829 17.2499V15.7499ZM6.52806 15.7499H3.8829V17.2499H6.52806V15.7499ZM7.27229 16.5928C7.27257 16.5905 7.27303 16.5877 7.27376 16.5842L5.80703 16.2701C5.79729 16.3156 5.78955 16.3612 5.78383 16.407L7.27229 16.5928ZM7.27376 16.5842C7.44649 15.7778 7.86467 14.7325 8.84713 14.1214L8.05494 12.8477C6.56845 13.7722 6.01594 15.2947 5.80703 16.2701L7.27376 16.5842Z"
          fill="black"
        />
        <path
          d="M8.45103 13.4846L8.84713 14.1214L9.77211 13.5462L8.90467 12.8873L8.45103 13.4846ZM3.03856 15.4076L2.33584 15.1455L2.33584 15.1455L3.03856 15.4076ZM6.52806 16.4999V17.2499H7.19028L7.27229 16.5928L6.52806 16.4999ZM6.5404 16.4272L7.27376 16.5842L7.27376 16.5842L6.5404 16.4272ZM6.4168 13.5833C7.11197 13.5833 7.61644 13.7925 7.99739 14.0818L8.90467 12.8873C8.26697 12.4029 7.44487 12.0833 6.4168 12.0833V13.5833ZM3.74127 15.6696C4.10542 14.6933 4.84455 13.5833 6.4168 13.5833V12.0833C3.89363 12.0833 2.77781 13.9605 2.33584 15.1455L3.74127 15.6696ZM3.8829 15.7499C3.80188 15.7499 3.75679 15.715 3.74191 15.6965C3.73521 15.6881 3.7357 15.685 3.73622 15.688C3.73708 15.6929 3.73415 15.6887 3.74127 15.6696L2.33584 15.1455C2.13355 15.6878 2.25284 16.2364 2.57078 16.6337C2.88025 17.0204 3.36243 17.2499 3.8829 17.2499V15.7499ZM6.52806 15.7499H3.8829V17.2499H6.52806V15.7499ZM7.27229 16.5928C7.27257 16.5905 7.27303 16.5877 7.27376 16.5842L5.80703 16.2701C5.79729 16.3156 5.78955 16.3612 5.78383 16.407L7.27229 16.5928ZM7.27376 16.5842C7.44649 15.7778 7.86467 14.7325 8.84713 14.1214L8.05494 12.8477C6.56845 13.7722 6.01594 15.2947 5.80703 16.2701L7.27376 16.5842Z"
          fill="white"
          fillOpacity="0.1"
        />
        <path
          d="M10.9998 12.8333C14.2086 12.8333 15.1709 15.0797 15.4595 16.4272C15.5752 16.9672 15.1355 17.4166 14.5832 17.4166H7.41651C6.86422 17.4166 6.42446 16.9672 6.54013 16.4272C6.82873 15.0797 7.79105 12.8333 10.9998 12.8333Z"
          stroke="black"
        />
        <path
          d="M10.9998 12.8333C14.2086 12.8333 15.1709 15.0797 15.4595 16.4272C15.5752 16.9672 15.1355 17.4166 14.5832 17.4166H7.41651C6.86422 17.4166 6.42446 16.9672 6.54013 16.4272C6.82873 15.0797 7.79105 12.8333 10.9998 12.8333Z"
          stroke="black"
          fill="white"
          // stroke-opacity="0.1"
        />
      </g>
      <defs>
        <clipPath id="clip0_3103_82406">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default TrainerSVG;
