import movementsData from "components/movements.json";
import http from "services/authService";
// import movementsData from "../components/movements.json";
// import { movementType } from "../types";

const appUrl = process.env.REACT_APP_URL + "/Movement";
const mediaUrl = process.env.REACT_APP_URL + "/Media";

export const uploadMedia = (data: {
  File: File;
  IsPlanPoster: boolean;
  MovementId: string;
  PlanId:string;
}) => {
  return http.post(`${mediaUrl}/upload-video`, data, {
    headers: {
      // "x-api-version": "2.0",
      accept: "text/plain",
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getAllMovements = () => {
  return http.get(`${appUrl}/getmovements`);
};

// export const getAllMovements = () => {
//   return { data: { rsCode: 1, resultData: movementsData } };
// };

export const getMovement = (id: string) => {
  return http.get(`${appUrl}/getmovement?MovementId=${id}`);
};

// export const getMovement = (id: string) => {
//   return {
//     data: {
//       rsCode: 1,
//       resultData: movementsData.filter((item) => item.id === id)[0],
//     },
//   };
// };

export const addMovement = (data: any) => {
  return http.post(`${appUrl}/addmovement`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateMovement = (data: any) => {
  return http.put(`${appUrl}/updatemovement`, data, {
    headers: { "Content-Type": "application/json" },
  });
};

export const removeMovement = (id: string) => {
  return http.delete(`${appUrl}/deletemovement`, {
    data: {
      movementId: id,
    },
  });
};

// export const removeMovement = (data: any) => {
//   return http.delete(`${appUrl}/deletemovement/`, data);
// };
export const mockApi = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(movementsData);
    }, 5000); // Simulating a 2-second delay for data fetching
  });
};
