import * as React from "react";
import { SVGProps } from "react";

function ReviewSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#f88d8d" {...props} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 485 485" >
<g>
   <g>
       <g>
           <path d="M340.732,0H55v485h155h55h165V94.518L340.732,0z M345,26.364L405.101,90H345V26.364z M256.001,328.151L250,329.375V470
               h-25V329.375l-6.001-1.224C176.121,319.407,145,281.282,145,237.5c0-51.005,41.495-92.5,92.5-92.5s92.5,41.495,92.5,92.5
               C330,281.282,298.879,319.407,256.001,328.151z M415,470H265V341.444c46.71-12.366,80-55.129,80-103.944
               c0-59.276-48.225-107.5-107.5-107.5C178.225,130,130,178.224,130,237.5c0,48.815,33.29,91.578,80,103.944V470H70V15h260v90h85
               V470z"/>
           <path d="M237.5,165c-39.977,0-72.5,32.523-72.5,72.5s32.523,72.5,72.5,72.5s72.5-32.523,72.5-72.5S277.477,165,237.5,165z
                M237.5,295c-31.706,0-57.5-25.794-57.5-57.5s25.794-57.5,57.5-57.5s57.5,25.794,57.5,57.5S269.206,295,237.5,295z"/>
           <path d="M205,237.5h15c0-12.407,10.094-22.5,22.5-22.5v-15C221.822,200,205,216.822,205,237.5z"/>
           <rect x="92.5" y="41" width="70" height="15"/>
           <rect x="92.5" y="81" width="110" height="15"/>
       </g>
   </g>
</g>
</svg>
  );
}
export default ReviewSVG;
